import React, { Fragment } from "react";

import TextSection from "../../components/TextSection";
import BookingsList from "../../containers/BookingsList";

/**
 * MyBookings component
 *
 * @param {object} props
 */
const MyBookings = () => {
  return (
    <Fragment>
      <TextSection
        title="My Bookings"
        textHtml={(
          <div className="gel-pica">
            <p className="gs-u-mb+">Here's a list of all your bookings</p>
          </div>
        )}
      />
      <div className="gel-wrap gel-wrap--small o-slice-spacing-bottom">
        <BookingsList title={null} isViewAllEnabled={false} />
      </div>
    </Fragment>
  );
};

export default MyBookings;
