/* eslint-disable no-alert */
import React, { useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

/**
 * SummerPaymentPlan component
 *
 * @param {object} props
 */
const SummerPaymentPlan = ({
  classes,
  elementId,
  name,
  frequency,
  price,
  label,
  membershipId
}) => {
  const paymentPlanClasses = classNames("payment-plan", classes);

  useEffect(() => {
    window.paypal
      .Buttons({
        style: {
          shape: "pill",
          color: "blue",
          layout: "vertical",
          label: "paypal"
        },
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: "The Gym at Moffat - Summer Membership",
                amount: { currency_code: "GBP", value: 60 }
              }
            ]
          });
        },
        onApprove: (_, actions) => {
          return actions.order.capture().then(async details => {
            if (details && details.id) {
              window.location.href = `/memberships/${membershipId}/SUMMER_${details.id}/success`;
            } else {
              window.location.href = `/memberships/${membershipId}/SUMMER_SUBSCRIPTION_ID/success`;
            }
          });
        },
        onError: err => {
          alert("An error occurred. Please contact support.");
          console.log(err);
        }
      })
      .render(`#${elementId}`);
  }, []);

  return (
    <li className={paymentPlanClasses}>
      <div className="payment-plan__wrapper gs-u-p++ gel-1/1">
        <div className="payment-plan__content gs-u-align-center">
          <h4 className="payment-plan__name gel-pica gs-u-mb++">{name}</h4>
          <hr className="gs-u-mv+" />
          {label && (
            <span className="payment-plan__label gel-brevier-bold gs-u-p gs-u-display-inline-block gs-u-mb+">
              {label}
            </span>
          )}
          <h4 className="payment-plan__price gel-canon">{price}</h4>
          <p className="payment-plan__frequency gel-brevier gs-u-mb++">
            {frequency}
          </p>
        </div>
        <div className="payment-plan__paypal">
          <div id={elementId} />
        </div>
      </div>
    </li>
  );
};

SummerPaymentPlan.defaultProps = {
  classes: null,
  label: null
};

SummerPaymentPlan.propTypes = {
  elementId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  frequency: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  planId: PropTypes.string.isRequired,
  membershipId: PropTypes.string.isRequired,
  label: PropTypes.node,
  classes: PropTypes.string
};

export default SummerPaymentPlan;
