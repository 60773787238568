/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";

axios.defaults.withCredentials = true;

const mapStateToProps = (state) => ({});

/**
 * ChildConsent container
 *
 * @param {object} props
 */
const ChildConsent = () => {
  return (
    <div className="gel-wrap gel-wrap o-slice-spacing o-slice-spacing-bottom">
      <h2 className="gs-u-mb++">
        Parent/Guardian/Carer Gymnastics & Trampolining Consent Form
      </h2>

      <Formik
        initialValues={{
          classType: "gymnastics",
          childClass: "",
          childName: "",
          dateOfBirth: "",
          adultName: "",
          adultEmail: "",
          adultPhone: "",
          collectionName: "",
          collectionPhone: "",
          familyDoctor: "",
          familyDoctorPhone: "",
          childMedicalConditions: "",
          emergencyContactName: "",
          emergencyContactPhone: "",
          emergencyContactRelationship: "",
          consentOne: null,
          consentTwo: null,
          consentThree: null,
          consentFour: null,
          consentFive: null,
          consentSix: null,
          consentSeven: null,
        }}
        onSubmit={async (values) => {
          const { data } = await axios
            .post("/api/child-consent", values, { credentials: "include" })
            .catch((err) => {
              const { data: errData } = err.response;
              if (errData.error.indexOf("duplicate") > -1) {
                alert(
                  "Looks like that we already have your consent? If you think this is a mistake, please get in touch with us."
                );
              } else {
                alert(
                  "Something went wrong there. Please try again and if the problem persists, please let us know! Thanks for your patience."
                );
              }
            });

          if (data.user && data.user._id) {
            window.location.href = `/?m=Successfully submitted consent. You should receieve a confirmation email.`;
          } else {
            alert(
              "Something went wrong there. Please try again and if the problem persists, please let us know! Thanks for your patience."
            );
          }
        }}
        validationSchema={Yup.object().shape({
          classType: Yup.string().required("Class type is required"),
          childClass: Yup.string().required("Class is required"),
          childName: Yup.string().required("Child's name is required"),
          dateOfBirth: Yup.string().required(
            "Child's date of birth is required"
          ),
          adultEmail: Yup.string()
            .email("Please enter a valid email")
            .required("Parent/Guardian/Carer email address is required"),
          adultName: Yup.string().required(
            "Parent/Guardian/Carer name is required"
          ),
          adultPhone: Yup.string().required(
            "Parent/Guardian/Carer phone number is required"
          ),
          collectionName: Yup.string().required(
            "Collection contact name is required"
          ),
          collectionPhone: Yup.string().required(
            "Collection contact phone number is required"
          ),
          familyDoctor: Yup.string().required("Family doctor is required"),
          familyDoctorPhone: Yup.string().required(
            "Family doctor's phone number is required"
          ),
          childMedicalConditions: Yup.string().required(
            "This is a required question"
          ),
          emergencyContactName: Yup.string().required(
            "Emergency contact name is required"
          ),
          emergencyContactPhone: Yup.string().required(
            "Emergency contact phone is required"
          ),
          emergencyContactRelationship: Yup.string().required(
            "Emergency contact relationship is required"
          ),
          consentOne: Yup.string()
            .nullable()
            .required("Please choose either Yes or No."),
          consentTwo: Yup.string()
            .nullable()
            .required("Please choose either Yes or No."),
          consentThree: Yup.string()
            .nullable()
            .required("Please choose either Yes or No."),
          consentFour: Yup.string()
            .nullable()
            .required("Please choose either Yes or No."),
          consentFive: Yup.string()
            .nullable()
            .required("Please choose either Yes or No."),
          consentSix: Yup.string()
            .nullable()
            .required("Please choose either Yes or No."),
          consentSeven: Yup.string()
            .nullable()
            .required("Please choose either Yes or No."),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <form onSubmit={handleSubmit} className="gel-layout">
              <h3 className="gel-great-primer-bold gel-1/1 gel-layout__item gs-u-mb++">
                Class Details
              </h3>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/2 gs-u-pt++">
                <label
                  className="gs-u-display-inline-block gs-u-mr+"
                  htmlFor="gymnastics"
                >
                  <input
                    type="radio"
                    className="gs-u-mr"
                    id="gymnastics"
                    name="classType"
                    value="gymnastics"
                    checked={values.classType === "gymnastics"}
                    onChange={handleChange}
                  />
                  Gymnastics
                </label>
                <label
                  className="gs-u-display-inline-block"
                  htmlFor="trampoline"
                >
                  <input
                    type="radio"
                    className="gs-u-mr"
                    id="trampoline"
                    name="classType"
                    checked={values.classType === "trampoline"}
                    onChange={handleChange}
                    value="trampoline"
                  />
                  Trampoline
                </label>
              </fieldset>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/2">
                <label htmlFor="childClass" className="gs-u-display-block">
                  Class *
                </label>
                <input
                  id="childClass"
                  type="text"
                  value={values.childClass}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.childClass && touched.childClass
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.childClass && touched.childClass && (
                  <div className="input-error">{errors.childClass}</div>
                )}
              </fieldset>

              <h3 className="gel-great-primer-bold gel-1/1 gel-layout__item gs-u-mb++">
                Child's Details
              </h3>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/2">
                <label htmlFor="childName" className="gs-u-display-block">
                  Name *
                </label>
                <input
                  id="childName"
                  type="text"
                  value={values.childName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.childName && touched.childName
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.childName && touched.childName && (
                  <div className="input-error">{errors.childName}</div>
                )}
              </fieldset>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/2">
                <label htmlFor="dateOfBirth" className="gs-u-display-block">
                  Date of birth *
                </label>
                <input
                  id="dateOfBirth"
                  type="text"
                  value={values.dateOfBirth}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.dateOfBirth && touched.dateOfBirth
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.dateOfBirth && touched.dateOfBirth && (
                  <div className="input-error">{errors.dateOfBirth}</div>
                )}
              </fieldset>

              <h3 className="gel-great-primer-bold gel-1/1 gel-layout__item gs-u-mb++">
                Parent/Guardian/Carer Details
              </h3>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/2">
                <label htmlFor="adultName" className="gs-u-display-block">
                  Name *
                </label>
                <input
                  id="adultName"
                  type="text"
                  value={values.adultName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.adultName && touched.adultName
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.adultName && touched.adultName && (
                  <div className="input-error">{errors.adultName}</div>
                )}
              </fieldset>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/2">
                <label htmlFor="adultPhone" className="gs-u-display-block">
                  Phone number *
                </label>
                <input
                  id="adultPhone"
                  type="text"
                  value={values.adultPhone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.adultPhone && touched.adultPhone
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.adultPhone && touched.adultPhone && (
                  <div className="input-error">{errors.adultPhone}</div>
                )}
              </fieldset>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
                <label htmlFor="adultEmail" className="gs-u-display-block">
                  Email address *
                </label>
                <input
                  id="adultEmail"
                  type="text"
                  value={values.adultEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.adultEmail && touched.adultEmail
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.adultEmail && touched.adultEmail && (
                  <div className="input-error">{errors.adultEmail}</div>
                )}
              </fieldset>

              <h3 className="gel-great-primer-bold gel-1/1 gel-layout__item gs-u-mb++">
                Collection Contact Details
              </h3>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/2">
                <label htmlFor="collectionName" className="gs-u-display-block">
                  Name *
                </label>
                <input
                  id="collectionName"
                  type="text"
                  value={values.collectionName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.collectionName && touched.collectionName
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.collectionName && touched.collectionName && (
                  <div className="input-error">{errors.collectionName}</div>
                )}
              </fieldset>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/2">
                <label htmlFor="collectionPhone" className="gs-u-display-block">
                  Phone number *
                </label>
                <input
                  id="collectionPhone"
                  type="text"
                  value={values.collectionPhone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.collectionPhone && touched.collectionPhone
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.collectionPhone && touched.collectionPhone && (
                  <div className="input-error">{errors.collectionPhone}</div>
                )}
              </fieldset>

              <h3 className="gel-great-primer-bold gel-1/1 gel-layout__item gs-u-mb++">
                Medical Details
              </h3>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/2">
                <label htmlFor="familyDoctor" className="gs-u-display-block">
                  Family Doctor *
                </label>
                <input
                  id="familyDoctor"
                  type="text"
                  value={values.familyDoctor}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.familyDoctor && touched.familyDoctor
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.familyDoctor && touched.familyDoctor && (
                  <div className="input-error">{errors.familyDoctor}</div>
                )}
              </fieldset>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/2">
                <label
                  htmlFor="familyDoctorPhone"
                  className="gs-u-display-block"
                >
                  Doctor's number *
                </label>
                <input
                  id="familyDoctorPhone"
                  type="text"
                  value={values.familyDoctorPhone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.familyDoctorPhone && touched.familyDoctorPhone
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.familyDoctorPhone && touched.familyDoctorPhone && (
                  <div className="input-error">{errors.familyDoctorPhone}</div>
                )}
              </fieldset>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
                <label
                  htmlFor="childMedicalConditions"
                  className="gs-u-display-block"
                >
                  Does your child suffer from any medical conditions/allergies
                  that the club/coach should be aware of (including any current
                  medication) *
                </label>
                <input
                  id="childMedicalConditions"
                  type="text"
                  value={values.childMedicalConditions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.childMedicalConditions &&
                    touched.childMedicalConditions
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.childMedicalConditions &&
                  touched.childMedicalConditions && (
                    <div className="input-error">
                      {errors.childMedicalConditions}
                    </div>
                  )}
              </fieldset>

              <h3 className="gel-great-primer-bold gel-1/1 gel-layout__item gs-u-mb++">
                Emergency contact details
              </h3>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/2">
                <label
                  htmlFor="emergencyContactName"
                  className="gs-u-display-block"
                >
                  Name *
                </label>
                <input
                  id="emergencyContactName"
                  type="text"
                  value={values.emergencyContactName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.emergencyContactName && touched.emergencyContactName
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.emergencyContactName &&
                  touched.emergencyContactName && (
                    <div className="input-error">
                      {errors.emergencyContactName}
                    </div>
                  )}
              </fieldset>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/2">
                <label
                  htmlFor="emergencyContactPhone"
                  className="gs-u-display-block"
                >
                  Phone number *
                </label>
                <input
                  id="emergencyContactPhone"
                  type="text"
                  value={values.emergencyContactPhone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.emergencyContactPhone &&
                    touched.emergencyContactPhone
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.emergencyContactPhone &&
                  touched.emergencyContactPhone && (
                    <div className="input-error">
                      {errors.emergencyContactPhone}
                    </div>
                  )}
              </fieldset>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
                <label
                  htmlFor="emergencyContactRelationship"
                  className="gs-u-display-block"
                >
                  Relationship to child *
                </label>
                <input
                  id="emergencyContactRelationship"
                  type="text"
                  value={values.emergencyContactRelationship}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.emergencyContactRelationship &&
                    touched.emergencyContactRelationship
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.emergencyContactRelationship &&
                  touched.emergencyContactRelationship && (
                    <div className="input-error">
                      {errors.emergencyContactRelationship}
                    </div>
                  )}
              </fieldset>

              <h3 className="gel-great-primer-bold gel-1/1 gel-layout__item gs-u-mb++">
                Consent (please read carefully and choose yes or no as
                appropriate)
              </h3>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
                <label
                  className="gs-u-display-inline-block gs-u-mr+"
                  htmlFor="consentOneYes"
                >
                  <input
                    type="radio"
                    className="gs-u-mr"
                    id="consentOneYes"
                    name="consentOne"
                    value="yes"
                    checked={values.consentOne === "yes"}
                    onChange={handleChange}
                  />
                  Yes
                </label>
                <label
                  className="gs-u-display-inline-block"
                  htmlFor="consentOneNo"
                >
                  <input
                    type="radio"
                    className="gs-u-mr"
                    id="consentOneNo"
                    name="consentOne"
                    checked={values.consentOne === "no"}
                    onChange={handleChange}
                    value="no"
                  />
                  No
                </label>

                <span className="gs-u-ml+">
                  I give consent to suitably trained staff members administering
                  emergency first aid to my child, or undertaking procedures as
                  directed by a quailified medical practitioner.{" "}
                </span>

                {errors.consentOne && touched.consentOne && (
                  <div className="input-error">{errors.consentOne}</div>
                )}
              </fieldset>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
                <label
                  className="gs-u-display-inline-block gs-u-mr+"
                  htmlFor="consentTwoYes"
                >
                  <input
                    type="radio"
                    className="gs-u-mr"
                    id="consentTwoYes"
                    name="consentTwo"
                    value="yes"
                    checked={values.consentTwo === "yes"}
                    onChange={handleChange}
                  />
                  Yes
                </label>
                <label
                  className="gs-u-display-inline-block"
                  htmlFor="consentTwoNo"
                >
                  <input
                    type="radio"
                    className="gs-u-mr"
                    id="consentTwoNo"
                    name="consentTwo"
                    checked={values.consentTwo === "no"}
                    onChange={handleChange}
                    value="no"
                  />
                  No
                </label>

                <span className="gs-u-ml+">
                  I confirm to the best of my knowledge that my son/ daughter
                  does not suffer from any medical condition other than those
                  listed above.
                </span>

                {errors.consentTwo && touched.consentTwo && (
                  <div className="input-error">{errors.consentTwo}</div>
                )}
              </fieldset>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
                <label
                  className="gs-u-display-inline-block gs-u-mr+"
                  htmlFor="consentThreeYes"
                >
                  <input
                    type="radio"
                    className="gs-u-mr"
                    id="consentThreeYes"
                    name="consentThree"
                    value="yes"
                    checked={values.consentThree === "yes"}
                    onChange={handleChange}
                  />
                  Yes
                </label>
                <label
                  className="gs-u-display-inline-block"
                  htmlFor="consentThreeNo"
                >
                  <input
                    type="radio"
                    className="gs-u-mr"
                    id="consentThreeNo"
                    name="consentThree"
                    checked={values.consentThree === "no"}
                    onChange={handleChange}
                    value="no"
                  />
                  No
                </label>

                <span className="gs-u-ml+">
                  I understand that the Club or Organisers accept no
                  responsibility for loss or damage to property caused by or
                  during attendance on any of the clubs organised activities.
                </span>

                {errors.consentThree && touched.consentThree && (
                  <div className="input-error">{errors.consentThree}</div>
                )}
              </fieldset>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
                <label
                  className="gs-u-display-inline-block gs-u-mr+"
                  htmlFor="consentFourYes"
                >
                  <input
                    type="radio"
                    className="gs-u-mr"
                    id="consentFourYes"
                    name="consentFour"
                    value="yes"
                    checked={values.consentFour === "yes"}
                    onChange={handleChange}
                  />
                  Yes
                </label>
                <label
                  className="gs-u-display-inline-block"
                  htmlFor="consentFourNo"
                >
                  <input
                    type="radio"
                    className="gs-u-mr"
                    id="consentFourNo"
                    name="consentFour"
                    checked={values.consentFour === "no"}
                    onChange={handleChange}
                    value="no"
                  />
                  No
                </label>

                <span className="gs-u-ml+">
                  I agree from time to time if weather permits children will be
                  taken from The Gym to open space to participate in activities
                  and returned to The Gym for collection.
                </span>

                {errors.consentFour && touched.consentFour && (
                  <div className="input-error">{errors.consentFour}</div>
                )}
              </fieldset>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
                <label
                  className="gs-u-display-inline-block gs-u-mr+"
                  htmlFor="consentFiveYes"
                >
                  <input
                    type="radio"
                    className="gs-u-mr"
                    id="consentFiveYes"
                    name="consentFive"
                    value="yes"
                    checked={values.consentFive === "yes"}
                    onChange={handleChange}
                  />
                  Yes
                </label>
                <label
                  className="gs-u-display-inline-block"
                  htmlFor="consentFiveNo"
                >
                  <input
                    type="radio"
                    className="gs-u-mr"
                    id="consentFiveNo"
                    name="consentFive"
                    checked={values.consentFive === "no"}
                    onChange={handleChange}
                    value="no"
                  />
                  No
                </label>

                <span className="gs-u-ml+">
                  I give my permission for my child’s image (photographs and
                  video) to be taken during gymnastics training and social
                  occasions undertaken by the Gym at Moffat Gymnastics
                  Programme.
                </span>

                {errors.consentFive && touched.consentFive && (
                  <div className="input-error">{errors.consentFive}</div>
                )}
              </fieldset>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
                <label
                  className="gs-u-display-inline-block gs-u-mr+"
                  htmlFor="consentSixYes"
                >
                  <input
                    type="radio"
                    className="gs-u-mr"
                    id="consentSixYes"
                    name="consentSix"
                    value="yes"
                    checked={values.consentSix === "yes"}
                    onChange={handleChange}
                  />
                  Yes
                </label>
                <label
                  className="gs-u-display-inline-block"
                  htmlFor="consentSixNo"
                >
                  <input
                    type="radio"
                    className="gs-u-mr"
                    id="consentSixNo"
                    name="consentSix"
                    checked={values.consentSix === "no"}
                    onChange={handleChange}
                    value="no"
                  />
                  No
                </label>

                <span className="gs-u-ml+">
                  I also give consent for the above images to be posted to the
                  Gymnastics Programme WhatsApp groups, and to the open Gym at
                  Moffat Facebook pages.
                </span>

                {errors.consentSix && touched.consentSix && (
                  <div className="input-error">{errors.consentSix}</div>
                )}
              </fieldset>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
                <label
                  className="gs-u-display-inline-block gs-u-mr+"
                  htmlFor="consentSevenYes"
                >
                  <input
                    type="radio"
                    className="gs-u-mr"
                    id="consentSevenYes"
                    name="consentSeven"
                    value="yes"
                    checked={values.consentSeven === "yes"}
                    onChange={handleChange}
                  />
                  Yes
                </label>
                <label
                  className="gs-u-display-inline-block"
                  htmlFor="consentSevenNo"
                >
                  <input
                    type="radio"
                    className="gs-u-mr"
                    id="consentSevenNo"
                    name="consentSeven"
                    checked={values.consentSeven === "no"}
                    onChange={handleChange}
                    value="no"
                  />
                  No
                </label>

                <span className="gs-u-ml+">
                  I agree that The Gym can share information about my
                  son/daughter on an exclusive gymnastics/trampolining What’s
                  App group
                </span>

                {errors.consentSeven && touched.consentSeven && (
                  <div className="input-error">{errors.consentSeven}</div>
                )}
              </fieldset>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
                <button
                  className="button button--filled button--large"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit consent form
                </button>
              </fieldset>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default connect(mapStateToProps)(ChildConsent);
