/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/prop-types */
/* eslint-disable no-alert */
import React, { useState } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import classNames from "classnames";
import { getMembershipLevelLabel } from "../utils/memberships";
import { NewMembershipForm } from "./NewMembershipForm";

axios.defaults.withCredentials = true;

const mapStateToProps = (state) => ({
  memberOne: state.ui.memberOneLevel,
  memberTwo: state.ui.memberTwoLevel,
});

const CouplesMembershipForm = ({ memberOne, memberTwo }) => {
  const [activeMember, setActiveMember] = useState("memberOne");
  return (
    <div className="gel-wrap o-slice-spacing o-slice-spacing-bottom">
      <h2 className="gs-u-mb++">Fill out our Couples Membership form!</h2>
      <p className="gs-u-mb++">
        <i>
          Please note: While you are signing up for a Couples Membership, you
          will each have separate accounts with the gym. You are both still
          treated as individual members!
        </i>
      </p>
      <Formik
        initialValues={{
          memberOne__title: "",
          memberOne__firstName: "",
          memberOne__surName: "",
          memberOne__dateOfBirth: "",
          memberOne__address: "",
          memberOne__phone: "",
          memberOne__email: "",
          memberOne__marketingOptOut: false,
          memberOne__membershipType: "adult",
          memberOne__membershipLevel: memberOne,
          memberOne__gymInduction: "gym-induction-yes",
          memberOne__medicalQuestionnaire1: "q1-no",
          memberOne__medicalQuestionnaire1Explain: "",
          memberOne__medicalQuestionnaire2: "q2-no",
          memberOne__medicalQuestionnaire2Explain: "",
          memberOne__medicalQuestionnaire3: "q3-no",
          memberOne__medicalQuestionnaire3Explain: "",
          memberOne__medicalQuestionnaire4: "q4-no",
          memberOne__medicalQuestionnaire4Explain: "",
          memberOne__medicalQuestionnaire5: "q5-no",
          memberOne__medicalQuestionnaire5Explain: "",
          memberOne__medicalQuestionnaire6: "q6-no",
          memberOne__medicalQuestionnaire6Explain: "",
          memberOne__medicalQuestionnaire7: "q7-no",
          memberOne__medicalQuestionnaire7Explain: "",
          memberOne__medicalQuestionnaire8: "q8-no",
          memberOne__medicalQuestionnaire8Explain: "",
          memberOne__medicalQuestionnaire9: "q9-no",
          memberOne__medicalQuestionnaire9Explain: "",
          memberOne__goodHealthCheck: false,
          memberOne__rulesCheck: false,
          memberOne__socialMediaSharing: false,
          memberOne__nextOfKinName: "",
          memberOne__nextOfKinPhone: "",
          memberOne__username: "",
          memberOne__password: "",
          memberTwo__title: "",
          memberTwo__firstName: "",
          memberTwo__surName: "",
          memberTwo__dateOfBirth: "",
          memberTwo__address: "",
          memberTwo__phone: "",
          memberTwo__email: "",
          memberTwo__marketingOptOut: false,
          memberTwo__membershipType: "adult",
          memberTwo__membershipLevel: memberTwo,
          memberTwo__gymInduction: "gym-induction-yes",
          memberTwo__medicalQuestionnaire1: "q1-no",
          memberTwo__medicalQuestionnaire1Explain: "",
          memberTwo__medicalQuestionnaire2: "q2-no",
          memberTwo__medicalQuestionnaire2Explain: "",
          memberTwo__medicalQuestionnaire3: "q3-no",
          memberTwo__medicalQuestionnaire3Explain: "",
          memberTwo__medicalQuestionnaire4: "q4-no",
          memberTwo__medicalQuestionnaire4Explain: "",
          memberTwo__medicalQuestionnaire5: "q5-no",
          memberTwo__medicalQuestionnaire5Explain: "",
          memberTwo__medicalQuestionnaire6: "q6-no",
          memberTwo__medicalQuestionnaire6Explain: "",
          memberTwo__medicalQuestionnaire7: "q7-no",
          memberTwo__medicalQuestionnaire7Explain: "",
          memberTwo__medicalQuestionnaire8: "q8-no",
          memberTwo__medicalQuestionnaire8Explain: "",
          memberTwo__medicalQuestionnaire9: "q9-no",
          memberTwo__medicalQuestionnaire9Explain: "",
          memberTwo__goodHealthCheck: false,
          memberTwo__rulesCheck: false,
          memberTwo__socialMediaSharing: false,
          memberTwo__nextOfKinName: "",
          memberTwo__nextOfKinPhone: "",
          memberTwo__username: "",
          memberTwo__password: "",
        }}
        onSubmit={async (values) => {
          if (values.memberOne__email === values.memberTwo__email) {
            return window.alert(
              "Please ensure both members have different email addresses"
            );
          }

          if (values.memberOne__username === values.memberTwo__username) {
            return window.alert(
              "Please ensure both members have different user names"
            );
          }
          const { data } = await axios
            .post("/api/couples-memberships", values, {
              credentials: "include",
            })
            .catch((err) => {
              const { data: errData } = err.response;
              if (errData.error.indexOf("duplicate") > -1) {
                alert(
                  "One of the supplied email address' / username's is already registered as a member. Please choose a different email / username to become a member."
                );
              } else {
                alert(errData.error);
              }
            });

          if (data.memberships) {
            const membershipIds = data.memberships.map(({ _id }) => _id);
            window.location.href = `/memberships/${membershipIds.join(
              "/"
            )}/payment`;
          } else {
            alert(
              "Something went wrong there. Please try again and if the problem persists, please let us know! Thanks for your patience."
            );
          }
        }}
        validationSchema={Yup.object().shape({
          memberOne__title: Yup.string().required("Title is required"),
          memberOne__firstName: Yup.string().required("First name is required"),
          memberOne__surName: Yup.string().required("Surname is required"),
          memberOne__dateOfBirth: Yup.string().required("DOB is required"),
          memberOne__address: Yup.string().required("Address is required"),
          memberOne__phone: Yup.string().required("Phone number is required"),
          memberOne__email: Yup.string()
            .email("Please enter a valid email")
            .required("Email is required"),
          memberOne__membershipType: Yup.string().required(
            "Membership type is required"
          ),
          memberOne__membershipLevel: Yup.string().required(
            "Membership level is required"
          ),
          memberOne__goodHealthCheck: Yup.bool().oneOf(
            [true],
            "Health check is required"
          ),
          memberOne__rulesCheck: Yup.bool().oneOf(
            [true],
            "Rules check is required"
          ),
          memberOne__nextOfKinName: Yup.string().required(
            "Next of kin is required"
          ),
          memberOne__nextOfKinPhone: Yup.string().required(
            "Next of kin phone number is required"
          ),
          memberOne__username: Yup.string().required("Username is required"),
          memberOne__password: Yup.string().required("Password is required"),
          memberTwo__title: Yup.string().required("Title is required"),
          memberTwo__firstName: Yup.string().required("First name is required"),
          memberTwo__surName: Yup.string().required("Surname is required"),
          memberTwo__dateOfBirth: Yup.string().required("DOB is required"),
          memberTwo__address: Yup.string().required("Address is required"),
          memberTwo__phone: Yup.string().required("Phone number is required"),
          memberTwo__email: Yup.string()
            .email("Please enter a valid email")
            .required("Email is required"),
          memberTwo__membershipType: Yup.string().required(
            "Membership type is required"
          ),
          memberTwo__membershipLevel: Yup.string().required(
            "Membership level is required"
          ),
          memberTwo__goodHealthCheck: Yup.bool().oneOf(
            [true],
            "Health check is required"
          ),
          memberTwo__rulesCheck: Yup.bool().oneOf(
            [true],
            "Rules check is required"
          ),
          memberTwo__nextOfKinName: Yup.string().required(
            "Next of kin is required"
          ),
          memberTwo__nextOfKinPhone: Yup.string().required(
            "Next of kin phone number is required"
          ),
          memberTwo__username: Yup.string().required("Username is required"),
          memberTwo__password: Yup.string().required("Password is required"),
        })}
      >
        {(props) => {
          const { handleSubmit, isSubmitting, errors } = props;
          return (
            <form onSubmit={handleSubmit} className="gel-layout">
              <ul className="no-list-style gel-layout__item gs-u-mb+">
                <li className="gs-u-display-inline-block@l gel-1/2@l">
                  <button
                    type="button"
                    className={classNames(
                      "memberships-choice__nav_button button button--large button--full",
                      {
                        "button--active-no-hover": activeMember === "memberOne",
                      }
                    )}
                    onClick={() => setActiveMember("memberOne")}
                  >
                    Member 1 - {getMembershipLevelLabel(memberOne)}
                  </button>
                </li>
                <li className="gs-u-display-inline-block@l gel-1/2@l">
                  <button
                    type="button"
                    className={classNames(
                      "memberships-choice__nav_button button button--large button--full",
                      {
                        "button--active-no-hover": activeMember === "memberTwo",
                      }
                    )}
                    onClick={() => setActiveMember("memberTwo")}
                  >
                    Member 2 - {getMembershipLevelLabel(memberTwo)}
                  </button>
                </li>
              </ul>
              <div className="gel-layout__item gs-u-mt">
                {/* Member 1 */}
                <div
                  className={classNames({
                    "gs-u-display-none": activeMember === "memberTwo",
                  })}
                >
                  <NewMembershipForm
                    elementId="member1"
                    formikProps={props}
                    inputPrefix="memberOne__"
                    title="Member 1 Details"
                  />
                </div>

                {/* Member 2 */}
                <div
                  className={classNames({
                    "gs-u-display-none": activeMember === "memberOne",
                  })}
                >
                  <NewMembershipForm
                    elementId="member2"
                    formikProps={props}
                    inputPrefix="memberTwo__"
                    title="Member 2 Details"
                  />
                </div>

                <ul className="no-list-style gs-u-mb++">
                  <li className="gs-u-display-inline-block@l gel-1/2@l">
                    <button
                      type="button"
                      className={classNames(
                        "memberships-choice__nav_button button button--large button--full",
                        {
                          "button--active-no-hover":
                            activeMember === "memberOne",
                        }
                      )}
                      onClick={() => {
                        setActiveMember("memberOne");
                        setTimeout(
                          () =>
                            document.getElementById("member1").scrollIntoView({
                              behavior: "smooth",
                            }),
                          200
                        );
                      }}
                    >
                      Member 1 - {getMembershipLevelLabel(memberOne)}
                    </button>
                  </li>
                  <li className="gs-u-display-inline-block@l gel-1/2@l">
                    <button
                      type="button"
                      className={classNames(
                        "memberships-choice__nav_button button button--large button--full",
                        {
                          "button--active-no-hover":
                            activeMember === "memberTwo",
                        }
                      )}
                      onClick={() => {
                        setActiveMember("memberTwo");
                        setTimeout(
                          () =>
                            document.getElementById("member2").scrollIntoView({
                              behavior: "smooth",
                            }),
                          200
                        );
                      }}
                    >
                      Member 2 - {getMembershipLevelLabel(memberTwo)}
                    </button>
                  </li>
                </ul>

                <fieldset className="gs-u-mb++ gel-1/1">
                  <button
                    className="button button--filled button--large"
                    type="submit"
                    disabled={isSubmitting || Object.keys(errors).length}
                  >
                    Proceed to payment
                  </button>
                  <div className="input-feedback gs-u-mt">
                    <i>
                      Please make sure you have filled in all required fields
                      for both members before proceeding.
                    </i>
                  </div>
                </fieldset>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default connect(mapStateToProps)(CouplesMembershipForm);
