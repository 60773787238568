import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

/**
 * Card component
 *
 * @param {object} props
 */
const Card = ({ classes, textHtml, imageUrl, ribbonText, isReversed }) => {
  const cardClasses = classNames(
    "card gs-u-align-top gs-o-media-island gs-o-",
    {
      [`${classes}`]: classes
    }
  );

  const layoutClasses = classNames("card__layout gel-layout", {
    "gel-layout--rev": isReversed
  });

  const ribbonClasses = classNames("corner-ribbon sticky shadow", {
    "top-left": !isReversed,
    "top-right": isReversed
  });

  return (
    <li className={cardClasses}>
      {ribbonText && <div className={ribbonClasses}>{ribbonText}</div>}
      <div className="gel-wrap">
        <div className={layoutClasses}>
          <div className="card_image_cont gel-layout__item gs-u-display-inline-block gel-1/1 gel-1/2@l gs-o-media-island gs-u-align-center gs-u-mb++ gs-u-mt++ gs-u-m0@l">
            <img src={imageUrl} alt="" />
          </div>
          <div className="gel-layout__item gs-u-pv+ gel-1/1 gel-1/2@l">
            {textHtml}
          </div>
        </div>
      </div>
    </li>
  );
};

Card.defaultProps = {
  classes: null,
  ribbonText: null,
  isReversed: false
};

Card.propTypes = {
  textHtml: PropTypes.node.isRequired,
  imageUrl: PropTypes.string.isRequired,
  classes: PropTypes.string,
  ribbonText: PropTypes.string,
  isReversed: PropTypes.bool
};

export default Card;
