import React, { Fragment } from 'react';
import Banner from '../../components/Banner';
import TextSection from '../../components/TextSection';

/**
 * Register component
 *
 * @param {object} props
 */
const Register = () => {
  return (
    <Fragment>
      <Banner
        title="Create your Gym at Moffat online account!"
        imageUrl="/assets/img/gym-dark-large.jpeg"
      />
      <TextSection
        title="Get started with The Gym at Moffat online services"
        textHtml={
          <React.Fragment>
            <p className="gs-u-mb++">
              We're looking to offer our gym goers a range of different online
              services, including online bookings. In order to get started with
              this, we'll need you to create an account with us.
            </p>
            <p className="gs-u-mb++">
              If you're <b>already a member</b> with us, this should be really
              quick - as we already have all the details we need from you.
            </p>
            <p className="gs-u-mb++">
              If you're <b>not a member</b>, but would like to access the gym on
              a pay as you go basis, we'll need you to fill out some details for
              us.
            </p>
            <ul className="gel-layout">
              <li className="gel-layout__item gel-1/2@m gs-u-mb">
                <a
                  className="button button--full gs-u-p+"
                  href="/register/non-member"
                >
                  I'm not a member
                </a>
              </li>
              <li className="gel-layout__item gel-1/2@m gs-u-mb">
                <a
                  className="button button--full button--filled gs-u-p+"
                  href="/register/member"
                >
                  I'm a member
                </a>
              </li>
            </ul>
          </React.Fragment>
        }
      />
    </Fragment>
  );
};

export default Register;
