/* eslint-disable prettier/prettier */
import React, { Fragment } from 'react';
import Banner from '../../components/Banner';
import TextSection from '../../components/TextSection';

/**
 * JustGiving component
 *
 * @param {object} props
 */
const JustGiving = () => {
  return (
    <Fragment>
      <Banner title="Just giving" imageUrl="/assets/img/vault.jpeg" />
      <TextSection
        title="Crowdfunding at The Gym at Moffat"
        textHtml={
          <div className="gel-pica">
            <p className="gs-u-mb+">
              We're raising £4,000 to move the existing interior wall to create
              a designated safe area for gymnastics, to expand, improve and help
              us gain formal club status.
            </p>
            <p className="gs-u-mb+">
              Sarah and I have made huge progress over the last 5 years in
              building our gymnastics programme at the Gym at Moffat. We now
              cater for children from pre-toddler up to 14 years and beyond.
            </p>
            <p className="gs-u-mb+">
              Our ambition is to achieve formal club status with Scottish
              Gymnastics so we can start our journey towards competing at a
              National level. To do this, we need our own protected space in the
              gym. We can achieve this by moving an internal wall within the gym
              itself which will increase our floor space, and give us our own
              protected area in which to train.
            </p>
            <p className="gs-u-mb+">
              A small project - hopefully with big results! All funds raised
              will go directly to the development of our gymnastics programme.
            </p>
            <a
              href="https://www.justgiving.com/crowdfunding/gymnasticsatmoffat?utm_term=k7EGXypmG"
              target="_blank"
              className="button button--large"
              rel="noreferrer"
            >
              Give now <i className="fa fa-external-link gs-u-ml" />
            </a>
          </div>
        }
      />
    </Fragment>
  );
};

export default JustGiving;
