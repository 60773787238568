/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/prop-types */
/* eslint-disable no-alert */
import React from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";

axios.defaults.withCredentials = true;

const mapStateToProps = state => ({
  user: get(state, "user.profile", {})
});

const PersonalTrainingEnquiry = ({ user }) => (
  <div className="gel-wrap o-slice-spacing o-slice-spacing-bottom">
    <h2 className="gs-u-mb++">Fill out our Personal Training Enquiry Form!</h2>
    <Formik
      initialValues={{
        firstName: user.firstName || "",
        surName: user.surName || "",
        phone: user.phone || "",
        email: user.email || "",
        ptRequirements: ""
      }}
      onSubmit={async values => {
        const { data } = await axios
          .post("/api/personal-training/enquiry", values, {
            credentials: "include"
          })
          .catch(err => {
            const { data: errData } = err.response;
            console.error(errData);
            alert(
              "Something went wrong there. Please try again and if the problem persists, please let us know! Thanks for your patience."
            );
          });

        if (data.success) {
          window.location.href =
            "/?m=Your Personal Training Enquiry has been submitted. We will be in touch.";
        } else {
          alert(
            "Something went wrong there. Please try again and if the problem persists, please let us know! Thanks for your patience."
          );
        }
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required("First name is required"),
        surName: Yup.string().required("Surname is required"),
        phone: Yup.string().required("Phone number is required"),
        email: Yup.string()
          .email("Please enter a valid email")
          .required("Email is required"),
        ptRequirements: Yup.string().required("PT requirements is required")
      })}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit
        } = props;
        return (
          <form onSubmit={handleSubmit} className="gel-layout">
            <fieldset className="gel-layout__item gs-u-mb++ gel-1/1 gel-6/12@m">
              <label htmlFor="firstName" className="gs-u-display-block">
                First name *
              </label>
              <input
                id="firstName"
                type="text"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.firstName && touched.firstName
                    ? "text-input error"
                    : "text-input"
                }
              />
              {errors.firstName && touched.firstName && (
                <div className="input-error">{errors.firstName}</div>
              )}
            </fieldset>

            <fieldset className="gel-layout__item gs-u-mb++ gel-1/1 gel-6/12@m">
              <label htmlFor="surName" className="gs-u-display-block">
                Surname *
              </label>
              <input
                id="surName"
                type="text"
                value={values.surName}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.surName && touched.surName
                    ? "text-input error"
                    : "text-input"
                }
              />
              {errors.surName && touched.surName && (
                <div className="input-error">{errors.surName}</div>
              )}
            </fieldset>

            <fieldset className="gel-layout__item gs-u-mb++ gel-1/1 gel-6/12@m">
              <label htmlFor="email" className="gs-u-display-block">
                Email *
              </label>
              <input
                id="email"
                type="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.email && touched.email
                    ? "text-input error"
                    : "text-input"
                }
              />
              {errors.email && touched.email && (
                <div className="input-error">{errors.email}</div>
              )}
            </fieldset>

            <fieldset className="gel-layout__item gs-u-mb++ gel-1/1 gel-6/12@m">
              <label htmlFor="phone" className="gs-u-display-block">
                Phone number *
              </label>
              <input
                id="phone"
                type="text"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.phone && touched.phone
                    ? "text-input error"
                    : "text-input"
                }
              />
              {errors.phone && touched.phone && (
                <div className="input-error">{errors.phone}</div>
              )}
            </fieldset>

            <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
              <label htmlFor="ptRequirements" className="gs-u-display-block">
                Your PT requirements *
              </label>
              <textarea
                id="ptRequirements"
                type="text"
                value={values.ptRequirements}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.ptRequirements && touched.ptRequirements
                    ? "text-input error"
                    : "text-input"
                }
              />
              {errors.ptRequirements && touched.ptRequirements && (
                <div className="input-error">{errors.ptRequirements}</div>
              )}
              <div className="input-feedback gs-u-mt">
                <i>What can we do for you?</i>
              </div>
            </fieldset>

            <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
              <button
                className="button button--filled button--large"
                type="submit"
                disabled={isSubmitting}
              >
                Submit
              </button>
            </fieldset>
          </form>
        );
      }}
    </Formik>
  </div>
);

export default connect(mapStateToProps)(PersonalTrainingEnquiry);
