import get from 'lodash/get';
import { isGodOrAdmin } from './users';

const isGymAndClassesMember = user => {
  return (
    get(user, 'membershipLevel', '') === 'gym-and-classes' ||
    get(user, 'membershipLevel', '') === 'gym-and-classes-6-months' ||
    get(user, 'membershipLevel', '') === 'summer' ||
    isGodOrAdmin(user)
  );
};

const isGymMember = user => {
  return (
    ((get(user, 'membershipLevel', '') === 'gym-and-classes' ||
      get(user, 'membershipLevel', '') === 'gym-and-classes-6-months' ||
      get(user, 'membershipLevel', '') === 'gym-only' ||
      get(user, 'membershipLevel', '') === 'gym-only-6-months' ||
      get(user, 'membershipLevel', '') === 'summer') &&
      get(user, 'subscriptionId', '').length > 0) ||
    isGodOrAdmin(user)
  );
};

export { isGymAndClassesMember, isGymMember };
