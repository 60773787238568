/* eslint-disable prettier/prettier */
import React from "react";
import PaymentPlans from "../../containers/PaymentPlans";

/**
 * Payment component
 *
 * @param {object} props
 */
const Payment = () => {
  return (
    <div className="gel-wrap o-slice-spacing o-slice-spacing-bottom">
      <PaymentPlans />
    </div>
  );
};

export default Payment;
