export default [
  {
    day: 'Monday',
    morningClasses: [
      null,
      {
        id: 'gym-circuits',
        timeLabel: '10am - 10.45am',
        label: 'Gym Circuits',
        isBookable: false,
        isHoliday: true,
        startHour: 10,
        startMinutes: 0,
        endHour: 10,
        endMinutes: 45,
      },
      {
        id: 'stay-strong',
        timeLabel: '11am - 12 noon',
        label: 'Stay strong w/ Liz',
        isBookable: true,
        startHour: 11,
        startMinutes: 0,
        endHour: 12,
        endMinutes: 0,
      },
    ],
    afternoonClasses: [
      {
        id: 'spin',
        timeLabel: '5:15pm - 6pm',
        label: 'Spin',
        isBookable: false,
        isHoliday: true,
        startHour: 17,
        startMinutes: 15,
        endHour: 18,
        endMinutes: 0,
        isPrerecorded: false,
        maxBookingSlots: 8,
      },
      {
        id: 'pump',
        timeLabel: '6pm - 7pm',
        label: 'Pump',
        isBookable: false,
        isHoliday: true,
        startHour: 18,
        startMinutes: 0,
        endHour: 19,
        endMinutes: 0,
        isPrerecorded: false,
      },
      null,
    ],
  },
  {
    day: 'Tuesday',
    morningClasses: [
      {
        id: 'spin',
        timeLabel: '10am - 10.45am',
        label: 'Spin',
        isBookable: false,
        isHoliday: true,
        startHour: 10,
        startMinutes: 0,
        endHour: 10,
        endMinutes: 45,
        maxBookingSlots: 10,
      },
      {
        id: 'pickleball',
        timeLabel: '10am - 11am',
        label: 'Beginners Pickleball',
        isBookable: false,
        startHour: 10,
        startMinutes: 0,
        endHour: 11,
        endMinutes: 0,
      },
      {
        id: 'pickleball',
        timeLabel: '11am - 12 noon',
        label: 'Pickleball',
        isBookable: false,
        startHour: 11,
        startMinutes: 0,
        endHour: 12,
        endMinutes: 0,
        maxBookingSlots: 10,
      },
    ],
    afternoonClasses: [
      {
        id: 'oh-my-wod',
        timeLabel: '5:15pm - 6pm',
        label: 'Oh My Wod',
        isBookable: false,
        isHoliday: true,
        startHour: 17,
        startMinutes: 15,
        endHour: 18,
        endMinutes: 0,
      },
      {
        id: 'circuits',
        timeLabel: '6pm - 6.45pm',
        label: 'Circuits',
        isBookable: false,
        isHoliday: true,
        startHour: 18,
        startMinutes: 0,
        endHour: 18,
        endMinutes: 45,
      },
      null,
    ],
  },
  {
    day: 'Wednesday',
    morningClasses: [
      null,
      {
        id: 'circuits',
        timeLabel: '10am - 10.45am',
        label: 'Circuits',
        isBookable: false,
        isHoliday: true,
        startHour: 10,
        startMinutes: 0,
        endHour: 10,
        endMinutes: 45,
        isPrerecorded: false,
      },
      {
        id: 'stay-strong',
        timeLabel: '11am - 12 noon',
        label: 'Stay strong w/ Liz',
        isBookable: true,
        startHour: 11,
        startMinutes: 0,
        endHour: 12,
        endMinutes: 0,
      },
    ],
    afternoonClasses: [
      {
        id: 'workout',
        timeLabel: '5:15pm - 6:15pm',
        label: 'Workout',
        isBookable: false,
        isHoliday: true,
        startHour: 17,
        startMinutes: 15,
        endHour: 18,
        endMinutes: 15,
        isPrerecorded: false,
        maxBookingSlots: 8,
      },
      null,
      null,
    ],
  },
  {
    day: 'Thursday',
    morningClasses: [
      null,
      {
        id: 'lbt',
        timeLabel: '10am - 10.45am',
        label: 'L.B.T',
        isBookable: false,
        isHoliday: true,
        startHour: 10,
        startMinutes: 0,
        endHour: 10,
        endMinutes: 45,
      },
      {
        id: 'pickleball',
        timeLabel: '11am - 12 noon',
        label: 'Pickleball',
        isBookable: false,
        startHour: 11,
        startMinutes: 0,
        endHour: 12,
        endMinutes: 0,
        maxBookingSlots: 10,
      },
    ],
    afternoonClasses: [
      {
        id: 'spin',
        timeLabel: '5:15pm - 6pm',
        label: 'Spin',
        isBookable: false,
        isHoliday: true,
        startHour: 17,
        startMinutes: 15,
        endHour: 18,
        endMinutes: 0,
        isPrerecorded: false,
      },
      null,
      null,
    ],
  },
  {
    day: 'Friday',
    morningClasses: [
      null,
      {
        id: 'hitt-cardio',
        timeLabel: '10am - 10.45am',
        label: 'HITT Cardio',
        isBookable: false,
        isHoliday: true,
        startHour: 10,
        startMinutes: 0,
        endHour: 10,
        endMinutes: 45,
      },
      null,
    ],
    afternoonClasses: [null, null, null],
  },
  {
    day: 'Saturday',
    morningClasses: [
      {
        id: 'fiftyfifty',
        timeLabel: '9am - 10am',
        label: '50/50',
        isBookable: false,
        isHoliday: true,
        startHour: 9,
        startMinutes: 0,
        endHour: 10,
        endMinutes: 0,
      },
      {
        id: 'pickleball',
        timeLabel: '10am - 11am',
        label: 'Pickleball',
        isBookable: false,
        startHour: 10,
        startMinutes: 0,
        endHour: 11,
        endMinutes: 0,
        maxBookingSlots: 10,
      },
      null,
    ],
    afternoonClasses: [null, null, null],
  },
  {
    day: 'Sunday',
    morningClasses: [null, null, null],
    afternoonClasses: [null, null, null],
  },
];
