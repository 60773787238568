/* eslint-disable prettier/prettier */
import React, { Fragment } from "react";
import Banner from "../../components/Banner";
import TextSection from "../../components/TextSection";
import CardGrid from "../../components/CardGrid";

/**
 * PaymentSuccess component
 *
 * @param {object} props
 */
const PaymentSuccess = () => {
  return (
    <Fragment>
      <Banner title="Welcome to The Gym at Moffat!" imageUrl="/assets/img/celebrate.jpg" />
      <TextSection
        title="Your membership is now all set up."
        textHtml={(
          <React.Fragment>
            <p className="gs-u-mb++">
              You should receive a confirmation email from us but if you have any questions in the meantime, please don't hesitate to get in touch with us.
            </p>
            <a className="button gs-u-p+" href="/">Take me back to the home page.</a>
          </React.Fragment>
        )}
      />
    </Fragment>
  );
};

export default PaymentSuccess;
