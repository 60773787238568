import React from 'react';
import { connect } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import { getAsCalendarEvents } from '../utils/calendar';
import { toggleModal } from '../store/actions/ui';
import { setActiveBooking } from '../store/actions/bookings';

moment.locale('en-GB', {
  week: {
    dow: 1,
    doy: 1,
  },
});

const localizer = momentLocalizer(moment);

const mapStateToProps = state => ({
  bookings: get(state, 'bookings.list', []),
});

const mapDispatchToProps = dispatch => ({
  setModalState: isModalOpen => dispatch(toggleModal(isModalOpen)),
  setBookingState: activeBooking => dispatch(setActiveBooking(activeBooking)),
});

/**
 * BookingsCalendar component
 *
 * @param {object} props
 */
const BookingsCalendar = ({ bookings, setModalState, setBookingState }) => {
  return (
    <div className="o-slice-spacing-bottom">
      <Calendar
        localizer={localizer}
        events={getAsCalendarEvents(bookings)}
        startAccessor="start"
        endAccessor="end"
        defaultView="week"
        style={{ height: 500 }}
        popup
        onSelectEvent={(event, e) => {
          setBookingState(event);
          setModalState(true);
        }}
      />
    </div>
  );
};

BookingsCalendar.defaultProps = {
  bookings: [],
};

BookingsCalendar.propTypes = {
  bookings: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingsCalendar);
