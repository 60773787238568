import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

/**
 * TextSection component
 *
 * @param {object} props
 */
const TextSection = ({ classes, title, textHtml, hash }) => {
  const textSectionClasses = classNames(
    "text-section gs-u-ph gs-u-pv++ gs-u-mv++",
    {
      [`${classes}`]: classes
    }
  );

  return (
    <div className={textSectionClasses} id={hash || undefined}>
      <div className="text-section__inner gs-u-ph++ gs-u-align-center">
        {title && (
          <h2 className="text-section__title gel-double-pica-bold gs-u-display-inline-block gs-u-mb++">
            {title}
          </h2>
        )}
        <div className="gel-pica">{textHtml}</div>
      </div>
    </div>
  );
};

TextSection.defaultProps = {
  classes: null,
  title: null,
  textHtml: null,
  hash: null
};

TextSection.propTypes = {
  title: PropTypes.string,
  textHtml: PropTypes.any,
  classes: PropTypes.string,
  hash: PropTypes.string
};

export default TextSection;
