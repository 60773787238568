import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const MEMBERSHIP_KEYS = [
  "title",
  "firstName",
  "surName",
  "dateOfBirth",
  "email",
  "phone"
];

const KEYS_TO_LABEL = {
  title: "Title",
  firstName: "First name",
  surName: "Surname",
  dateOfBirth: "Date of birth",
  email: "Email address",
  phone: "Phone number"
};

/**
 * MembershipDetails component
 *
 * @param {object} props
 */
const MembershipDetails = ({ membership, classes }) => {
  const membershipDetails = classNames("membership-details gel-layout", {
    [`${classes}`]: classes
  });

  return (
    <ul className={membershipDetails}>
      {MEMBERSHIP_KEYS.map(key => {
        return (
          <li
            className="membership-details__item gel-layout__item gs-u-p+ gel-1/2@m gel-1/3@xl"
            key={`membership-${key}`}
          >
            <span className="membership-details__label gel-brevier-bold">{KEYS_TO_LABEL[key]}</span>
            <p className="membership-details__value gel-pica">{membership[key]}</p>
          </li>
        );
      })}
    </ul>
  );
};

MembershipDetails.defaultProps = {
  classes: null
};

MembershipDetails.propTypes = {
  membership: PropTypes.object.isRequired,
  classes: PropTypes.string
};

export default MembershipDetails;
