/* eslint-disable prettier/prettier */
import React, { Fragment } from 'react';
import Banner from '../../components/Banner';
import TextSection from '../../components/TextSection';
import CardGrid from '../../components/CardGrid';

/**
 * Home component
 *
 * @param {object} props
 */
const Home = () => {
  return (
    <Fragment>
      <Banner title="About Us" imageUrl="/assets/img/about-us.jpeg" />
      <TextSection
        title="Welcome to The Gym at Moffat"
        textHtml={
          <div className="gel-pica">
            <p className="gs-u-mb+">
              With <b>low cost memberships</b>, <b>quality gym equipment</b>,{' '}
              <b>classes & personal training</b>, there are loads of reasons why{' '}
              <b>The Gym at Moffat</b> is worth a visit.
            </p>
            <p className="gs-u-mb+">
              Our team are all local to the area - Robert, Mike & Jo have a wide
              range of knowledge and expertise. We pride outselves as being
              friendly, laid back and approachable.
            </p>
            <p>Come along, and have a wander around our facility!</p>
          </div>
        }
      />

      <CardGrid
        title="Meet the team!"
        cardItems={[
          {
            textHtml: (
              <div className="gel-pica">
                <h3 className="gs-u-display-block gs-u-mb gel-pica-bold">
                  Robert Meiklem
                </h3>
                <p className="gs-u-mb">
                  Since the age of 16 I've been working within the leisure and
                  fitness industry. For me, it has never been a job, it's been
                  my passion.
                </p>
                <p className="gs-u-mb">
                  Over the years I've pretty much done every role you can do
                  from Leisure attendant, gym instructor, class instructor,
                  personal trainer, Health club Manager and Gym owner.
                </p>
                <p className="gs-u-mb">
                  What I give, is all my experience to you - whether you're
                  coming in as a new gym goer and need some detail on how to use
                  something, or maybe your coming into a class and want put
                  through a workout, or perhaps your after some 1 on 1 training
                  to make sure whatever goal you have set yourself, you have
                  someone to work with and push you to that goal.
                </p>
                <p className="gs-u-mb">
                  I've used many different sports in my time to aid my fitness
                  and redesign how I train personally and how I work with people
                  on an individual basis. Over the last few years I’ve moved
                  away from combat and physical sports like MMA and Pro
                  Wrestling into aesthetic and gym related sports like Men’s
                  Physique. My Work over the past few years has allowed me to
                  develop my skills as a trainer and a coach to help people
                  change their body composition and feel way better about how
                  they look whether this has been to reduce body fat, increase
                  muscle mass or general toning up.
                </p>
                <p className="gs-u-mb">
                  With this much experience you can rest assured you will be in
                  safe hands with whatever capacity you need me for.
                </p>
              </div>
            ),
            imageUrl: '/assets/img/robert.jpeg',
            ribbonText: 'Manager',
          },
          {
            textHtml: (
              <div className="gs-u-ph+">
                <h3 className="gs-u-display-block gs-u-mb gel-pica-bold">Jo</h3>
                <p className="gs-u-mb">
                  Hi my name is Jo and I've been working at the Gym at Moffat
                  for the past 6 years. From my time in the fitness industry I
                  have gained many skills and qualifications for example, I'm a
                  qualified classes instructor and teach a variety of classes
                  such as Spin, HITT Cardio, Circuits and much more.
                </p>
                <p className="gs-u-mb">
                  I'm also a qualified level 3 Personal Trainer who aims to help
                  people meet their goals in a Physical and Mental aspect in the
                  fitness industry. Goals like weight loss, strength trainer and
                  even getting the first steps in the gym. In the future I would
                  like to enhance my skills further to enhance my knowledge in
                  fitness.
                </p>
              </div>
            ),
            imageUrl: '/assets/img/jo.png',
          },
          {
            textHtml: (
              <div className="gel-pica">
                <h3 className="gs-u-display-block gs-u-mb gel-pica-bold">
                  Janey Barbieri
                </h3>
                <p className="gs-u-mb">
                  I am the person you will see working in the office and around
                  the gym. I help to keep things running smoothly and will
                  always be at the end of the phone when you need help and
                  information.
                </p>
                <p className="gs-u-mb">
                  In my spare time I am a dressage rider and having 2 horses
                  keeps me fit. I am passionate about providing a friendly, easy
                  to use gym service for the people of Moffat and surrounding
                  areas.
                </p>
              </div>
            ),
            imageUrl: '/assets/img/janey.jpeg',
          },
        ]}
      />
    </Fragment>
  );
};

export default Home;
