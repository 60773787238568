/* eslint-disable prettier/prettier */
import React, { Fragment } from 'react';
import Banner from '../../components/Banner';
import TextSection from '../../components/TextSection';

/**
 * Teens component
 *
 * @param {object} props
 */
const Teens = () => {
  return (
    <Fragment>
      <Banner
        title="Teenagers at The Gym at Moffat"
        imageUrl="/assets/img/vault.jpeg"
      />
      <TextSection
        textHtml={
          <div className="gel-pica" style={{ textAlign: 'left' }}>
            <p className="gs-u-mb+">
              <b>The Gym at Moffat welcomes Teens!</b>
              <span className="gs-u-display-block gs-u-mt+">
                At The Gym at Moffat, we are committed to supporting local
                teenagers by providing a safe and welcoming space where they can
                stay active, build confidence, and connect with others in the
                community.
              </span>
            </p>
            <p className="gs-u-mb+">
              <b>What kind of activities do we offer?</b>
              <span className="gs-u-display-block gs-u-mt+">
                We offer a range of activities for teens, including memberships,
                fitness classes, badminton, pickleball, indoor football, indoor
                tennis, personal training, and gymnastics.
              </span>
            </p>
            <p className="gs-u-mb+">
              <b>Why is going to the gym good for me?</b>
              <span className="gs-u-display-block gs-u-mt+">
                Engaging in regular physical activity offers numerous benefits,
                including improved mental well-being, increased energy levels,
                and enhanced self-esteem. It also serves as an effective way to
                manage stress—all while keeping you active and having fun!
              </span>
            </p>
            <p className="gs-u-mb+">
              <b>Should I be afraid?</b>
              <span className="gs-u-display-block gs-u-mt+">
                We take pride in welcoming many teen members who already enjoy
                our facilities, so there’s no need to feel self-conscious or
                apprehensive. The Gym at Moffat is a safe and supportive
                environment where you can feel comfortable at all times.
              </span>
              <span className="gs-u-display-block gs-u-mt+">
                Our friendly staff are always available to offer guidance,
                support, and address any concerns you may have. We are committed
                to helping you feel confident and empowered on your fitness
                journey.
              </span>
            </p>
            <p className="gs-u-mb+">
              <b>Is it expensive?</b>
              <span className="gs-u-display-block gs-u-mt+">
                The Gym at Moffat offers dedicated memberships for teens aged 12
                to 15. Our membership plans start at just £15 per month on
                subscription, with an option to include unlimited class access
                for £20 per month. We also provide discounts on annual
                memberships and pay-as-you-go (PAYG) options.
              </span>
              <span className="gs-u-display-block gs-u-mt+">
                A complimentary gym induction is required upon joining, ensuring
                that all new members are familiar with our facilities and
                equipment. During the induction, our team will guide you through
                the gym and answer any questions you may have.
              </span>
            </p>
            <p className="gs-u-mb+">
              <b>Rules</b>
              <span className="gs-u-display-block gs-u-mt+">
                Members under the age of 16 must be accompanied by an adult and
                are required to complete an induction. Additionally, permission
                and training from the manager are mandatory for the use of free
                weights.
              </span>
            </p>
            <p className="gs-u-mb+">
              <b className="gs-u-display-block gs-u-mb+">Hours</b>
              <span>
                Staff supervision is available Monday to Thursday from 4:00 PM
                to 7:00 PM. Additionally, every Wednesday from 4:00 PM to 5:00
                PM, we host Teen Hour, where a personal trainer provides free
                training and advice.
              </span>
            </p>
            <p className="gs-u-mb+">
              <b className="gs-u-display-block gs-u-mb+">Classes</b>
              <span>
                All classes are open to teens, including our interactive,
                darkened spin room — perfect for those who prefer a more
                immersive and comfortable environment.
              </span>
            </p>
            <p className="gs-u-mb+">
              <b className="gs-u-display-block gs-u-mb+">Court hire</b>
              <span>
                Teen members enjoy free access to activities such as badminton,
                basketball, indoor football, pickleball, and more. Please note
                that adult supervision is required.
              </span>
            </p>
          </div>
        }
      />
    </Fragment>
  );
};

export default Teens;
