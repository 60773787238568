import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import TextSection from '../components/TextSection';

const mapStateToProps = state => ({
  user: get(state, 'user'),
});

/**
 * BookingsHeader container
 *
 * @param {object} props
 */
const BookingsHeader = ({ user, context = 'classes' }) => {
  if (!user) {
    return (
      <React.Fragment>
        <section>
          <TextSection
            title=""
            classes="gs-u-p0 gs-u-mv0 gs-u-mv"
            textHtml={
              <React.Fragment>
                <ul className="gel-layout">
                  <li className="gel-layout__item gel-1/2@m gs-u-mb++">
                    <p className="gs-u-mb gs-u-align-center">
                      <b>Already have an account?</b>
                    </p>
                    <a
                      className="button button--full gs-u-p+"
                      href={`/login?ptrt=/${
                        context === 'court hire' ? 'court-hire' : 'classes'
                      }`}
                    >
                      Sign in to book {context}
                    </a>
                  </li>
                  <li className="gel-layout__item gel-1/2@m gs-u-mb++">
                    <p className="gs-u-mb gs-u-align-center">
                      <b>Want to join us?</b>
                    </p>
                    <a
                      className="button button--full gs-u-p+"
                      href="/memberships"
                    >
                      Join us to book {context}
                    </a>
                  </li>
                </ul>
              </React.Fragment>
            }
          />
        </section>
      </React.Fragment>
    );
  }

  return null;
};

BookingsHeader.defaultProps = {};

BookingsHeader.propTypes = {};

export default connect(mapStateToProps)(BookingsHeader);
