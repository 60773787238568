import React from 'react';
import moment from 'moment-timezone';

const getBookingDate = booking => {
  const format = 'ddd, MMM Do - H:mm a';
  if (booking.bookingStartDate) {
    return moment(booking.bookingStartDate)
      .tz('Europe/London')
      .format(format);
  }

  if (booking.created_date) {
    return moment(booking.created_date)
      .tz('Europe/London')
      .format(format);
  }
};

/**
 * BookingItem component
 *
 * @param {object} props
 */
const BookingItem = ({ booking = {} }) => {
  return (
    <li className="bookings-list__item gs-u-mb+" key={booking._id}>
      <span className="gs-u-clearfix gs-u-display-block gs-u-p+">
        <b>{booking.class && booking.class.label}</b>
        <span className="gs-u-ml+ gs-u-display-inline-block">
          {booking.firstName} {booking.surName}
        </span>

        <a
          href={`/api/bookings/${booking._id}/delete`}
          className="gs-u-display-inline-block@m gel-brevier gs-u-ml+ gs-u-display-inline-block gs-u-float-right gs-u-mt--"
          title="Delete this booking"
          onClick={e => {
            e.preventDefault();
            if (
              window.confirm('Are you sure you want to delete this booking?')
            ) {
              window.location.href = `/api/bookings/${booking._id}/delete?ptrt=${window.location.pathname}`;
            }
          }}
        >
          <i className="fa fa-trash" />
        </a>
        <span className="bookings-list__time gs-u-display-block gs-u-display-inline@m gel-brevier gs-u-ml@m gs-u-display-inline-block gs-u-float-right@m gs-u-mt gs-u-mt0@m">
          <i className="fa fa-calendar gs-u-mr" />
          {getBookingDate(booking)}
        </span>
      </span>
    </li>
  );
};

export default BookingItem;
