/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-target-blank */
import React, { useMemo } from "react";
import moment from "moment";
import get from "lodash/get";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTable, useSortBy, useExpanded } from "react-table";

const transformUsers = users => {
  return users.map(user => {
    return {
      firstName: user.firstName,
      surName: user.surName,
      email: user.email,
      actions: user,
      created_date: user.created_date
    };
  });
};

function ActionsCell({ value }) {
  return (
    <a
      className="gs-u-display-block delete"
      href={`/api/users/${value._id}/delete`}
      onClick={e => {
        e.preventDefault();
        // eslint-disable-next-line no-alert
        const shouldDelete = window.confirm(
          "Are you sure you want to delete this user? This action can't be reversed."
        );

        if (shouldDelete) {
          window.location.href = e.target.getAttribute("href");
        }
      }}
    >
      <i className="fa fa-trash icon--delete gs-u-mr" />
      Remove
    </a>
  );
}

const usersColumns = [
  {
    Header: "First name",
    accessor: "firstName"
  },
  {
    Header: "Surname",
    accessor: "surName"
  },
  {
    Header: "Email",
    accessor: "email"
  },
  {
    Header: "Joined",
    accessor: "created_date",
    Cell: ({ value }) => {
      return moment(value).format("ddd, MMM Do - H:mm a");
    }
  },
  {
    Header: "Actions",
    accessor: "actions",
    Cell: ActionsCell
  },
  {
    Header: () => null,
    id: "expander", // It needs an ID
    Cell: ({ row }) => (
      // Use Cell to render an expander for each row.
      // We can use the getToggleRowExpandedProps prop-getter
      // to build the expander.
      <span className="gs-u-float-right" {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? (
          <i className="fa fa-chevron-down" />
        ) : (
          <i className="fa fa-chevron-right" />
        )}
      </span>
    )
  }
];

const mapStateToProps = state => ({
  users: transformUsers(get(state, "members.list", []))
});

function renderRowSubComponent({ row }) {
  const {
    original: { actions: user }
  } = row;
  return (
    <div>
      <div className="membership-details__content gs-u-p++ gel-1/1">
        <ul className="membership-details__list gel-1/1">
          <li className="gel-1/3 gs-u-mb+ gs-u-display-inline-block">
            <span className="gel-pica-bold gs-u-display-block gs-u-mb">ID</span>
            <span className="gel-pica">{user._id}</span>
          </li>
          <li className="gel-1/3 gs-u-mb+ gs-u-display-inline-block">
            <span className="gel-pica-bold gs-u-display-block gs-u-mb">
              Address
            </span>
            <span className="gel-pica">{user.address}</span>
          </li>
          <li className="gel-1/3 gs-u-mb+ gs-u-display-inline-block">
            <span className="gel-pica-bold gs-u-display-block gs-u-mb">
              Phone
            </span>
            <span className="gel-pica">{user.phone}</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

/**
 * UsersTable component
 *
 * @param {object} props
 */
const UsersTable = ({ users, title }) => {
  if (!users.length) {
    return (
      <div className="bookings">
        <h2 className="text-section__title gel-great-primer-bold gs-u-display-inline-block gs-u-mb++">
          {title}
        </h2>
        <p>
          Looks like there's currently no users. Check back later to see if
          any come through.
        </p>
      </div>
    );
  }

  const data = useMemo(() => users, []);
  const columns = useMemo(() => usersColumns, []);
  const tableInstance = useTable({ columns, data }, useSortBy, useExpanded);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns
  } = tableInstance;

  return (
    <div className="bookings gel-wrap classes__wrapper">
      <table {...getTableProps()}>
        <thead>
          {// Loop over the header rows
          headerGroups.map(headerGroup => (
            // Apply the header row props
            <tr className="table-col" {...headerGroup.getHeaderGroupProps()}>
              {// Loop over the headers in each row
              headerGroup.headers.map(column => (
                // Apply the header cell props
                <th
                  className="gel-pica-bold table-col gs-u-p gs-u-p+@l"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {// Render the header
                  column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <i className="fa fa-chevron-down gs-u-ml-" />
                        : <i className="fa fa-chevron-up gs-u-ml-" />
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {// Loop over the table rows
          rows.map(row => {
            // Prepare the row for display
            prepareRow(row);
            return (
              <>
                <tr {...row.getRowProps()}>
                  {// Loop over the rows cells
                  row.cells.map(cell => {
                    // Apply the cell props
                    return (
                      <td
                        className="gel-long-primer gs-u-p+"
                        {...cell.getCellProps()}
                      >
                        {// Render the cell contents
                        cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                      {renderRowSubComponent({ row })}
                    </td>
                  </tr>
                ) : null}
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

UsersTable.defaultProps = {
  users: [],
  title: "Users"
};

UsersTable.propTypes = {
  users: PropTypes.array,
  title: PropTypes.string
};

export default connect(mapStateToProps)(UsersTable);
