/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/prop-types */
/* eslint-disable no-alert */
import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import MemberDetailsFormFields from "../components/MemberDetailsFormFields";
import MedicalQuestionnaireFormFields from "../components/MedicalQuestionnaireFormFields";
import MemberLoginFormFields from "../components/MemberLoginFormFields";

axios.defaults.withCredentials = true;

const mapStateToProps = (state) => ({
  type: state.ui.type,
  level: state.ui.level,
});

const MembershipForm = ({ type, level }) => (
  <div className="gel-wrap o-slice-spacing o-slice-spacing-bottom">
    <h2 className="gs-u-mb++">Fill out our Membership form!</h2>
    <Formik
      initialValues={{
        title: "",
        firstName: "",
        surName: "",
        dateOfBirth: "",
        address: "",
        phone: "",
        email: "",
        marketingOptOut: false,
        membershipType: type === "child" ? "child" : "adult",
        membershipLevel: level,
        gymInduction: "gym-induction-yes",
        medicalQuestionnaire1: "q1-no",
        medicalQuestionnaire1Explain: "",
        medicalQuestionnaire2: "q2-no",
        medicalQuestionnaire2Explain: "",
        medicalQuestionnaire3: "q3-no",
        medicalQuestionnaire3Explain: "",
        medicalQuestionnaire4: "q4-no",
        medicalQuestionnaire4Explain: "",
        medicalQuestionnaire5: "q5-no",
        medicalQuestionnaire5Explain: "",
        medicalQuestionnaire6: "q6-no",
        medicalQuestionnaire6Explain: "",
        medicalQuestionnaire7: "q7-no",
        medicalQuestionnaire7Explain: "",
        medicalQuestionnaire8: "q8-no",
        medicalQuestionnaire8Explain: "",
        medicalQuestionnaire9: "q9-no",
        medicalQuestionnaire9Explain: "",
        goodHealthCheck: false,
        rulesCheck: false,
        socialMediaSharing: false,
        nextOfKinName: "",
        nextOfKinPhone: "",
        username: "",
        password: "",
      }}
      onSubmit={async (values) => {
        const { data } = await axios
          .post("/api/memberships", values, { credentials: "include" })
          .catch((err) => {
            const { data: errData } = err.response;
            if (errData.error.indexOf("duplicate") > -1) {
              alert(
                "That email address / username is already registered as a member. Please choose a different email / username to become a member."
              );
            } else {
              alert(errData.error);
            }
          });

        if (data.membership && data.membership._id) {
          window.location.href = `/memberships/${data.membership._id}/payment`;
        } else {
          alert(
            "Something went wrong there. Please try again and if the problem persists, please let us know! Thanks for your patience."
          );
        }
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required("Title is required"),
        firstName: Yup.string().required("First name is required"),
        surName: Yup.string().required("Surname is required"),
        dateOfBirth: Yup.string().required("DOB is required"),
        address: Yup.string().required("Address is required"),
        phone: Yup.string().required("Phone number is required"),
        email: Yup.string()
          .email("Please enter a valid email")
          .required("Email is required"),
        membershipType: Yup.string().required("Membership type is required"),
        membershipLevel: Yup.string().required("Membership level is required"),
        goodHealthCheck: Yup.bool().oneOf([true], "Health check is required"),
        rulesCheck: Yup.bool().oneOf([true], "Rules check is required"),
        nextOfKinName: Yup.string().required("Next of kin is required"),
        nextOfKinPhone: Yup.string().required(
          "Next of kin phone number is required"
        ),
        username: Yup.string().required("Username is required"),
        password: Yup.string().required("Password is required"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <form onSubmit={handleSubmit} className="gel-layout">
            <MemberDetailsFormFields
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              isMembershipForm
            />

            <h3 className="gel-great-primer-bold gel-1/1 gel-layout__item gs-u-mb++">
              Gym induction
            </h3>

            <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
              <label
                className="gs-u-display-inline-block gs-u-mr+ gs-u-mb++"
                htmlFor="gym-induction-yes"
              >
                <input
                  type="radio"
                  className="gs-u-mr"
                  id="gym-induction-yes"
                  name="gymInduction"
                  value="gym-induction-yes"
                  checked={values.gymInduction === "gym-induction-yes"}
                  onChange={handleChange}
                />
                I require a gym induction
                <span className="label-info gs-u-ml">
                  You will be contacted by one of our team to organise this in
                  due course.
                </span>
              </label>
              {values.membershipType === "adult" && (
                <label
                  className="gs-u-display-inline-block"
                  htmlFor="gym-induction-no"
                >
                  <input
                    type="radio"
                    className="gs-u-mr"
                    id="gym-induction-no"
                    name="gymInduction"
                    value="gym-induction-no"
                    checked={values.gymInduction === "gym-induction-no"}
                    onChange={handleChange}
                  />
                  I do not require a gym induction
                  <span className="label-info gs-u-ml">
                    You must have previous gym experience and suffucient
                    experience to use the equipment safely.
                  </span>
                </label>
              )}
            </fieldset>

            <MedicalQuestionnaireFormFields
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />

            <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
              <label className="gs-u-mb+" htmlFor="rulesCheck">
                I agree to abide by the rules of the gym and I undertake to read
                all instructions carefully before using the equipment. I
                understand that I use this facility at my own risk and that The
                Gym accepts no liability for injury or damage. *
                <input
                  className="gs-u-ml"
                  type="checkbox"
                  id="rulesCheck"
                  checked={values.rulesCheck}
                  onChange={handleChange}
                />
                {errors.rulesCheck && touched.rulesCheck && (
                  <span className="input-error">{errors.rulesCheck}</span>
                )}
              </label>

              <label className="gs-u-mb+" htmlFor="socialMediaSharing">
                I am happy for my photograph to be shared on social media sites
                <input
                  className="gs-u-ml"
                  type="checkbox"
                  id="socialMediaSharing"
                  checked={values.socialMediaSharing}
                  onChange={handleChange}
                />
              </label>
            </fieldset>

            <h3 className="gel-great-primer-bold gel-1/1 gel-layout__item gs-u-mb++">
              Next of kin
            </h3>

            <fieldset className="gel-layout__item gs-u-mb++ gel-6/12">
              <label htmlFor="nextOfKinName" className="gs-u-display-block">
                Name *
              </label>
              <input
                id="nextOfKinName"
                type="text"
                value={values.nextOfKinName}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.nextOfKinName && touched.nextOfKinName
                    ? "text-input error"
                    : "text-input"
                }
              />
              {errors.nextOfKinName && touched.nextOfKinName && (
                <div className="input-error">{errors.nextOfKinName}</div>
              )}
            </fieldset>

            <fieldset className="gel-layout__item gs-u-mb++ gel-6/12">
              <label htmlFor="nextOfKinPhone" className="gs-u-display-block">
                Phone number *
              </label>
              <input
                id="nextOfKinPhone"
                type="tel"
                value={values.nextOfKinPhone}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.nextOfKinPhone && touched.nextOfKinPhone
                    ? "text-input error"
                    : "text-input"
                }
              />
              {errors.nextOfKinPhone && touched.nextOfKinPhone && (
                <div className="input-error">{errors.nextOfKinPhone}</div>
              )}
            </fieldset>

            <MemberLoginFormFields
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />

            <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
              <button
                className="button button--filled button--large"
                type="submit"
                disabled={isSubmitting}
              >
                Proceed to payment
              </button>
            </fieldset>
          </form>
        );
      }}
    </Formik>
  </div>
);

export default connect(mapStateToProps)(MembershipForm);
