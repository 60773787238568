import React, { Fragment } from "react";
import TextSection from "../../components/TextSection";
import UsersTable from "../../containers/UsersTable";

/**
 * AdminUsers component
 *
 * @param {object} props
 */
const AdminUsers = () => {
  return (
    <Fragment>
      <TextSection title="Users" textHtml />
      <div className="gel-wrap o-slice-spacing-bottom">
        <UsersTable />
      </div>
    </Fragment>
  );
};

export default AdminUsers;
