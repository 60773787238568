/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import PropTypes from "prop-types";
import { QUESTIONS } from "../utils/consts";

const renderQuestion = (
  question,
  number,
  values,
  handleChange,
  handleBlur,
  inputPrefix
) => {
  return (
    <li className="gs-u-mb+" key={`question-${number}`}>
      <div className="gs-u-clearfix">
        {question}
        <span className="gs-u-float-right">
          <label
            className="gs-u-display-inline-block gs-u-mr+"
            htmlFor={`${inputPrefix}q${number}-yes`}
          >
            <input
              type="radio"
              className="gs-u-mr"
              id={`${inputPrefix}q${number}-yes`}
              name={`${inputPrefix}medicalQuestionnaire${number}`}
              value={`q${number}-yes`}
              checked={
                values[`${inputPrefix}medicalQuestionnaire${number}`] ===
                `q${number}-yes`
              }
              onChange={handleChange}
            />
            Yes
          </label>
          <label
            className="gs-u-display-inline-block"
            htmlFor={`${inputPrefix}q${number}-no`}
          >
            <input
              type="radio"
              className="gs-u-mr"
              id={`${inputPrefix}q${number}-no`}
              name={`${inputPrefix}medicalQuestionnaire${number}`}
              value={`q${number}-no`}
              checked={
                values[`${inputPrefix}medicalQuestionnaire${number}`] ===
                `q${number}-no`
              }
              onChange={handleChange}
            />
            No
          </label>
        </span>
      </div>
      <input
        id={`${inputPrefix}medicalQuestionnaire${number}Explain`}
        placeholder="Additional information"
        type="text"
        value={values[`${inputPrefix}medicalQuestionnaire${number}Explain`]}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </li>
  );
};

/**
 * MedicalQuestionnaireFormFields component
 *
 * @param {object} props
 */
const MedicalQuestionnaireFormFields = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  inputPrefix,
}) => {
  return (
    <React.Fragment>
      <h3 className="gel-great-primer-bold gel-1/1 gel-layout__item gs-u-mb++">
        Medical Questionnaire
      </h3>

      <div className="gel-layout__item gs-u-mb++ gel-1/1">
        <p className="gs-u-mb+">
          Please answer the following questons by selecting the appropriate box.
        </p>
        <p>
          <span className="label-info">
            If you answer yes to any of these questions please explain in the
            text box provided.
          </span>
        </p>
      </div>
      <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
        <ol className="gs-u-pl+">
          {QUESTIONS.map((question, index) =>
            renderQuestion(
              question,
              index + 1,
              values,
              handleChange,
              handleBlur,
              inputPrefix
            )
          )}
        </ol>
        <p className="gs-u-mb+">
          If you answered <span className="input-error">YES</span> to one or
          more questions, please consult your doctor <i>before</i> using this
          gym.
        </p>
        <p className="gs-u-mb+">
          Please ask their advice as to whether you can undertake unrestricted
          exercises on both cardiovascular equipment (bikes, steppers and
          rowers) and resistance machines. Follow your doctor's advice.
        </p>
        <p className="gs-u-mb+">
          We take <span className="input-error">no responsibility</span> for
          <span className="input-error"> pre existing conditions</span> and will
          require you to produce a doctor's note prior to gaining entry.
        </p>

        <label htmlFor={`${inputPrefix}goodHealthCheck`} className="gs-u-mb+">
          I have read the above questionnaire and to the best of my knowledge, I
          am in good health and fit to use the gym. *
          <input
            className="gs-u-ml"
            type="checkbox"
            id={`${inputPrefix}goodHealthCheck`}
            checked={values[`${inputPrefix}goodHealthCheck`]}
            onChange={handleChange}
          />
          {errors[`${inputPrefix}goodHealthCheck`] &&
            touched[`${inputPrefix}goodHealthCheck`] && (
              <span className="input-error">
                {errors[`${inputPrefix}goodHealthCheck`]}
              </span>
            )}
        </label>
      </fieldset>
    </React.Fragment>
  );
};

MedicalQuestionnaireFormFields.defaultProps = {
  inputPrefix: "",
};

MedicalQuestionnaireFormFields.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  inputPrefix: PropTypes.string,
};

export default MedicalQuestionnaireFormFields;
