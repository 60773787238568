/* eslint-disable react/no-array-index-key */
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Card from "./Card";

/**
 * CardGrid component
 *
 * @param {object} props
 */
const CardGrid = ({ cardItems, title, classes }) => {
  const cardGridInner = classNames("card-grid__inner", {
    [`${classes}`]: classes
  });

  return (
    <div className="card-grid">
      <div className="gs-u-align-center">
        <h2 className="card-grid__title gel-double-pica-bold gs-u-display-inline-block gs-u-mb++">
          {title}
        </h2>
      </div>
      <ul className={cardGridInner}>
        {cardItems.map((card, index) => (
          <Card key={`card-${index}`} {...card} />
        ))}
      </ul>
    </div>
  );
};

CardGrid.defaultProps = {
  classes: null,
  title: null
};

CardGrid.propTypes = {
  cardItems: PropTypes.array.isRequired,
  title: PropTypes.string,
  classes: PropTypes.string
};

export default CardGrid;
