import React from 'react';
import CouplesPaymentPlans from '../../containers/CouplesPaymentPlans';

/**
 * CouplesPayment component
 *
 * @param {object} props
 */
const CouplesPayment = () => {
  return (
    <div className="gel-wrap o-slice-spacing o-slice-spacing-bottom">
      <CouplesPaymentPlans />
    </div>
  );
};

export default CouplesPayment;
