/* eslint-disable prettier/prettier */
import React, { Fragment } from "react";
import Section from "../../components/Section";
import BackgroundVideo from "../../containers/BackgroundVideo";

/**
 * PersonalTraining component
 *
 * @param {object} props
 */
const PersonalTraining = () => {
  return (
    <React.Fragment>
      <BackgroundVideo videoUrl="https://s3-eu-west-1.amazonaws.com/thegymatmoffat.co.uk/pt.mp4" />
      <div className="pt">
        <div className="pt__image">
          <div className="background-hero background-hero--alt">
            <div className="background-hero__wrapper">
              <div className="animate__animated animate__fadeInDown">
                <div className="background-hero__content gs-u-p++">
                  <h2>
                    Personal
                    <br />
                    Training
                  </h2>
                </div>
                <ul className="gel-layout gs-u-mt+">
                  <li className="gel-layout__item gs-u-display-inline-block gel-1/1">
                    <a
                      className="button button--full button--white button--large"
                      href="/personal-training/enquiry"
                    >
                      <i className="fa fa-info-circle gs-u-mr" />
                      Enquire now
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Section
          title="Robert Meiklem"
          imageUrl="/assets/img/pt.jpg"
          text={
            <Fragment>
              <p className="gel-pica gs-u-mb+">
                What I give, is all my experience to you - whether you're coming
                in as a new gym goer and need some detail on how to use
                something, or maybe your coming into a class and want put
                through a workout, or perhaps your after some 1 on 1 training to
                make sure whatever goal you have set yourself, you have someone
                to work with and push you to that goal.
              </p>
              <p className="gs-u-align-middle gs-u-mb+">
                <i className="fa fa-phone gs-u-mr" />
                <a href="tel:07752815816" className="footer__phone gs-u-pv">
                  <b>07752815816</b>
                </a>
              </p>
            </Fragment>
          }
          button={
            <a
              className="button button--dark button--large"
              href="/personal-training/enquiry"
            >
              <i className="fa fa-info-circle gs-u-mr" />
              Enquire now
            </a>
          }
        />
        <Section
          isReversed
          title="Jo Swan"
          imageUrl="/assets/img/jo-pt.jpg"
          text={
            <Fragment>
              <p className="gel-pica gs-u-mb+">
                I'm a qualified level 3 Personal Trainer. I am young, full of
                motivation and here to help you meet your goals. Even though I
                am young I've still got good experience behind me in aspects of
                fitness like injuries, mobility, sports specific training,
                weight training, cardio training plus much more.
              </p>
              <p className="gs-u-align-middle gs-u-mb+">
                <i className="fa fa-phone gs-u-mr" />
                <a href="tel:07493274954" className="footer__phone gs-u-pv">
                  <b>07493274954</b>
                </a>
              </p>
            </Fragment>
          }
          button={
            <a
              className="button button--dark button--large"
              href="/personal-training/enquiry"
            >
              <i className="fa fa-info-circle gs-u-mr" />
              Enquire now
            </a>
          }
        />
        <Section
          title="Liz Dingley"
          imageUrl="/assets/img/liz.jpeg"
          text={
            <Fragment>
              <p className="gel-pica gs-u-mb+">
                I'm a PT and strength coach. I have a personal empathy with
                beginners and nervous/new gym goers having been one myself. My
                passion is teaching people to get strong through correct
                technique, controlled movement and proper programming.
              </p>
              <p className="gel-pica gs-u-mb+">
                Strength and resistance training is hugely beneficial to
                everyone and I particularly enjoy coaching older people whose
                muscle mass can decline with age.
              </p>
              <p className="gs-u-align-middle gs-u-mb+">
                <i className="fa fa-phone gs-u-mr" />
                <a href="tel:07710527434" className="footer__phone gs-u-pv">
                  <b>07710527434</b>
                </a>
              </p>
            </Fragment>
          }
          button={
            <a
              className="button button--dark button--large"
              href="/personal-training/enquiry"
            >
              <i className="fa fa-info-circle gs-u-mr" />
              Enquire now
            </a>
          }
        />
      </div>
    </React.Fragment>
  );
};

export default PersonalTraining;
