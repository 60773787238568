import get from 'lodash/get';

export function getAsCalendarEvent(booking) {
  return {
    id: get(booking, '_id'),
    title: `${get(booking, 'class.label', '')} - ${get(
      booking,
      'firstName',
      ''
    )} ${get(booking, 'surName', '')}`,
    allDay: false,
    start: new Date(get(booking, 'bookingStartDate')),
    end: new Date(get(booking, 'bookingEndDate')),
  };
}

export function getAsCalendarEvents(bookings) {
  return bookings.map(booking => getAsCalendarEvent(booking));
}
