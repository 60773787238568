import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";

import MembershipDetails from "../components/MembershipDetails";
import { getMembershipLevelLabel } from "../utils/memberships";

const mapStateToProps = (state) => ({
  plans: get(state, "payment.plans", []),
  couplesMemberships: get(state, "payment.couplesMemberships", {}),
});

const renderPaymentPlan = ({ plan, membershipIds }) => {
  const { name, frequency, price, label, planId } = plan;

  return (
    <li className="gel-layout__item gel-1/2@l" key={plan.frequency}>
      <div className="payment-plan__wrapper gs-u-p++ gel-1/1">
        <div className="payment-plan__content gs-u-align-center">
          <h4 className="payment-plan__name gel-pica gs-u-mb++">{name}</h4>
          <hr className="gs-u-mv+" />
          {label && (
            <span className="payment-plan__label gel-brevier-bold gs-u-p gs-u-display-inline-block gs-u-mb+">
              {label}
            </span>
          )}
          <h4 className="payment-plan__price gel-canon">{price}</h4>
          <p className="payment-plan__frequency gel-brevier gs-u-mb++">
            {frequency}
          </p>
        </div>
        <div className="payment-plan__paypal">
          <a
            className="button button--full button--filled button--large button--gold"
            type="button"
            href={`/api/paypal/subscription-init?planId=${planId}&membershipId=${membershipIds.join(
              ","
            )}&planName=${name}`}
          >
            <img src="/assets/img/paypal.svg" alt="PayPal" />
          </a>
        </div>
        <div className="payment-plan__issues gs-u-mt gs-u-p+">
          <p>
            If you experience issues with your PayPal payment, please contact us
            and we can provide alternative methods while we try to fix your
            issue.
          </p>
        </div>
      </div>
    </li>
  );
};

/**
 * CouplesPaymentPlans component
 *
 * @param {object} props
 */
const CouplesPaymentPlans = ({ plans, couplesMemberships }) => {
  return (
    <React.Fragment>
      <h3 className="section__title gel-double-pica-bold gs-u-mb++">
        Your Memberships
      </h3>
      {couplesMemberships.map((membership, index) => {
        return (
          <div className="gs-u-mb++">
            <h3 className="gel-great-primer-bold gel-1/1">
              Member {index + 1} -{" "}
              {getMembershipLevelLabel(membership.membershipLevel)}
            </h3>
            <MembershipDetails membership={membership} />
          </div>
        );
      })}

      <h3 className="section__title gel-double-pica-bold gs-u-mb++ o-slice-spacing">
        Select payment plan
      </h3>
      <ul className="gel-layout gel-layout--equal">
        {plans.map((plan) =>
          renderPaymentPlan({
            plan,
            membershipIds: couplesMemberships.map(({ _id }) => _id),
          })
        )}
      </ul>
    </React.Fragment>
  );
};

CouplesPaymentPlans.propTypes = {
  plans: PropTypes.array.isRequired,
  couplesMemberships: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(CouplesPaymentPlans);
