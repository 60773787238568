import React, { Fragment } from "react";
import Banner from "../../components/Banner";
import CouplesMembershipForm from "../../containers/CouplesMembershipForm";

/**
 * CouplesMembershipsDetails component
 *
 * @param {object} props
 */
const CouplesMembershipsDetails = () => {
  return (
    <Fragment>
      <Banner
        title="Want to come join us?"
        imageUrl="/assets/img/classes.jpg"
      />
      <CouplesMembershipForm />
    </Fragment>
  );
};

export default CouplesMembershipsDetails;
