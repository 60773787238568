import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push as Menu } from 'react-burger-menu';
import get from 'lodash/get';
import { toggleSidebar } from '../store/actions/ui';
import { isGodOrAdmin, isUserLoggedIn } from '../utils/users';

const mapStateToProps = state => ({
  isSidebarOpen: state.ui.isSidebarOpen,
  user: get(state, 'user'),
});

const mapDispatchToProps = dispatch => ({
  setSidebarState: isSidebarOpen => dispatch(toggleSidebar(isSidebarOpen)),
});

const menuItems = [
  {
    url: '/',
    text: 'Home',
    icon: 'home',
  },
  {
    url: '/about-us',
    text: 'About us',
    icon: 'info-circle',
  },
  {
    url: '/memberships',
    text: 'Memberships',
    icon: 'user-plus',
  },
  {
    icon: 'credit-card',
    url: '/payg',
    text: 'Pay as you go',
  },
  {
    url: '/classes',
    text: 'Classes',
    icon: 'users',
  },
  {
    url: '/court-hire',
    text: 'Court Hire',
    icon: 'futbol-o',
  },
  {
    url: '/gymnastics',
    text: 'Gymnastics',
    icon: 'user',
  },
  {
    url: '/personal-training',
    text: 'Personal Training',
    icon: 'shield',
  },
];

/**
 * Sidebar component
 *
 * @param {object} props
 */
const Sidebar = ({ isSidebarOpen, setSidebarState, user }) => {
  useEffect(() => {
    if (isUserLoggedIn(user)) {
      menuItems.push({
        url: `/bookings/my`,
        text: 'My Bookings',
        icon: 'user',
      });
      menuItems.push({
        url: `/logout`,
        text: 'Sign out',
        icon: 'sign-out',
      });
      if (isGodOrAdmin(user)) {
        menuItems.push({
          url: `/admin`,
          text: 'Admin',
          icon: 'lock',
        });
      }
    } else {
      menuItems.push({
        url: '/login',
        text: 'Sign in',
        icon: 'sign-in',
      });
    }
  }, [user]);
  return (
    <Menu
      isOpen={isSidebarOpen}
      customBurgerIcon={false}
      right
      onClose={() => setSidebarState(false)}
      pageWrapId="page-wrap"
      outerContainerId="outer-container"
      className="sidebar"
      width={275}
    >
      <React.Fragment>
        <div className="gs-u-align-center">
          <img
            className="header__img gel-3/4 gs-u-display-inline-block"
            src="/assets/img/logo.svg"
            alt=""
          />
        </div>
        <ul className="sidebar__list">
          {menuItems.map(({ url, text, icon }) => (
            <li
              className="sidebar__list_item"
              key={`menu-item-${icon}-${text}`}
            >
              <a className="gs-u-p+ gs-u-display-block" href={url}>
                <i
                  className={`sidebar__list_item_icon fa fa-${icon} gs-u-mr`}
                />
                {text}
              </a>
            </li>
          ))}
        </ul>
      </React.Fragment>
    </Menu>
  );
};

Sidebar.propTypes = {
  isSidebarOpen: PropTypes.bool.isRequired,
  setSidebarState: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
