import React from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

const mapStateToProps = state => ({
  users: get(state, "users.list", [])
});

const renderUsers = users => {
  return (
    <ul className="bookings-list">
      {users.map(user => {
        if (user) {
          return (
            <li className="bookings-list__item gs-u-mb+" key={user._id}>
              <span className="gs-u-clearfix gs-u-display-block gs-u-p+">
                <b>
                  {user.firstName} {user.surName}
                </b>
                <span className="bookings-list__time gs-u-display-block gs-u-display-inline@m gel-brevier gs-u-ml@m gs-u-display-inline-block gs-u-float-right@m gs-u-mt gs-u-mt0@m">
                  <i className="fa fa-calendar gs-u-mr" />
                  {moment(user.created_date).format("ddd, MMM Do - H:mm a")}
                </span>
              </span>
            </li>
          );
        }

        return null;
      })}
    </ul>
  );
};

/**
 * UsersList component
 *
 * @param {object} props
 */
const UsersList = ({ users, title, isViewAllEnabled }) => {
  if (!users.length) {
    return (
      <div className="bookings">
        <h2 className="text-section__title gel-great-primer-bold gs-u-display-inline-block gs-u-mb++">
          {title}
        </h2>
        <p>
          Looks like there's currently no members. Check back later to see if
          any come through.
        </p>
      </div>
    );
  }

  return (
    <div className="bookings">
      <div>
        {title && (
          <h2 className="text-section__title gel-great-primer-bold gs-u-display-inline-block gs-u-mb++">
            {title}
          </h2>
        )}
        {isViewAllEnabled && (
          <a className="button gs-u-ml+ sc-o-link" href="/admin/users">
            View all <i className="fa fa-next" />
          </a>
        )}
      </div>
      {renderUsers(users)}
    </div>
  );
};

UsersList.defaultProps = {
  users: [],
  title: "Members",
  isViewAllEnabled: true
};

UsersList.propTypes = {
  users: PropTypes.array,
  title: PropTypes.string,
  isViewAllEnabled: PropTypes.bool
};

export default connect(mapStateToProps)(UsersList);
