/* eslint-disable prettier/prettier */
import React, { Fragment } from "react";
import BookingsHeader from "../../containers/BookingsHeader";
import BookingsForm from "../../containers/BookingsForm";
import BookingsModal from "../../containers/BookingsModal";

/**
 * Bookings component
 *
 * @param {object} props
 */
const Bookings = () => {
  return (
    <Fragment>
      <BookingsModal />
      <BookingsHeader />
      <BookingsForm />
    </Fragment>
  );
};

export default Bookings;
