import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import classNames from 'classnames';

const mapStateToProps = state => ({
  user: get(state, 'user'),
});

/**
 * BackgroundHero component
 *
 * @param {object} props
 */
const BackgroundHero = () => {
  const itemClassnames = classNames(
    'gel-layout__item gs-u-display-inline-block gel-1/2'
  );
  return (
    <div className="background-hero">
      <div className="background-hero__wrapper">
        <div className="animate__animated animate__fadeInDown">
          <div className="gel-layout gel-layout--equal">
            <div className="gel-layout__item flex-col gel-1/1">
              <div className="background-hero__content gs-u-p+ gs-u-p++@m">
                <h2>
                  Welcome To
                  <br />
                  The Gym at Moffat
                </h2>
              </div>
              <ul className="gel-layout gs-u-mt+">
                <li className={itemClassnames}>
                  <a
                    className="button button--full button--white button--large"
                    href="/classes"
                  >
                    <i className="fa fa-calendar gs-u-mr" />
                    Book now
                  </a>
                </li>
                <li className={itemClassnames}>
                  <a
                    className="button button--full button--white button--large"
                    href="/memberships"
                  >
                    <i className="fa fa-user-plus gs-u-mr" />
                    Join now
                  </a>
                </li>
                <li className="gel-layout__item gs-u-display-inline-block gel-1/1 gs-u-mt+">
                  <a
                    className="button button--full button--white button--large"
                    href="/payg"
                  >
                    <i className="fa fa-clock-o gs-u-mr" />
                    Book a PAYG Session
                  </a>
                </li>
                <li className="gel-layout__item gs-u-display-inline-block gel-1/1 gs-u-mt+ gs-u-align-center">
                  <a href="/classes" className="yellow" rel="noreferrer">
                    <i className="fa fa-info-circle gs-u-mr" />
                    New spin room! Book in now!
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(BackgroundHero);
