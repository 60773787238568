import React from "react";
import { connect } from "react-redux";

const mapStateToProps = state => ({});

/**
 * BackgroundVideo component
 *
 * @param {object} props
 */
const BackgroundVideo = () => {
  return (
    <div
      className="background-image"
      style={{ backgroundImage: `url("./assets/img/background.jpeg")` }}
    />
  );
};

export default connect(mapStateToProps)(BackgroundVideo);
