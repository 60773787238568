/* eslint-disable prettier/prettier */
import React, { Fragment, useState } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { COUPLES_PLANS } from "../../utils/consts";
import Modal from "../../containers/Modal";
import { toggleModal } from "../../store/actions/ui";

const getPlans = (frequency = "per 6 months") => {
  return [
    {
      ...COUPLES_PLANS["gym-only"].find((item) => item.frequency === frequency),
    },
    {
      ...COUPLES_PLANS["gym-and-classes"].find(
        (item) => item.frequency === frequency
      ),
    },
  ];
};

const CouplesSelection = ({
  selectedMemberships,
  setSelectedMemberships,
  selected,
  setSelected,
}) => {
  const dispatch = useDispatch();
  return (
    <div className="couples-selection gs-u-p+ gs-u-mb++">
      <h2 className="gs-u-mb++">Select a plan for each member</h2>
      <ul className="gel-layout">
        <li className="gel-layout__item gel-1/1 gel-1/2@l gs-u-align-center gs-u-mb+">
          <div className="couples-selection__choice">
            <button
              type="button"
              className={classNames("button button--large gel-pica-bold", {
                "button--success gel-10/12": selectedMemberships.one !== null,
                "gel-1/1": !selectedMemberships.one,
              })}
              onClick={() => {
                setSelected("one");
                dispatch(toggleModal(true));
              }}
            >
              {selectedMemberships.one ? (
                <span>{selectedMemberships.one.name}</span>
              ) : (
                <i>Choose a plan</i>
              )}
            </button>
            {selectedMemberships.one && (
              <button
                className="button button--large gel-2/12"
                type="button"
                onClick={() => {
                  setSelectedMemberships({
                    ...selectedMemberships,
                    one: null,
                  });
                }}
              >
                <i className="fa fa-close" />
              </button>
            )}
          </div>
          <p className="gs-u-mt gel-pica-bold">Member 1</p>
        </li>
        <li className="gel-layout__item gel-1/1 gel-1/2@l gs-u-align-center">
          <div className="couples-selection__choice">
            <button
              type="button"
              className={classNames(
                "button button--full button--large gel-pica-bold",
                {
                  "button--success gel-10/12": selectedMemberships.two !== null,
                  "gel-1/1": !selectedMemberships.two,
                }
              )}
              onClick={() => {
                setSelected("two");
                dispatch(toggleModal(true));
              }}
            >
              {selectedMemberships.two ? (
                <span>{selectedMemberships.two.name}</span>
              ) : (
                <i>Choose a plan</i>
              )}
            </button>
            {selectedMemberships.two && (
              <button
                className="button button--large gel-2/12"
                type="button"
                onClick={() => {
                  setSelectedMemberships({
                    ...selectedMemberships,
                    two: null,
                  });
                }}
              >
                <i className="fa fa-close" />
              </button>
            )}
          </div>
          <p className="gs-u-mt gel-pica-bold">Member 2</p>
        </li>
      </ul>
    </div>
  );
};

const defaultMembershipState = {
  one: null,
  two: null,
};

/**
 * CouplesMemberships component
 *
 * @param {object} props
 */
const CouplesMemberships = () => {
  const dispatch = useDispatch();
  const [activeFrequency, setActiveFrequency] = useState("per 6 months");
  const [activePlans, setActivePlans] = useState(getPlans());
  const [selectedMember, setSelectedMember] = useState("one");
  const [selectedMemberships, setSelectedMemberships] = useState(
    defaultMembershipState
  );

  return (
    <Fragment>
      <div className="memberships-choice gs-u-align-center">
        <div className="memberships-choice__content gs-u-ph+ gs-u-pv++ gs-u-p++@l">
          <h1 className="gel-trafalgar-bold gs-u-mb++">Couples Memberships</h1>
          <div className="couples-selection gs-u-p+ gs-u-mb++">
            <h2 className="gs-u-mb++">Choose payment frequency</h2>
            <ul className="gs-u-mb+ gs-u-ml+@l gs-u-display-inline-block@l">
              <li className="gs-u-display-inline-block@l">
                <button
                  type="button"
                  className={`memberships-choice__nav_button button button--large button--dark ${
                    activeFrequency === "per 6 months" ? "button--active" : ""
                  }`}
                  onClick={() => {
                    setSelectedMemberships(defaultMembershipState);
                    setActiveFrequency("per 6 months");
                    setActivePlans(getPlans("per 6 months"));
                  }}
                >
                  6 months
                </button>
              </li>
              <li className="gs-u-display-inline-block@l">
                <button
                  type="button"
                  className={`memberships-choice__nav_button button button--large button--dark ${
                    activeFrequency === "per year" ? "button--active" : ""
                  }`}
                  onClick={() => {
                    setSelectedMemberships(defaultMembershipState);
                    setActiveFrequency("per year");
                    setActivePlans(getPlans("per year"));
                  }}
                >
                  Annually
                </button>
              </li>
            </ul>
          </div>
          <CouplesSelection
            setSelected={setSelectedMember}
            selected={selectedMember}
            selectedMemberships={selectedMemberships}
            setSelectedMemberships={setSelectedMemberships}
          />
          <div className="gel-wrap gel-wrap--small">
            {selectedMemberships.one && selectedMemberships.two && (
              <a
                className="button button--full button--filled button--large"
                href={`/memberships/couples/${selectedMemberships.one.level}/${selectedMemberships.two.level}`}
              >
                Continue
              </a>
            )}
          </div>
          <Modal classes="modal--large gs-u-align-left">
            <h2 className="couples-selection__modal_title gel-double-pica-bold gs-u-display-inline-block gs-u-mb++">
              P L A N S
            </h2>
            <div className="gel-1/1 memberships-choice-wrapper">
              <ul className="gel-1/1">
                {activePlans.map((plan) => (
                  <li
                    key={`membership-${plan.elementId}`}
                    className="memberships-choice__list_item gs-u-mb+"
                  >
                    <div className="memberships-choice__list_item_inner gs-u-align-center gs-u-p++ gel-1/1">
                      <div>
                        <h4 className="payment-plan__name gel-pica gs-u-mb++">
                          {plan.planLabel}
                        </h4>
                        <hr className="gs-u-mv+" />
                        <h4 className="payment-plan__price gel-canon">
                          {plan.price}
                        </h4>
                        <p className="payment-plan__frequency gel-brevier gs-u-mb++">
                          {plan.frequency}
                        </p>
                        <p className="payment-plan__frequency gel-pica gs-u-mb++">
                          <i className="fa fa-id-badge gs-u-mr" />
                          {plan.ageRange}
                        </p>
                        <div className="gs-u-mb++">{plan.descriptionNode}</div>
                      </div>
                      <button
                        type="button"
                        className="button button--large button--full"
                        onClick={() => {
                          dispatch(toggleModal(false));
                          setSelectedMemberships({
                            ...selectedMemberships,
                            [selectedMember]: plan,
                          });
                        }}
                      >
                        Select this membership
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </Modal>
        </div>
      </div>
    </Fragment>
  );
};

export default CouplesMemberships;
