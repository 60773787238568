/* eslint-disable prettier/prettier */
import React, { Fragment, useState } from "react";
import { PLANS } from "../../utils/consts";

const getPlans = (type = "adult", frequency = "per month") => {
  return [
    {
      ...PLANS[type]["gym-only"].find((item) => item.frequency === frequency),
    },
    {
      ...PLANS[type]["gym-and-classes"].find(
        (item) => item.frequency === frequency
      ),
    },
  ];
};

/**
 * MembershipsChoices component
 *
 * @param {object} props
 */
const MembershipsChoices = () => {
  const [activeFrequency, setActiveFrequency] = useState("per month");
  const [activeType, setActiveType] = useState("adult");
  const [activePlans, setActivePlans] = useState(getPlans());

  return (
    <Fragment>
      <div className="memberships-choice gs-u-align-center">
        <div className="memberships-choice__content gs-u-ph+ gs-u-pv++ gs-u-p++@l">
          <h1 className="gel-trafalgar-bold gs-u-mb++">Membership Packages</h1>
          <p className="couples-membership-banner gs-u-mb++">
            <b>New</b> Couples memberships are now available!{" "}
            <a className="link" href="/memberships/couples">
              <b>Join now</b>
            </a>
          </p>
          <div className="gel-1/1 gs-u-display-inline-block gs-u-align-center gs-u-pr+@l">
            <p className="gs-u-display-none gs-u-display-inline-block@l gel-pica-bold gs-u-mr+">
              Type
            </p>
            <ul className="gs-u-mb+ gs-u-display-inline-block@l">
              <li className="gs-u-display-inline-block@l">
                <button
                  type="button"
                  className={`memberships-choice__nav_button button button--large button--dark ${
                    activeType === "adult" ? "button--active" : ""
                  }`}
                  onClick={() => {
                    setActiveType("adult");
                    setActivePlans(getPlans("adult", activeFrequency));
                  }}
                >
                  Adult
                </button>
              </li>
              {activeFrequency !== "per 6 months" && (
                <li className="gs-u-display-inline-block@l">
                  <button
                    type="button"
                    className={`memberships-choice__nav_button button button--large button--dark ${
                      activeType === "child" ? "button--active" : ""
                    }`}
                    onClick={() => {
                      setActiveType("child");
                      setActivePlans(getPlans("child", activeFrequency));
                    }}
                  >
                    Child
                  </button>
                </li>
              )}
            </ul>
            <p className="gs-u-display-none gs-u-display-inline-block@l gel-pica-bold gs-u-ml++">
              Frequency
            </p>
            <ul className="gs-u-mb+ gs-u-ml+@l gs-u-display-inline-block@l">
              <li className="gs-u-display-inline-block@l">
                <button
                  type="button"
                  className={`memberships-choice__nav_button button button--large button--dark ${
                    activeFrequency === "per month" ? "button--active" : ""
                  }`}
                  onClick={() => {
                    setActiveFrequency("per month");
                    setActivePlans(getPlans(activeType, "per month"));
                  }}
                >
                  Monthly
                </button>
              </li>
              {activeType !== "child" && (
                <li className="gs-u-display-inline-block@l">
                  <button
                    type="button"
                    className={`memberships-choice__nav_button button button--large button--dark ${
                      activeFrequency === "per 6 months" ? "button--active" : ""
                    }`}
                    onClick={() => {
                      setActiveFrequency("per 6 months");
                      setActivePlans(getPlans(activeType, "per 6 months"));
                    }}
                  >
                    6 Months
                  </button>
                </li>
              )}
              <li className="gs-u-display-inline-block@l">
                <button
                  type="button"
                  className={`memberships-choice__nav_button button button--large button--dark ${
                    activeFrequency === "per year" ? "button--active" : ""
                  }`}
                  onClick={() => {
                    setActiveFrequency("per year");
                    setActivePlans(getPlans(activeType, "per year"));
                  }}
                >
                  Annually
                </button>
              </li>
            </ul>
          </div>
          <div className="gel-1/1 gel-wrap gs-u-mv">
            <i>
              <b>*No joining fee and no contract</b>
            </i>
          </div>
          <div className="gel-1/1 memberships-choice-wrapper gel-wrap">
            <ul className="memberships-choice__list gel-wrap gel-layout gel-layout--equal">
              {activePlans.map((plan) => (
                <li
                  key={`membership-${plan.elementId}`}
                  className="memberships-choice__list_item gel-layout__item gs-u-mb+ gs-u-mb0@l gs-u-p+@l gel-1/2@l gel-1/3@xl"
                >
                  <div className="memberships-choice__list_item_inner gs-u-align-center gs-u-p++ gel-1/1">
                    <div>
                      <h4 className="payment-plan__name gel-pica gs-u-mb++">
                        {plan.planLabel}
                      </h4>
                      <hr className="gs-u-mv+" />
                      <h4 className="payment-plan__price gel-canon">
                        {plan.price}
                      </h4>
                      <p className="payment-plan__frequency gel-brevier gs-u-mb++">
                        {plan.frequency}
                      </p>
                      <p className="payment-plan__frequency gel-pica gs-u-mb++">
                        <i className="fa fa-id-badge gs-u-mr" />
                        {plan.ageRange}
                      </p>
                      <div className="gs-u-mb++">{plan.descriptionNode}</div>
                    </div>
                    <a
                      className="button button--large button--full"
                      href={`/memberships/${activeType}/${plan.level}`}
                    >
                      Select this membership
                    </a>
                  </div>
                </li>
              ))}
              <li className="memberships-choice__list_item payg-choice gel-layout__item gs-u-mb+ gs-u-mb0@l gs-u-p+@l gel-1/2@l gel-1/3@xl">
                <div className="memberships-choice__list_item_inner gs-u-align-center gs-u-p++ gel-1/1">
                  <div>
                    <h4 className="payment-plan__name gel-pica gs-u-mb++">
                      Pay as you go
                    </h4>
                    <hr className="gs-u-mv+ gs-u-mb++" />
                    <div className="gs-u-mb++">
                      <div className="plan-description gel-long-primer">
                        <i className="fa fa-calendar gs-u-mr" />{" "}
                        <b>PAYG access to the gym</b>:
                        <p className="gs-u-mb">24 hour access</p>
                        <span className="gs-u-display-block gs-u-mb">
                          <i className="fa fa-users gs-u-mr" />
                          <b>PAYG classes</b> - subject to booking and
                          availability
                        </span>
                        {activeType === "child" && (
                          <span className="gs-u-display-block gs-u-mb">
                            <i className="fa fa-user gs-u-mr" />
                            <b>Wednesday Teen Sessions</b> <br /> 4pm - 5pm with
                            staff on hand to provide supervision and guidance
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <a
                    className="button button--large button--full"
                    href="/register/non-member"
                  >
                    Create PAYG account
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MembershipsChoices;
