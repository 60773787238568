import React, { Fragment, useState } from 'react';
import axios from 'axios';

import { useSelector } from 'react-redux';
import TextSection from '../../components/TextSection';
import { isGymMember } from '../../utils/bookings';
import { isGodOrAdmin } from '../../utils/users';
import { WeeklyPAYGPass } from '../../containers/WeeklyPAYGPass';

function initPayPalButton({ setPayment, user, sessionsPaymentValue }) {
  window.paypal
    .Buttons({
      style: {
        shape: 'pill',
        color: 'blue',
        layout: 'vertical',
        label: 'paypal',
      },
      createOrder: (data, actions) =>
        actions.order.create({
          purchase_units: [
            {
              description: 'The Gym at Moffat Gym Slot',
              amount: { currency_code: 'GBP', value: sessionsPaymentValue },
            },
          ],
        }),
      onApprove: (_, actions) =>
        actions.order.capture().then(async details => {
          setPayment(details);
          const booking = {
            userId: user._id,
            email: user.email,
            firstName: user.firstName,
            surName: user.surName,
            username: user.username,
            membershipType: user.membershipType,
            membershipLevel: user.membershipLevel,
            phone: user.phone,
            class: {
              id: 'gym-slot',
              timeLabel: '',
              label: 'PAYG Gym Access',
              isBookable: false,
              isPrerecorded: false,
            },
            payment: details,
          };

          const { data } = await axios
            .post('/api/bookings/gym', booking)
            .catch(err => {
              const { data: errData } = err.response;
              if (errData.message) {
                alert(errData.message);
              } else {
                alert(
                  'Something went wrong there. Please try again and if the problem persists, please let us know! Thanks for your patience.'
                );
              }
            });

          if (data && data.success) {
            window.location.href =
              '/payg?m=Your gym slot is now paid for. You should receive a confirmation email with the door code';
          }
        }),
      onError: err => {
        console.log(err);
      },
    })
    .render('#paypal-button-container');
}

const PRICE_PER_SESSION = 6;

/**
 * PAYG component
 *
 * @param {object} props
 */
const PAYG = () => {
  const user = useSelector(state => state.user);
  const [payment, setPayment] = useState(null);
  const [showBookButton, setShowBookButton] = useState(true);
  const [paypalLoaded, setPaypalLoaded] = useState(false);
  const [sessionsValue, setSessionsValue] = useState(1);
  const [sessionsPaymentValue, setSessionsPaymentValue] = useState(
    PRICE_PER_SESSION
  );

  const setupPayPalButton = () => {
    if (user) {
      const buttonContainer = document.getElementById(
        'paypal-button-container'
      );

      if (buttonContainer && !paypalLoaded) {
        initPayPalButton({ setPayment, user, sessionsPaymentValue });
        setPaypalLoaded(true);
      }
    }
  };

  const resetBooking = () => {
    const paypalNode = document.getElementById('paypal-button-container');
    setShowBookButton(true);
    setPaypalLoaded(false);
    paypalNode.innerHTML = '';
  };

  const handleSessionsChange = e => {
    if (!showBookButton) {
      resetBooking();
    }
    setSessionsValue(e.target.value);
    setSessionsPaymentValue(parseInt(e.target.value, 10) * PRICE_PER_SESSION);
  };

  const handleBookingSelect = () => {
    setShowBookButton(false);
    setupPayPalButton();
  };
  return (
    <Fragment>
      <TextSection
        title="Pay as you go"
        textHtml={
          <>
            <div className="gel-pica">
              <p className="gs-u-mb">
                Below, you can purchase your gym access sessions or grab a 1
                week pass (perfect for when you're just visiting Moffat).
              </p>
              <p>
                Once your payment has been taken, there's no need to book; we'll
                send out the door code in your confirmation email, and you can
                get access to our facilities right away! We're open 7 days a
                week, 24 hours a day.
              </p>
            </div>

            {user && (!isGymMember(user) || isGodOrAdmin(user)) && (
              <ul className="memberships-choice__list gel-wrap gel-layout gel-layout--equal o-slice-spacing">
                <li className="memberships-choice__list_item gel-layout__item gs-u-mb+ gs-u-mb0@l gs-u-p+@l gel-1/2@l">
                  <div className="memberships-choice__list_item_inner drop-shadow gs-u-align-center gs-u-p++ gel-1/1">
                    <div>
                      <h4 className="payment-plan__name gel-pica gs-u-mb++">
                        Single Sessions
                      </h4>
                      <hr className="gs-u-mv+ gs-u-mb++" />
                      <h4 className="payment-plan__price gel-canon">£6</h4>
                      <p className="payment-plan__frequency gel-brevier gs-u-mb++">
                        per session
                      </p>
                      <div>
                        <div className="plan-description gel-long-primer">
                          <i className="fa fa-calendar gs-u-mr" />{' '}
                          <b>PAYG access to the gym</b>:
                          <p className="gs-u-mb">
                            {sessionsValue} session(s) of gym access
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="o-slice-spacing">
                      <div className="range-slider gs-u-mb++">
                        <input
                          className="range-slider__range"
                          type="range"
                          value={sessionsValue}
                          min="1"
                          max="14"
                          step="1"
                          onChange={handleSessionsChange}
                        />
                        <span className="range-slider__value">
                          {sessionsValue}
                        </span>
                      </div>
                      {showBookButton && (
                        <div className="">
                          <button
                            className="button gs-u-p+"
                            type="button"
                            onClick={handleBookingSelect}
                          >
                            Book {sessionsValue} session(s)
                          </button>
                        </div>
                      )}

                      {payment && (
                        <div className="classes__wrapper gel-wrap gs-u-mb++">
                          <div className="gs-u-align-center">
                            <p>Please wait while we process your payment...</p>
                          </div>
                        </div>
                      )}

                      {!payment && (
                        <div className="classes__wrapper gel-wrap gs-u-mb++">
                          <div
                            id="smart-button-container"
                            className="buttons__wrap"
                          >
                            <div>
                              <div id="paypal-button-container" />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </li>
                <li className="memberships-choice__list_item gel-layout__item gs-u-mb+ gs-u-mb0@l gs-u-p+@l gel-1/2@l">
                  <div className="memberships-choice__list_item_inner drop-shadow gs-u-align-center gs-u-p++ gel-1/1">
                    <div>
                      <h4 className="payment-plan__name gel-pica gs-u-mb++">
                        1 Week Pass
                      </h4>
                      <hr className="gs-u-mv+ gs-u-mb++" />
                      <h4 className="payment-plan__price gel-canon">£10</h4>
                      <p className="payment-plan__frequency gel-brevier gs-u-mb++">
                        per pass
                      </p>
                      <div>
                        <div className="plan-description gel-long-primer">
                          <i className="fa fa-calendar gs-u-mr" />{' '}
                          <b>PAYG access to the gym</b>:
                          <p className="gs-u-mb">24 hour access for 1 week</p>
                        </div>
                      </div>
                    </div>
                    <WeeklyPAYGPass />
                  </div>
                </li>
              </ul>
            )}

            {!user && (
              <a
                className="button button--full gs-u-p+ gs-u-mt++"
                href="/login?ptrt=/payg"
              >
                Sign in to book time slot(s)
              </a>
            )}
          </>
        }
      />
    </Fragment>
  );
};

export default PAYG;
