/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import PropTypes from "prop-types";

/**
 * MemberDetailsFormFields component
 *
 * @param {object} props
 */
const MemberDetailsFormFields = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  isMembershipForm = true,
}) => {
  return (
    <React.Fragment>
      <h3 className="gel-great-primer-bold gel-1/1 gel-layout__item gs-u-mb++">
        Member Details
      </h3>
      <fieldset className="gel-layout__item gs-u-mb++ gel-1/2 gel-2/12@l">
        <label htmlFor="title" className="gs-u-display-block">
          Title *
        </label>
        <input
          id="title"
          type="text"
          value={values.title}
          onChange={handleChange}
          onBlur={handleBlur}
          className={
            errors.title && touched.title ? "text-input error" : "text-input"
          }
        />
        {errors.title && touched.title && (
          <div className="input-error">{errors.title}</div>
        )}
      </fieldset>
      <fieldset className="gel-layout__item gs-u-mb++ gel-1/2 gel-5/12@l">
        <label htmlFor="firstName" className="gs-u-display-block">
          First name *
        </label>
        <input
          id="firstName"
          type="text"
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          className={
            errors.firstName && touched.firstName
              ? "text-input error"
              : "text-input"
          }
        />
        {errors.firstName && touched.firstName && (
          <div className="input-error">{errors.firstName}</div>
        )}
      </fieldset>
      <fieldset className="gel-layout__item gs-u-mb++ gel-1/2 gel-5/12@l">
        <label htmlFor="surName" className="gs-u-display-block">
          Surname *
        </label>
        <input
          id="surName"
          type="text"
          value={values.surName}
          onChange={handleChange}
          onBlur={handleBlur}
          className={
            errors.surName && touched.surName
              ? "text-input error"
              : "text-input"
          }
        />
        {errors.surName && touched.surName && (
          <div className="input-error">{errors.surName}</div>
        )}
      </fieldset>
      <fieldset className="gel-layout__item gs-u-mb++ gel-6/12">
        <label htmlFor="dateOfBirth" className="gs-u-display-block">
          Date of birth *
        </label>
        <input
          id="dateOfBirth"
          placeholder="DD/MM/YYYY"
          type="text"
          value={values.dateOfBirth}
          onChange={handleChange}
          onBlur={handleBlur}
          className={
            errors.dateOfBirth && touched.dateOfBirth
              ? "text-input error"
              : "text-input"
          }
        />
        {errors.dateOfBirth && touched.dateOfBirth && (
          <div className="input-error">{errors.dateOfBirth}</div>
        )}
      </fieldset>
      <fieldset className="gel-layout__item gs-u-mb++ gel-6/12">
        <label htmlFor="phone" className="gs-u-display-block">
          Phone number *
        </label>
        <input
          id="phone"
          type="tel"
          value={values.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          className={
            errors.phone && touched.phone ? "text-input error" : "text-input"
          }
        />
        {errors.phone && touched.phone && (
          <div className="input-error">{errors.phone}</div>
        )}
      </fieldset>
      <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
        <label htmlFor="address" className="gs-u-display-block">
          Address *
        </label>
        <input
          id="address"
          type="text"
          value={values.address}
          onChange={handleChange}
          onBlur={handleBlur}
          className={
            errors.address && touched.address
              ? "text-input error"
              : "text-input"
          }
        />
        {errors.address && touched.address && (
          <div className="input-error">{errors.address}</div>
        )}
      </fieldset>
      <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
        <label htmlFor="email" className="gs-u-display-block">
          Email *
        </label>
        <input
          id="email"
          placeholder="Enter your email"
          type="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          className={
            errors.email && touched.email ? "text-input error" : "text-input"
          }
        />
        {errors.email && touched.email && (
          <div className="input-error">{errors.email}</div>
        )}
        <div className="input-feedback gs-u-mt">
          <i>
            * Our preferred method of contact. An email address will allow you
            to use our online booking system.
          </i>
        </div>
      </fieldset>

      <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
        <p className="gs-u-mb">
          Data Protection: Information collected on this form will be treated as
          confidential and held securely by The Gym. We will use this
          information to administer and process your membership. Your details
          will not be passed on to a third party and will be destroyed when the
          membership is cancelled. We may also use this information to update
          you about our products and services.
        </p>
        <label htmlFor="marketingOptOut">
          If you would like to opt out of receiving marketing/promotional
          messages, including The Gym at Moffat, please tick here
          <input
            className="gs-u-ml"
            type="checkbox"
            id="marketingOptOut"
            checked={values.marketingOptOut}
            onChange={handleChange}
          />
        </label>
      </fieldset>

      {isMembershipForm && (
        <React.Fragment>
          <h3 className="gel-great-primer-bold gel-1/1 gel-layout__item gs-u-mb++">
            Membership Type
          </h3>

          <fieldset className="gel-layout__item gs-u-mb gel-1/1">
            <label
              className="gs-u-display-inline-block gs-u-mr+"
              htmlFor="adult"
            >
              <input
                type="radio"
                className="gs-u-mr"
                id="adult"
                name="membershipType"
                value="adult"
                checked={values.membershipType === "adult"}
                onChange={handleChange}
              />
              Adult
            </label>
            <label className="gs-u-display-inline-block" htmlFor="child">
              <input
                type="radio"
                className="gs-u-mr"
                id="child"
                name="membershipType"
                checked={values.membershipType === "child"}
                onChange={handleChange}
                value="child"
              />
              Child
              <span className="label-info gs-u-ml">(12-16 years old)</span>
            </label>
          </fieldset>

          {values.membershipType === "child" && (
            <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
              <p className="input-error">
                Note! All teen members must have an adult supervising at all
                times and are not allowed to use the free weights.
              </p>
            </fieldset>
          )}

          <h3 className="gel-great-primer-bold gel-1/1 gel-layout__item gs-u-mb++">
            Membership Level
          </h3>

          <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
            <label
              className="gs-u-display-inline-block gel-1/3@l"
              htmlFor="gym-only"
              style={{ verticalAlign: "top", paddingRight: "8px" }}
            >
              <input
                type="radio"
                className="gs-u-mr"
                id="gym-only"
                name="membershipLevel"
                value="gym-only"
                checked={values.membershipLevel === "gym-only"}
                onChange={handleChange}
              />
              Gym Only
              <span className="label-info gs-u-ml">
                Adult: £30 p/m, £150 p/6m or £300 p/a. Child: £15 p/m or £150
                p/a.
              </span>
            </label>
            <label
              className="gs-u-display-inline-block gel-1/3@l"
              htmlFor="gym-and-classes"
              style={{ verticalAlign: "top", paddingRight: "8px" }}
            >
              <input
                type="radio"
                className="gs-u-mr"
                id="gym-and-classes"
                name="membershipLevel"
                value="gym-and-classes"
                checked={values.membershipLevel === "gym-and-classes"}
                onChange={handleChange}
              />
              Gym & Classes
              <span className="label-info gs-u-ml">
                Adult: £40 p/m, £200 p/6m or £400 p/a. Child: £20 p/m or £200
                p/a.
              </span>
            </label>
          </fieldset>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

MemberDetailsFormFields.defaultProps = {
  isMembershipForm: false,
};

MemberDetailsFormFields.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  isMembershipForm: PropTypes.bool,
};

export default MemberDetailsFormFields;
