import React, { useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";
import classNames from "classnames";
import { toggleModal } from "../store/actions/ui";

const mapStateToProps = (state) => ({
  isModalOpen: get(state, "ui.isModalOpen", false),
});

const mapDispatchToProps = (dispatch) => ({
  setModalState: (isModalOpen) => dispatch(toggleModal(isModalOpen)),
});

/**
 * Modal component
 *
 * @param {object} props
 */
const Modal = ({ isModalOpen, setModalState, onClose, children, classes }) => {
  const modalClasses = classNames("modal", classes);
  const modalContentRef = useRef(null);
  const modalRef = useRef(null);
  const closeModal = () => {
    setModalState(false);
    document.querySelector("body").classList.remove("noscroll");
    if (typeof onClose === "function") {
      onClose();
    }
  };
  const escFunction = useCallback((e) => {
    if (e.keyCode === 27) {
      closeModal();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      document.querySelector("body").classList.add("noscroll");
    } else {
      document.querySelector("body").classList.remove("noscroll");
    }
  }, [isModalOpen]);

  if (!isModalOpen) {
    return null;
  }

  return (
    <div className={modalClasses} ref={modalRef}>
      <button
        type="button"
        className="modal__button button button--white button--fixed"
        onClick={() => closeModal()}
      >
        <i className="fa fa-times" />
      </button>
      <div className="modal__content gs-u-p++" ref={modalContentRef}>
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setModalState: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
