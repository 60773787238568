import React, { Fragment } from "react";
import TextSection from "../../components/TextSection";
import MembersTable from "../../containers/MembersTable";

/**
 * Admin component
 *
 * @param {object} props
 */
const Admin = () => {
  return (
    <Fragment>
      <TextSection
        title="Members"
        textHtml={
          <>
            <p className="gs-u-mb++">
              Below is all the gym users who have signed up as members.
            </p>
            <p className="gs-u-mb++">
              Remember, <b>before removing users</b>, make sure to click through
              to their PayPal subscription (via the PayPal link in the table)
              and make sure their subscription isn't active. They might have
              already done this from their side, if not - you can cancel their
              subscription from there.
            </p>
          </>
        }
      />
      <div className="gel-wrap gel-wrap--large o-slice-spacing-bottom">
        <div className="export gel-wrap gel-wrap--large gs-u-mb+ gs-u-align-right">
          <a
            className="button button--large gs-u-mr+"
            href="/admin/export?type=child"
          >
            <i className="fa fa-download gs-u-mr" />
            Export teen memberships
          </a>
          <a className="button button--large" href="/admin/export?type=adult">
            <i className="fa fa-download gs-u-mr" />
            Export adult memberships
          </a>
        </div>
        <MembersTable />
      </div>
    </Fragment>
  );
};

export default Admin;
