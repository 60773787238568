import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";

const mapStateToProps = state => ({
});

/**
 * MyAccount container
 *
 * @param {object} props
 */
const MyAccount = () => {
  return (
    <section className="">
      <div className="gel-wrap">
        <h1>My account</h1>
      </div>
    </section>
  );
};

MyAccount.defaultProps = {

};

MyAccount.propTypes = {

};

export default connect(mapStateToProps)(MyAccount);
