import React from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import {
  getMembershipTypeLabel,
  getMembershipLevelLabel,
} from "../utils/memberships";

const mapStateToProps = (state) => ({
  members: get(state, "members.list", []),
});

const renderMembers = (members, hasFullDetails) => {
  return (
    <ul className="bookings-list">
      {members.map((member) => {
        if (member) {
          return (
            <li className="bookings-list__item gs-u-mb+" key={member._id}>
              <span className="gs-u-clearfix gs-u-display-block gs-u-p+">
                <b>
                  {member.firstName} {member.surName}
                </b>
                <span className="gs-u-ml+ gs-u-display-inline-block gel-brevier">
                  {getMembershipTypeLabel(member.membershipType)} -{" "}
                  {getMembershipLevelLabel(member.membershipLevel)}
                </span>
                {!member.subscriptionId && (
                  <span title="This member does not have an active subscription">
                    <i className="fa fa-exclamation-circle gs-u-ml delete" />
                  </span>
                )}
                <span className="bookings-list__time gs-u-display-block gs-u-display-inline@m gel-brevier gs-u-ml@m gs-u-display-inline-block gs-u-float-right@m gs-u-mt gs-u-mt0@m">
                  <i className="fa fa-calendar gs-u-mr" />
                  {moment(member.created_date).format("ddd, MMM Do - H:mm a")}
                </span>
              </span>
              {hasFullDetails && (
                <span className="gs-u-clearfix gs-u-display-block gs-u-p+">
                  <span className="gs-u-display-block gs-u-mr+ gs-u-mb+">
                    <b>Membership ID:</b> {`${member._id}`}
                  </span>
                  <span className="gs-u-display-block gs-u-mr+ gs-u-mb+">
                    <b>Subscription ID:</b> {member.subscriptionId || "Unknown"}
                  </span>
                  <span className="gs-u-display-block gs-u-mr+ error">
                    <a
                      href={`/api/members/${member._id}/unsubscribe`}
                      title="Delete this members subscription from our system"
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          window.confirm(
                            "Are you sure you want to delete this subscription?"
                          )
                        ) {
                          window.location.href = `/api/memberships/${member._id}/unsubscribe?ptrt=${window.location.pathname}`;
                        }
                      }}
                    >
                      <b className="delete">
                        <i className="fa fa-trash" /> Delete payment
                        subscription from our system
                      </b>
                    </a>
                  </span>
                </span>
              )}
            </li>
          );
        }

        return null;
      })}
    </ul>
  );
};

/**
 * MembersList component
 *
 * @param {object} props
 */
const MembersList = ({ members, title, isViewAllEnabled, hasFullDetails }) => {
  if (!members.length) {
    return (
      <div className="bookings">
        <h2 className="text-section__title gel-great-primer-bold gs-u-display-inline-block gs-u-mb++">
          {title}
        </h2>
        <p>
          Looks like there's currently no members. Check back later to see if
          any come through.
        </p>
      </div>
    );
  }

  return (
    <div className="bookings">
      <div>
        {title && (
          <h2 className="text-section__title gel-great-primer-bold gs-u-display-inline-block gs-u-mb++">
            {title}
          </h2>
        )}
        {isViewAllEnabled && (
          <a className="button gs-u-ml+ sc-o-link" href="/admin/members">
            View all <i className="fa fa-next" />
          </a>
        )}
      </div>
      {renderMembers(members, hasFullDetails)}
    </div>
  );
};

MembersList.defaultProps = {
  members: [],
  title: "Members",
  isViewAllEnabled: true,
  hasFullDetails: false,
};

MembersList.propTypes = {
  members: PropTypes.array,
  title: PropTypes.string,
  isViewAllEnabled: PropTypes.bool,
  hasFullDetails: PropTypes.bool,
};

export default connect(mapStateToProps)(MembersList);
