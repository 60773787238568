import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

function initPayPalButton({ setPayment, user }) {
  window.paypal
    .Buttons({
      style: {
        shape: 'pill',
        color: 'blue',
        layout: 'vertical',
        label: 'paypal',
      },
      createOrder: (data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              description: 'The Gym at Moffat Week Gym Pass',
              amount: { currency_code: 'GBP', value: 10 },
            },
          ],
        });
      },
      onApprove: (_, actions) => {
        return actions.order.capture().then(async details => {
          setPayment(details);
          const booking = {
            userId: user._id,
            email: user.email,
            firstName: user.firstName,
            surName: user.surName,
            username: user.username,
            membershipType: user.membershipType,
            membershipLevel: user.membershipLevel,
            phone: user.phone,
            class: {
              id: 'gym-slot-week',
              timeLabel: '',
              label: 'PAYG Week Gym Pass',
              isBookable: false,
              isPrerecorded: false,
            },
            payment: details,
          };

          const { data } = await axios
            .post(`/api/bookings/gym`, booking)
            .catch(err => {
              const { data: errData } = err.response;
              if (errData.message) {
                alert(errData.message);
              } else {
                alert(
                  'Something went wrong there. Please try again and if the problem persists, please let us know! Thanks for your patience.'
                );
              }
            });

          if (data && data.success) {
            window.location.href =
              '/payg?m=Your 1 week gym pass is now paid for. You should receive a confirmation email with the door code';
          }
        });
      },
      onError: err => {
        console.log(err);
      },
    })
    .render('#payg-week-payment');
}

export const WeeklyPAYGPass = () => {
  const [payment, setPayment] = useState();
  const user = useSelector(state => state.user);

  useEffect(() => {
    initPayPalButton({ setPayment, user });
  }, []);

  return (
    <div className="gs-u-align-center o-slice-spacing">
      {!payment && (
        <div className="classes__wrapper gel-wrap gs-u-mb++">
          <div className="buttons__wrap">
            <div>
              <div id="payg-week-payment" />
            </div>
          </div>
        </div>
      )}

      {payment && (
        <div className="classes__wrapper gel-wrap gs-u-mb++">
          <div className="gs-u-align-center">
            <p>Please wait while we process your payment...</p>
          </div>
        </div>
      )}
    </div>
  );
};
