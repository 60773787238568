/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import PropTypes from "prop-types";

/**
 * MemberLoginFormFields component
 *
 * @param {object} props
 */
const MemberLoginFormFields = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  inputPrefix,
}) => {
  return (
    <React.Fragment>
      <h3 className="gel-great-primer-bold gel-1/1 gel-layout__item gs-u-mb++">
        Account Credentials
      </h3>
      <fieldset className="gel-layout__item gs-u-mb gel-1/2">
        <label
          htmlFor={`${inputPrefix}username`}
          className="gs-u-display-block"
        >
          Username *
        </label>
        <input
          id={`${inputPrefix}username`}
          type="text"
          value={values[`${inputPrefix}username`]}
          onChange={handleChange}
          onBlur={handleBlur}
          className={
            errors[`${inputPrefix}username`] &&
            touched[`${inputPrefix}username`]
              ? "text-input error"
              : "text-input"
          }
        />
        {errors[`${inputPrefix}username`] &&
          touched[`${inputPrefix}username`] && (
            <div className="input-error">
              {errors[`${inputPrefix}username`]}
            </div>
          )}
      </fieldset>
      <fieldset className="gel-layout__item gs-u-mb gel-1/2">
        <label
          htmlFor={`${inputPrefix}password`}
          className="gs-u-display-block"
        >
          Password *
        </label>
        <input
          id={`${inputPrefix}password`}
          type="password"
          value={values[`${inputPrefix}password`]}
          onChange={handleChange}
          onBlur={handleBlur}
          className={
            errors[`${inputPrefix}password`] &&
            touched[`${inputPrefix}password`]
              ? "text-input error"
              : "text-input"
          }
        />
        {errors[`${inputPrefix}password`] &&
          touched[`${inputPrefix}password`] && (
            <div className="input-error">
              {errors[`${inputPrefix}password`]}
            </div>
          )}
      </fieldset>
      <div className="input-feedback gs-u-mb++ gel-layout__item">
        <i>So you can log into our system to start booking classes.</i>
      </div>
    </React.Fragment>
  );
};

MemberLoginFormFields.defaultProps = {
  inputPrefix: "",
};

MemberLoginFormFields.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  inputPrefix: PropTypes.string,
};

export default MemberLoginFormFields;
