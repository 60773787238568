import React, { Fragment } from 'react';
import BackgroundVideo from '../../containers/BackgroundVideo';
import BackgroundHero from '../../containers/BackgroundHero';
import Section from '../../components/Section';

/**
 * Home component
 *
 * @param {object} props
 */
const Home = () => {
  return (
    <Fragment>
      <BackgroundVideo videoUrl="https://s3-eu-west-1.amazonaws.com/thegymatmoffat.co.uk/gymatmoffat-vid.mp4" />
      <BackgroundHero />
      <Section
        title="The Gym at Moffat Experience"
        imageUrl="/assets/img/gym-light.jpeg"
        text={
          <Fragment>
            <p className="gel-pica gs-u-mb+">
              The Gym at Moffat offers a spacious fitness playground packed with
              top notch kit and the latest fitness classes.
            </p>
            <p className="gel-pica gs-u-mb++">
              Our expert trainers will help you plan your fitness goals, with
              regular reviews to keep you on track.
            </p>
          </Fragment>
        }
        button={
          <a className="button button--dark button--large" href="/memberships">
            <i className="fa fa-info-circle gs-u-mr" />
            Find out more
          </a>
        }
      />
      <Section
        isReversed
        title="The Best Classes"
        imageUrl="/assets/img/spin.jpeg"
        text={
          <Fragment>
            <p className="gel-pica gs-u-mb++">
              From Beats, to Bars, to Circuits, we've got a huge range of
              classes to suit all levels.
            </p>
          </Fragment>
        }
        button={
          <a className="button button--dark button--large" href="/classes">
            <i className="fa fa-users gs-u-mr" />
            View classes
          </a>
        }
      />
      <Section
        title="Personal Training"
        imageUrl="/assets/img/personal-training.jpg"
        text={
          <Fragment>
            <p className="gel-pica gs-u-mb+">
              Robert and Jo both offer Personal training within the gym
              environment or your own home.
            </p>
            <p className="gel-pica gs-u-mb+">
              We can cater for all levels of fitness from beginners to advanced
              athletes.
            </p>
            <p className="gel-pica gs-u-mb++">
              We can also accommodate for rehabilitation and GP referrals.
            </p>
          </Fragment>
        }
        button={
          <a
            className="button button--dark button--large"
            href="/personal-training"
          >
            <i className="fa fa-info-circle gs-u-mr" />
            Find out more
          </a>
        }
      />
      <Section
        isReversed
        title="Pay as you go"
        imageUrl="/assets/img/court-hire.jpeg"
        text={
          <p className="gel-pica gs-u-mb++">
            Purchase your one-time gym slot(s) or grab yourself a full weeks'
            gym access pass.
          </p>
        }
        button={
          <a className="button button--dark button--large" href="/classes#gym">
            <i className="fa fa-info-circle gs-u-mr" />
            Get started
          </a>
        }
      />
    </Fragment>
  );
};

export default Home;
