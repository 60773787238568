import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";

import PaymentPlan from "../components/PaymentPlan";
import SummerPaymentPlan from "../components/SummerPaymentPlan";
import MembershipDetails from "../components/MembershipDetails";
import { oneTimePlans } from "../utils/consts";

const mapStateToProps = (state) => ({
  plans: get(state, "payment.plans", []),
  membership: get(state, "payment.membership", {}),
});

const renderPaymentPlan = ({ plan, membership }) => {
  if (membership.membershipLevel === "summer") {
    return (
      <SummerPaymentPlan
        classes="gel-layout__item gel-1/3@l"
        {...plan}
        key={plan.frequency}
        membershipId={membership._id}
      />
    );
  }

  return (
    <PaymentPlan
      classes="gel-layout__item gel-1/3@l"
      {...plan}
      key={plan.frequency}
      membershipId={membership._id}
    />
  );
};

/**
 * PaymentPlans component
 *
 * @param {object} props
 */
const PaymentPlans = ({ plans, membership }) => {
  return (
    <React.Fragment>
      <h3 className="section__title gel-double-pica-bold gs-u-mb++">
        Your Membership
      </h3>
      <MembershipDetails membership={membership} />
      <h3 className="section__title gel-double-pica-bold gs-u-mb++ o-slice-spacing">
        Select payment plan
      </h3>
      <ul className="gel-layout gel-layout--equal">
        {plans
          .filter((plan) =>
            oneTimePlans.includes(membership.membershipLevel)
              ? true
              : plan.frequency !== "one time"
          )
          .map((plan) => renderPaymentPlan({ plan, membership }))}
      </ul>
    </React.Fragment>
  );
};

PaymentPlans.propTypes = {
  plans: PropTypes.array.isRequired,
  membership: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(PaymentPlans);
