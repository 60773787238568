/* eslint-disable react/no-array-index-key */
/* eslint-disable prettier/prettier */
import React, { Fragment } from 'react';
import BookingsModal from '../../containers/BookingsModal';
import BookingsForm from '../../containers/BookingsForm';
import Banner from '../../components/Banner';
import TextSection from '../../components/TextSection';
import { CLASSES } from '../../utils/consts';

/**
 * Classes component
 *
 * @param {object} props
 */
const Classes = () => {
  return (
    <Fragment>
      <BookingsModal />
      <Banner
        title="Classes"
        classes="banner--classes"
        imageUrl="/assets/img/spin.jpeg"
      />
      <BookingsForm />
      <div className="classes">
        <TextSection
          title="Our Classes"
          textHtml={
            <div className="gel-pica">
              <p className="gs-u-mb+">
                Here's our full list of available classes at The Gym at Moffat.
              </p>
            </div>
          }
        />
        <ul
          id="list"
          className="classes__list gel-wrap gel-8/12@l o-slice-spacing-bottom"
        >
          {CLASSES.map(classObj => (
            <li key={classObj.name} className="gs-u-mb++">
              <p className="gel-paragon-bold gs-u-mb">{classObj.name}</p>
              <div className="gel-pica">{classObj.description}</div>
              {classObj.videoLink && (
                <div className="gs-u-mt gs-u-mb+">
                  <a
                    className="button button--large"
                    target="_blank"
                    href={classObj.videoLink}
                    rel="noreferrer"
                  >
                    <i className="fa fa-play" /> Watch Video
                  </a>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </Fragment>
  );
};

export default Classes;
