export const courtHireActivities = [
  {
    id: 'badminton',
    name: 'Badminton',
    description:
      'Badminton rackets x 4 and shuttlecocks are in the games cupboard . Please use the badminton posts on wheels and the badminton net at the top height. ',
  },
  {
    id: 'basketball',
    name: 'Basketball',
    description:
      'We have various basketballs in the games cupboard. You may pull the basketball hoop out from the wall carefully with the hook provided. Please do not hang on the basketball net. ',
  },
  {
    id: 'indoor-social-football',
    name: 'Indoor social football',
    description: 'An indoor football is in the games cupboard.',
  },
  {
    id: 'indoor-tennis',
    name: 'Indoor tennis',
    description:
      'Indoor tennis rackets and balls are in the games cupboard. Please use the badminton posts on wheels and the badminton net at the top height.',
  },
  {
    id: 'pickleball',
    name: 'Pickleball',
    description:
      'There are 4 pickleball bats and balls in the games cupboard. Please use the two badminton net poles on wheels and the badminton net set at the lower height. The pickleball rules are listed on posters on the whiteboard in the court area.',
  },
];

export default [
  {
    day: 'Monday',
    slots: [
      {
        timeLabel: '7am - 8am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 7,
        startMinutes: 0,
        endHour: 8,
        endMinutes: 0,
      },
      {
        timeLabel: '8am - 9am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 8,
        startMinutes: 0,
        endHour: 9,
        endMinutes: 0,
      },
      {
        timeLabel: '9am - 10am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 9,
        startMinutes: 0,
        endHour: 10,
        endMinutes: 0,
      },
      {
        timeLabel: '10am - 11am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 10,
        startMinutes: 0,
        endHour: 11,
        endMinutes: 0,
      },
      {
        timeLabel: '5pm - 6pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 17,
        startMinutes: 0,
        endHour: 18,
        endMinutes: 0,
      },
      {
        timeLabel: '6pm - 7pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 18,
        startMinutes: 0,
        endHour: 19,
        endMinutes: 0,
      },
      {
        timeLabel: '7pm - 8pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 19,
        startMinutes: 0,
        endHour: 20,
        endMinutes: 0,
      },
      {
        timeLabel: '8pm - 9pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 20,
        startMinutes: 0,
        endHour: 21,
        endMinutes: 0,
      },
      {
        timeLabel: '9pm - 10pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 21,
        startMinutes: 0,
        endHour: 22,
        endMinutes: 0,
      },
    ],
  },
  {
    day: 'Tuesday',
    slots: [
      {
        timeLabel: '7am - 8am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 7,
        startMinutes: 0,
        endHour: 8,
        endMinutes: 0,
      },
      {
        timeLabel: '8am - 9am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 8,
        startMinutes: 0,
        endHour: 9,
        endMinutes: 0,
      },
      {
        timeLabel: '9am - 10am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 9,
        startMinutes: 0,
        endHour: 10,
        endMinutes: 0,
      },
      {
        timeLabel: '12 noon - 1pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 12,
        startMinutes: 0,
        endHour: 13,
        endMinutes: 0,
      },
      {
        timeLabel: '1pm - 2pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 13,
        startMinutes: 0,
        endHour: 14,
        endMinutes: 0,
      },
      {
        timeLabel: '2pm - 3pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 14,
        startMinutes: 0,
        endHour: 15,
        endMinutes: 0,
      },
      {
        timeLabel: '3pm - 4pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 15,
        startMinutes: 0,
        endHour: 16,
        endMinutes: 0,
      },
      {
        timeLabel: '4pm - 5pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 16,
        startMinutes: 0,
        endHour: 17,
        endMinutes: 0,
      },
      {
        timeLabel: '5pm - 6pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 17,
        startMinutes: 0,
        endHour: 18,
        endMinutes: 0,
      },
      {
        timeLabel: '7pm - 8pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 19,
        startMinutes: 0,
        endHour: 20,
        endMinutes: 0,
      },
      {
        timeLabel: '8pm - 9pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 20,
        startMinutes: 0,
        endHour: 21,
        endMinutes: 0,
      },
      {
        timeLabel: '9pm - 10pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 21,
        startMinutes: 0,
        endHour: 22,
        endMinutes: 0,
      },
    ],
  },
  {
    day: 'Wednesday',
    slots: [
      {
        timeLabel: '7am - 8am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 7,
        startMinutes: 0,
        endHour: 8,
        endMinutes: 0,
      },
      {
        timeLabel: '8am - 9am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 8,
        startMinutes: 0,
        endHour: 9,
        endMinutes: 0,
      },
      {
        timeLabel: '9am - 10am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 9,
        startMinutes: 0,
        endHour: 10,
        endMinutes: 0,
      },
      {
        timeLabel: '10am - 11am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 10,
        startMinutes: 0,
        endHour: 11,
        endMinutes: 0,
      },
      {
        timeLabel: '12 noon - 1pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 12,
        startMinutes: 0,
        endHour: 13,
        endMinutes: 0,
      },
      {
        timeLabel: '1pm - 2pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 13,
        startMinutes: 0,
        endHour: 14,
        endMinutes: 0,
      },
      {
        timeLabel: '2pm - 3pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 14,
        startMinutes: 0,
        endHour: 15,
        endMinutes: 0,
      },
      {
        timeLabel: '3pm - 4pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 15,
        startMinutes: 0,
        endHour: 16,
        endMinutes: 0,
      },
      {
        timeLabel: '4pm - 5pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 16,
        startMinutes: 0,
        endHour: 17,
        endMinutes: 0,
      },
      {
        timeLabel: '5pm - 6pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 17,
        startMinutes: 0,
        endHour: 18,
        endMinutes: 0,
      },
      {
        timeLabel: '6pm - 7pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 18,
        startMinutes: 0,
        endHour: 19,
        endMinutes: 0,
      },
      {
        timeLabel: '7pm - 8pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 19,
        startMinutes: 0,
        endHour: 20,
        endMinutes: 0,
      },
      {
        timeLabel: '8pm - 9pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 20,
        startMinutes: 0,
        endHour: 21,
        endMinutes: 0,
      },
      {
        timeLabel: '9pm - 10pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 21,
        startMinutes: 0,
        endHour: 22,
        endMinutes: 0,
      },
    ],
  },
  {
    day: 'Thursday',
    slots: [
      {
        timeLabel: '7am - 8am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 7,
        startMinutes: 0,
        endHour: 8,
        endMinutes: 0,
      },
      {
        timeLabel: '8am - 9am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 8,
        startMinutes: 0,
        endHour: 9,
        endMinutes: 0,
      },
      {
        timeLabel: '9am - 10am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 9,
        startMinutes: 0,
        endHour: 10,
        endMinutes: 0,
      },
      {
        timeLabel: '10am - 11am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 10,
        startMinutes: 0,
        endHour: 11,
        endMinutes: 0,
      },
      {
        timeLabel: '12 noon - 1pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 12,
        startMinutes: 0,
        endHour: 13,
        endMinutes: 0,
      },
      {
        timeLabel: '1pm - 2pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 13,
        startMinutes: 0,
        endHour: 14,
        endMinutes: 0,
      },
      {
        timeLabel: '3pm - 4pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 15,
        startMinutes: 0,
        endHour: 16,
        endMinutes: 0,
      },
      {
        timeLabel: '4pm - 5pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 16,
        startMinutes: 0,
        endHour: 17,
        endMinutes: 0,
      },
      {
        timeLabel: '5pm - 6pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 17,
        startMinutes: 0,
        endHour: 18,
        endMinutes: 0,
      },
      {
        timeLabel: '7pm - 8pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 19,
        startMinutes: 0,
        endHour: 20,
        endMinutes: 0,
      },
      {
        timeLabel: '8pm - 9pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 20,
        startMinutes: 0,
        endHour: 21,
        endMinutes: 0,
      },
      {
        timeLabel: '9pm - 10pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 21,
        startMinutes: 0,
        endHour: 22,
        endMinutes: 0,
      },
    ],
  },
  {
    day: 'Friday',
    slots: [
      {
        timeLabel: '7am - 8am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 7,
        startMinutes: 0,
        endHour: 8,
        endMinutes: 0,
      },
      {
        timeLabel: '8am - 9am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 8,
        startMinutes: 0,
        endHour: 9,
        endMinutes: 0,
      },
      {
        timeLabel: '9am - 10am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 9,
        startMinutes: 0,
        endHour: 10,
        endMinutes: 0,
      },
      {
        timeLabel: '10am - 11am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 10,
        startMinutes: 0,
        endHour: 11,
        endMinutes: 0,
      },
      {
        timeLabel: '11am - 12 noon',
        label: 'Court Hire',
        isBookable: true,
        startHour: 11,
        startMinutes: 0,
        endHour: 12,
        endMinutes: 0,
      },
      {
        timeLabel: '12 noon - 1pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 12,
        startMinutes: 0,
        endHour: 13,
        endMinutes: 0,
      },
      {
        timeLabel: '1pm - 2pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 13,
        startMinutes: 0,
        endHour: 14,
        endMinutes: 0,
      },
      {
        timeLabel: '2pm - 3pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 14,
        startMinutes: 0,
        endHour: 15,
        endMinutes: 0,
      },
      {
        timeLabel: '3pm - 4pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 15,
        startMinutes: 0,
        endHour: 16,
        endMinutes: 0,
      },
      {
        timeLabel: '4pm - 5pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 16,
        startMinutes: 0,
        endHour: 17,
        endMinutes: 0,
      },
      {
        timeLabel: '5pm - 6pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 17,
        startMinutes: 0,
        endHour: 18,
        endMinutes: 0,
      },
      {
        timeLabel: '6pm - 7pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 18,
        startMinutes: 0,
        endHour: 19,
        endMinutes: 0,
      },
      {
        timeLabel: '7pm - 8pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 19,
        startMinutes: 0,
        endHour: 20,
        endMinutes: 0,
      },
      {
        timeLabel: '8pm - 9pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 20,
        startMinutes: 0,
        endHour: 21,
        endMinutes: 0,
      },
      {
        timeLabel: '9pm - 10pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 21,
        startMinutes: 0,
        endHour: 22,
        endMinutes: 0,
      },
    ],
  },
  {
    day: 'Saturday',
    slots: [
      {
        timeLabel: '7am - 8am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 7,
        startMinutes: 0,
        endHour: 8,
        endMinutes: 0,
      },
      {
        timeLabel: '8am - 9am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 8,
        startMinutes: 0,
        endHour: 9,
        endMinutes: 0,
      },
      {
        timeLabel: '9am - 10am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 9,
        startMinutes: 0,
        endHour: 10,
        endMinutes: 0,
      },
      {
        timeLabel: '11am - 12 noon',
        label: 'Court Hire',
        isBookable: true,
        startHour: 11,
        startMinutes: 0,
        endHour: 12,
        endMinutes: 0,
      },
      {
        timeLabel: '12 noon - 1pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 12,
        startMinutes: 0,
        endHour: 13,
        endMinutes: 0,
      },
      {
        timeLabel: '1pm - 2pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 13,
        startMinutes: 0,
        endHour: 14,
        endMinutes: 0,
      },
      {
        timeLabel: '2pm - 3pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 14,
        startMinutes: 0,
        endHour: 15,
        endMinutes: 0,
      },
      {
        timeLabel: '3pm - 4pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 15,
        startMinutes: 0,
        endHour: 16,
        endMinutes: 0,
      },
      {
        timeLabel: '4pm - 5pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 16,
        startMinutes: 0,
        endHour: 17,
        endMinutes: 0,
      },
      {
        timeLabel: '5pm - 6pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 17,
        startMinutes: 0,
        endHour: 18,
        endMinutes: 0,
      },
      {
        timeLabel: '6pm - 7pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 18,
        startMinutes: 0,
        endHour: 19,
        endMinutes: 0,
      },
      {
        timeLabel: '7pm - 8pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 19,
        startMinutes: 0,
        endHour: 20,
        endMinutes: 0,
      },
      {
        timeLabel: '8pm - 9pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 20,
        startMinutes: 0,
        endHour: 21,
        endMinutes: 0,
      },
      {
        timeLabel: '9pm - 10pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 21,
        startMinutes: 0,
        endHour: 22,
        endMinutes: 0,
      },
    ],
  },
  {
    day: 'Sunday',
    slots: [
      {
        timeLabel: '7am - 8am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 7,
        startMinutes: 0,
        endHour: 8,
        endMinutes: 0,
      },
      {
        timeLabel: '8am - 9am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 8,
        startMinutes: 0,
        endHour: 9,
        endMinutes: 0,
      },
      {
        timeLabel: '9am - 10am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 9,
        startMinutes: 0,
        endHour: 10,
        endMinutes: 0,
      },
      {
        timeLabel: '10am - 11am',
        label: 'Court Hire',
        isBookable: true,
        startHour: 10,
        startMinutes: 0,
        endHour: 11,
        endMinutes: 0,
      },
      {
        timeLabel: '11am - 12 noon',
        label: 'Court Hire',
        isBookable: true,
        startHour: 11,
        startMinutes: 0,
        endHour: 12,
        endMinutes: 0,
      },
      {
        timeLabel: '12 noon - 1pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 12,
        startMinutes: 0,
        endHour: 13,
        endMinutes: 0,
      },
      {
        timeLabel: '1pm - 2pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 13,
        startMinutes: 0,
        endHour: 14,
        endMinutes: 0,
      },
      {
        timeLabel: '2pm - 3pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 14,
        startMinutes: 0,
        endHour: 15,
        endMinutes: 0,
      },
      {
        timeLabel: '3pm - 4pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 15,
        startMinutes: 0,
        endHour: 16,
        endMinutes: 0,
      },
      {
        timeLabel: '4pm - 5pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 16,
        startMinutes: 0,
        endHour: 17,
        endMinutes: 0,
      },
      {
        timeLabel: '5pm - 6pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 17,
        startMinutes: 0,
        endHour: 18,
        endMinutes: 0,
      },
      {
        timeLabel: '6pm - 7pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 18,
        startMinutes: 0,
        endHour: 19,
        endMinutes: 0,
      },
      {
        timeLabel: '7pm - 8pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 19,
        startMinutes: 0,
        endHour: 20,
        endMinutes: 0,
      },
      {
        timeLabel: '8pm - 9pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 20,
        startMinutes: 0,
        endHour: 21,
        endMinutes: 0,
      },
      {
        timeLabel: '9pm - 10pm',
        label: 'Court Hire',
        isBookable: true,
        startHour: 21,
        startMinutes: 0,
        endHour: 22,
        endMinutes: 0,
      },
    ],
  },
];
