const getMembershipTypeLabel = type => {
  if (type === "child") {
    return "Child";
  }

  return "Adult";
};

const getMembershipLevelLabel = type => {
  if (type === "online-only") {
    return "Online Only";
  }

  if (type === "gym-only") {
    return "Gym Only";
  }

  if (type === "summer") {
    return "Summer";
  }

  if (type === "gym-only-6-months") {
    return "Gym Only - 6 Months";
  }

  if (type === "gym-and-classes-6-months") {
    return "Gym & Classes - 6 Months";
  }

  return "Gym & Classes";
};

export { getMembershipTypeLabel, getMembershipLevelLabel };
