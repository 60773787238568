import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * Notification component
 *
 * @param {object} props
 */
const Notification = ({ classes, message, errorMessage }) => {
  const [classText, setClassText] = useState('animate__bounceInDown');
  useEffect(() => {
    if (message || errorMessage) {
      window.history.pushState({}, document.title, window.location.pathname);
      setTimeout(() => {
        setClassText('animate__bounceOutUp');
      }, 4000);
    }
  });
  const notificationClasses = classNames(
    'notification gs-u-p+ animate__animated',
    `${classText}`,
    {
      [`${classes}`]: classes,
      'notification--error': errorMessage,
    }
  );

  if (message || errorMessage) {
    return (
      <div className={notificationClasses}>
        <p className="gel-pica-bold">{message || errorMessage}</p>
      </div>
    );
  }

  return null;
};

Notification.defaultProps = {
  classes: null,
  message: null,
  errorMessage: null,
};

Notification.propTypes = {
  message: PropTypes.string,
  classes: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default Notification;
