const initialState = {
  activeNavItem: "discover",
  isSidebarOpen: false,
  isModalOpen: false,
  deviceType: "desktop"
};

export default function ui(state = initialState, action) {
  switch (action.type) {
    case "UI_SIDEBAR_TOGGLE":
      return { ...state, isSidebarOpen: action.isSidebarOpen };
    case "UI_MODAL_TOGGLE":
      return { ...state, isModalOpen: action.isModalOpen };
    default:
      return state;
  }
}
