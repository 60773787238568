/* eslint-disable prettier/prettier */
import React, { Fragment, useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";

/**
 * Login component
 *
 * @param {object} props
 */
const Login = ({ ptrt }) => {
  const formEl = useRef(null);

  const getFormAction = () => {
    if (ptrt) {
      return `/api/login?ptrt=${ptrt}`;
    }

    return "/api/login";
  }

  return (
    <Fragment>
      <div className="hero hero--full-page gs-u-align-center">
        <div className="gel-22/24 gel-2/3@m gel-1/2@l gs-u-mt gs-u-mt++@l gs-u-display-inline-block gs-u-display-block@l">
          <div
            className="hero__content gs-u-p++ gel-1/1 gel-18/24@l gs-u-float-right@l o-slice-spacing o-slice-spacing-bottom gs-u-align-left"
          >
            <header className="header header--no-border">
              <a href="/" className="gel-1/1 gs-u-display-inline-block">
                <img className="logo gel-1/1" src="/assets/img/logo.svg" alt="" />
              </a>
            </header>
            <h2 className="gs-u-align-center gs-u-mb++">L O G I N</h2>
            <Formik
              initialValues={{
                username: "",
                password: ""
              }}
              validationSchema={Yup.object().shape({
                username: Yup.string().required("Username/email is required"),
                password: Yup.string().required("Password is required")
              })}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur
                } = props;
                const handleSubmit = () => {
                  formEl.current.submit();
                }
                return (
                  <form action={getFormAction()} method="POST" encType="application/x-www-form-urlencoded" ref={formEl} onSubmit={handleSubmit} className="gel-layout dark-form">
                    <fieldset className="gel-layout__item form-element form-element__control gel-1/1 gs-u-mb+">
                      <i className="fa fa-user form-element__icon" />
                      <input
                        id="username"
                        name="username"
                        type="text"
                        placeholder="Username or email address"
                        value={values.username}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.username && touched.username
                            ? "text-input error"
                            : "text-input"
                        }
                      />
                      {errors.username && touched.username && (
                        <div className="input-error gs-u-mt">{errors.username}</div>
                      )}
                    </fieldset>
                    
                    <fieldset className="gel-layout__item form-element form-element__control gel-1/1 gs-u-mb+">
                      <i className="fa fa-lock form-element__icon" />
                      <input
                        id="password"
                        name="password"
                        type="password"
                        placeholder="Password"
                        required
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.password && touched.password
                            ? "text-input error"
                            : "text-input"
                        }
                      />
                      {errors.password && touched.password && (
                        <div className="input-error gs-u-mt">{errors.password}</div>
                      )}
                    </fieldset>

                    <fieldset className="gel-layout__item gel-1/1">
                      <button
                        className="button button--filled button--full button--large"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Sign in
                      </button>
                    </fieldset>

                    <fieldset className="gel-layout__item gel-1/1 gel-long-primer gs-u-align-center gs-u-mt++">
                      <a className="" href="/password-reset">
                        Forgot your password?
                      </a>
                    </fieldset>

                    <fieldset className="gel-layout__item gel-1/1">
                      <hr className="gs-u-mv++" />
                      <p>
                        Don't have an account? You can <a className="" href="/memberships">get started here</a>.
                      </p>
                    </fieldset>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
