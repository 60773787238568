const initialState = {
  activeUser: null,
  list: []
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case "USERS_SET_ACTIVE":
      return { ...state, activeUser: action.activeUser };
    default:
      return state;
  }
}
