import React from 'react';
import OpeningHours from './OpeningHours';

/**
 * Footer component
 *
 * @param {object} props
 */
const Footer = () => {
  return (
    <footer className="footer gs-u-pv++">
      <div className="gel-wrap gs-u-pv+">
        <div className="gs-u-align-center">
          <img
            className="footer__img gs-u-mb++"
            src="/assets/img/logo.svg"
            alt=""
          />
          <OpeningHours />
          <div className="gs-u-mv++">
            <h2>
              <b>Enquiries and Bookings</b>
            </h2>
            <ul className="footer__section gs-u-align-left gs-u-display-inline-block gel-1/1 gs-u-clearfix gs-u-mb++">
              <li className="footer__section_item gs-u-mb+ gs-u-mb0@l gs-u-display-inline-block gs-u-align-center gel-1/1">
                <div className="gs-o-media__body gs-u-pt+">
                  <span className="footer__addr_1 gs-u-display-block gs-u-mb-">
                    <b>Monday to Friday: </b> 6am to 9pm
                  </span>
                  <span className="footer__addr_3 gs-u-display-block gs-u-mb" />
                  <span className="footer__addr_3 gs-u-display-block gs-u-mb- gel-pica yellow">
                    <i>
                      * Saturday and Sunday: Emails and messages only! Phone
                      calls in emergency.
                    </i>
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <ul className="footer__section gs-u-align-left gs-u-display-inline-block gel-1/1 gs-u-clearfix gs-u-mb++">
            <li className="footer__section_item gs-u-mb+ gs-u-mb0@l gs-u-display-inline-block gs-u-align-center gel-1/1 gel-1/3@l">
              <i className="fa fa-map-marker gs-u-mr" />
              <div className="gs-o-media__body gs-u-ml- gs-u-display-inline-block gs-u-align-middle gs-u-align-left">
                <span className="footer__addr_1 gs-u-display-block gs-u-mb-">
                  <b>The Gym, Edinburgh Road</b>
                </span>
                <span className="footer__addr_3 gs-u-display-block gs-u-mb-">
                  Moffat, DG10 9RY
                </span>
              </div>
            </li>
            <li className="footer__section_item gs-u-mb+ gs-u-mb0@l gs-u-display-inline-block gs-u-align-center gs-u-align-middle gel-1/1 gel-1/3@l">
              <p className="gs-u-align-middle gs-u-mb+">
                <i className="fa fa-phone gs-u-mr" />
                <a href="tel:07519282211" className="footer__phone gs-u-pv">
                  <b>07519282211</b>
                </a>
                <span className="gs-u-display-block gel-minion gs-u-ml">
                  All enquiries
                </span>
              </p>
            </li>
            <li className="footer__section_item gs-u-mb+ gs-u-mb0@l gs-u-display-inline-block gs-u-align-center gs-u-align-middle gel-1/1 gel-1/3@l">
              <i className="fa fa-at gs-u-mr" />
              <a href="mailto:thegymatmoffat@gmail.com" className="gs-u-pv">
                <b className="footer__email">thegymatmoffat@gmail.com</b>
              </a>
            </li>
          </ul>
          <ul className="footer__socials gs-u-mb+">
            <li className="gs-u-display-inline gs-u-mr">
              <a
                className="button"
                href="https://www.facebook.com/thegymatmoffat"
              >
                <i className="fa fa-facebook-square" />
              </a>
            </li>
            <li className="gs-u-display-inline">
              <a
                className="button"
                href="https://www.instagram.com/thegymatmoffat"
              >
                <i className="fa fa-instagram" />
              </a>
            </li>
          </ul>
          <div className="gs-u-mb++">
            <p className="gs-u-mt++">&copy; The Gym at Moffat</p>
          </div>
          <div className="gs-u-mb++">
            <a
              className="gs-u-mt++"
              href="https://s3.eu-west-1.amazonaws.com/thegymatmoffat.co.uk/The+Gym+at+Moffat+T%26C's+01_08_2023.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </a>
          </div>
        </div>
      </div>
      <div className="gel-wrap gel-wrap--small gel-long-primer gs-u-align-center gs-u-mb++">
        <a
          href="https://maps.app.goo.gl/8xX6zWQBRdZF7SVZ6"
          target="_blank"
          rel="noreferrer"
        >
          The Gym at Moffat, Edinburgh Road, Moffat, Dumfriesshire, DG10 9RY
        </a>
        . Company registered in Scotland no. - 595701. Registered office:
        Companies House, 4th Floor Edinburgh Quay 2, 139 Fountainbridge,
        Edinburgh, EH3 9FF.
      </div>
      <div className="gel-wrap gs-u-align-center">
        <p>
          Website by
          <a
            className="link gs-u-ml-"
            href="https://www.facebook.com/CodeWithCraig"
          >
            Code With Craig
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
