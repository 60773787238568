const initialState = {
  activeMember: null,
  list: []
};

export default function members(state = initialState, action) {
  switch (action.type) {
    case "MEMBERS_SET_ACTIVE":
      return { ...state, activeMember: action.activeMember };
    default:
      return state;
  }
}
