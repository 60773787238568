/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Modal from '../containers/Modal';
import { toggleModal } from '../store/actions/ui';

export const EditSubscriptionId = ({
  membership,
  subscriptionId,
  setSubscriptionId,
}) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();

    setIsSubmitting(true);

    await axios
      .put('/api/memberships', {
        subscriptionId,
        membership,
      })
      .catch(err => {
        setIsSubmitting(false);
        const { data: errData } = err.response;
        console.log(errData);

        if (errData.error) {
          return window.alert(errData.error);
        }

        if (errData.message) {
          return window.alert(errData.message);
        }

        return window.alert(
          'Looks like something went wrong there. Please try again.'
        );
      });

    setIsSubmitting(false);
    window.location.href =
      '/admin/members?m=Subscription ID updated successfully.';
  };

  const openModal = e => {
    e.preventDefault();
    setSubscriptionId(membership.subscriptionId);
    dispatch(toggleModal(true));
  };

  return (
    <>
      <Modal onModalClose={() => setSubscriptionId(member.subscriptionId)}>
        <React.Fragment>
          <h2 className="gel-double-pica-bold gs-u-display-inline-block gs-u-mb++">
            E D I T
          </h2>
          <div className="gs-u-mt+">
            <form className="" onSubmit={handleSubmit}>
              <fieldset className="gs-u-mb++ gel-1/1">
                <label
                  htmlFor="new-subscription-id"
                  className="gs-u-display-block"
                >
                  Subscription ID
                </label>
                <input
                  id="new-subscription-id"
                  type="text"
                  value={subscriptionId}
                  onChange={({ target }) => setSubscriptionId(target.value)}
                  className="text-input"
                  required
                />
              </fieldset>
              <fieldset className="gs-u-mb++ gel-1/1">
                <button
                  className="button button--filled button--large"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save changes
                </button>
              </fieldset>
            </form>
          </div>
        </React.Fragment>
      </Modal>
      <a title="Edit this members subscription id" onClick={openModal} href="#">
        <b className="edit gs-u-ml">
          <i className="fa fa-pencil-square" />
        </b>
      </a>
    </>
  );
};
