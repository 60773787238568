import React, { Fragment } from "react";
import TextSection from "../../components/TextSection";
import BookingsList from "../../containers/BookingsList";
import MembersList from "../../containers/MembersList";
import UsersList from "../../containers/UsersList";

/**
 * Admin component
 *
 * @param {object} props
 */
const Admin = () => {
  return (
    <Fragment>
      <TextSection
        title="Admin"
        textHtml={
          <div className="gel-pica">
            <p className="gs-u-mb+">
              Hello Gym Admins! Here, you'll be able to manage your users,
              members, subcriptions and bookings.
            </p>
          </div>
        }
      />
      <div className="gel-wrap o-slice-spacing-bottom">
        <ul className="gel-layout">
          <li className="gel-layout__item gel-1/2@l gs-u-mb++">
            <BookingsList title="Latest Bookings" />
          </li>
          <li className="gel-layout__item gel-1/2@l">
            <MembersList title="Latest Members" />
          </li>
          <li className="gel-layout__item gel-1/2@l">
            <UsersList title="Latest PAYG Users" />
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default Admin;
