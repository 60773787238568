const initialState = {
  plans: [],
  membership: {},
  couplesMemberships: []
};

export default function payment(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
