import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Banner from "../../components/Banner";
import MembershipForm from "../../containers/MembershipForm";

/**
 * Memberships component
 *
 * @param {object} props
 */
const Memberships = ({ level }) => {
  return (
    <Fragment>
      <Banner
        title="Want to come join us?"
        imageUrl="/assets/img/classes.jpg"
      />
      <MembershipForm />
    </Fragment>
  );
};

Memberships.propTypes = {
  level: PropTypes.string.isRequired
};

export default Memberships;
