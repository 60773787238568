export const toggleSidebar = isSidebarOpen => {
  return dispatch => {
    dispatch({
      type: "UI_SIDEBAR_TOGGLE",
      isSidebarOpen
    });
  };
};

export const toggleModal = isModalOpen => {
  return dispatch => {
    dispatch({
      type: "UI_MODAL_TOGGLE",
      isModalOpen
    });
  };
};
