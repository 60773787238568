/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import Modal from './Modal';
import BookingItem from '../components/BookingItem';

const mapStateToProps = state => ({
  activeBooking: get(state, 'bookings.activeBooking'),
  bookings: get(state, 'bookings.list'),
});

const renderOtherBookings = (bookings, activeBooking) => {
  const {
    bookingStartDate: activeBookingDate,
    _id: activeBookingId,
  } = activeBooking;
  const bookingDate = new Date(activeBookingDate);
  const otherBookings = bookings.filter(booking => {
    const { bookingStartDate, _id: bookingId } = booking;
    const date = new Date(bookingStartDate);
    const isNotSameBooking = activeBookingId !== bookingId;
    return isNotSameBooking && date.getTime() === bookingDate.getTime();
  });

  if (otherBookings && otherBookings.length) {
    return (
      <ul className="bookings-list gs-u-mb++">
        {otherBookings.map(booking => (
          <BookingItem key={booking._id} booking={booking} />
        ))}
      </ul>
    );
  }

  return <p>There are no other bookings for this class.</p>;
};

/**
 * EventModal component
 *
 * @param {object} props
 */
const EventModal = ({ activeBooking, bookings, user }) => {
  if (!activeBooking) {
    return null;
  }

  const booking = bookings.find(item => item._id === activeBooking.id);
  const isCourtHire = booking.class.id === 'court-hire';

  return (
    <Modal classes="modal--large">
      <React.Fragment>
        <h2 className="gel-double-pica-bold gs-u-display-inline-block gs-u-mb++">
          {isCourtHire ? 'C O U R T - H I R E' : 'B O O K I N G'}
        </h2>
        <ul className="bookings-list gs-u-mb++">
          <BookingItem booking={booking} />
        </ul>
        {!isCourtHire && (
          <>
            <h2 className="gel-double-pica-bold gs-u-display-inline-block gs-u-mb++">
              <span className="gs-u-display-inline-block gs-u-mr-alt">
                R E S T
              </span>
              <span className="gs-u-display-inline-block gs-u-mr-alt">O F</span>
              <span className="gs-u-display-inline-block">C L A S S</span>
            </h2>
            {renderOtherBookings(bookings, booking)}
          </>
        )}
      </React.Fragment>
    </Modal>
  );
};

export default connect(mapStateToProps)(EventModal);
