import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

/**
 * PaymentPlan component
 *
 * @param {object} props
 */
const PaymentPlan = ({
  classes,
  name,
  frequency,
  price,
  label,
  planId,
  membershipId
}) => {
  const paymentPlanClasses = classNames("payment-plan", classes);

  return (
    <li className={paymentPlanClasses}>
      <div className="payment-plan__wrapper gs-u-p++ gel-1/1">
        <div className="payment-plan__content gs-u-align-center">
          <h4 className="payment-plan__name gel-pica gs-u-mb++">{name}</h4>
          <hr className="gs-u-mv+" />
          {label && (
            <span className="payment-plan__label gel-brevier-bold gs-u-p gs-u-display-inline-block gs-u-mb+">
              {label}
            </span>
          )}
          <h4 className="payment-plan__price gel-canon">{price}</h4>
          <p className="payment-plan__frequency gel-brevier gs-u-mb++">
            {frequency}
          </p>
        </div>
        <div className="payment-plan__paypal">
          <a
            className="button button--full button--filled button--large button--gold"
            type="button"
            href={`/api/paypal/subscription-init?planId=${planId}&membershipId=${membershipId}&planName=${name}`}
          >
            <img src="/assets/img/paypal.svg" alt="PayPal" />
          </a>
        </div>
        <div className="payment-plan__issues gs-u-mt gs-u-p+">
          <p>
            If you experience issues with your PayPal payment, please contact us
            and we can provide alternative methods while we try to fix your
            issue.
          </p>
        </div>
      </div>
    </li>
  );
};

PaymentPlan.defaultProps = {
  classes: null,
  label: null
};

PaymentPlan.propTypes = {
  name: PropTypes.string.isRequired,
  frequency: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  planId: PropTypes.string.isRequired,
  membershipId: PropTypes.string.isRequired,
  label: PropTypes.node,
  classes: PropTypes.string
};

export default PaymentPlan;
