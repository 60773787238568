/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/prop-types */
import React, { useState, useRef } from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import {
  getMembershipTypeLabel,
  getMembershipLevelLabel
} from "../utils/memberships";

const mapStateToProps = state => ({
});

/**
 * RegisterMember container
 *
 * @param {object} props
 */
const RegisterMember = () => {
  const [userInfo, setUserInfo] = useState();
  const [isGettingMembership, setIsGettingMembership] = useState(false);
  const formRef = useRef();

  const getMembership = async () => {
    setIsGettingMembership(true);
    const { email } = get(formRef, "current.values");

    const response = await axios.get(`/api/memberships/${email}`).catch(err => {
      const { data: errData } = err.response;
      if (errData.message) {
        alert(errData.message);
      } else {
        alert(
          "Something went wrong there. Please try again and if the problem persists, please let us know! Thanks for your patience."
        );
      }
    });

    setIsGettingMembership(false);

    if (get(response, "data.membership")) {
      setUserInfo(response.data.membership);
    }
  };

  return (
    <div className="gel-wrap gel-wrap o-slice-spacing o-slice-spacing-bottom">
      <h2 className="gs-u-mb++">
        Connect your membership to your online account!
      </h2>
      <p className="gs-u-mb++">
        In order to create your online account, you'll need to connect your
        membership. Enter the email address connected to your membership below
        and click "Connect Membership". Once your membership details are
        displayed, you can then proceed to creating your account credentials.
      </p>
      <Formik
        innerRef={formRef}
        initialValues={{
          email: "",
          username: "",
          password: ""
        }}
        onSubmit={async values => {
          if (!userInfo || !userInfo._id) {
            return alert("Please connect your membership before you continue.");
          }

          const postData = { ...values, ...userInfo };

          const { data } = await axios
            .post("/api/users", postData, { credentials: "include" })
            .catch(err => {
              const { data: errData } = err.response;
              if (errData.error.indexOf("duplicate") > -1) {
                alert(
                  "Looks like that email/username is already registered as a user. Either your membership is already connected or the username you have chosen is already taken. If you think that is incorrect, please let us know."
                );
              } else {
                alert(
                  "Something went wrong there. Please try again and if the problem persists, please let us know! Thanks for your patience."
                );
              }
            });

          if (data.user && data.user._id) {
            window.location.href = `/register/success`;
          } else {
            alert(
              "Something went wrong there. Please try again and if the problem persists, please let us know! Thanks for your patience."
            );
          }
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().required("Membership email is required"),
          username: Yup.string().required("Username is required"),
          password: Yup.string().required("Password is required")
        })}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
          } = props;
          return (
            <form onSubmit={handleSubmit} className="gel-layout">
              <h3 className="gel-great-primer-bold gel-1/1 gel-layout__item gs-u-mb++">
                Connect your membership
              </h3>

              <fieldset className="gel-layout__item gel-8/12@l gel-9/12@xl gel-10/12@xxl gs-u-mb gs-u-mb++@l">
                <label htmlFor="email" className="gs-u-display-block">
                  Membership Email *
                </label>
                <input
                  id="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.email && touched.email
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.email && touched.email && (
                  <div className="input-error">{errors.email}</div>
                )}
              </fieldset>

              <fieldset className="gel-layout__item gel-4/12@l gel-3/12@xl gel-2/12@xxl gs-u-mb++">
                <button
                  type="button"
                  className="button button--full button--spaced-top gs-u-p+"
                  onClick={getMembership}
                  disabled={isGettingMembership}
                >
                  Connect Membership
                </button>
              </fieldset>

              {userInfo && (
                <div className="membership-details__content gs-u-p++ gs-u-ml gs-u-ml+@m gel-1/1 gs-u-mb++">
                  <h3 className="gel-great-primer-bold gel-1/1 gs-u-mb++">
                    Your Membership
                  </h3>
                  <ul className="membership-details__list gel-1/1">
                    <li className="gel-1/1 gel-1/3@l gs-u-mb+ gs-u-display-inline-block">
                      <span className="gel-pica-bold gs-u-display-block gs-u-mb">
                        Name
                      </span>
                      <span className="gel-pica">
                        {userInfo.firstName} {userInfo.surName}
                      </span>
                    </li>
                    <li className="gel-1/1 gel-1/3@l gs-u-mb+ gs-u-display-inline-block">
                      <span className="gel-pica-bold gs-u-display-block gs-u-mb">
                        Address
                      </span>
                      <span className="gel-pica">{userInfo.address}</span>
                    </li>
                    <li className="gel-1/1 gel-1/3@l gs-u-mb+ gs-u-display-inline-block">
                      <span className="gel-pica-bold gs-u-display-block gs-u-mb">
                        Phone
                      </span>
                      <span className="gel-pica">{userInfo.phone}</span>
                    </li>
                    <li className="gel-1/1 gel-1/3@l gs-u-mb+ gs-u-display-inline-block">
                      <span className="gel-pica-bold gs-u-display-block gs-u-mb">
                        Membership Type
                      </span>
                      <span className="gel-pica">
                        {getMembershipTypeLabel(userInfo.membershipType)}
                      </span>
                    </li>
                    <li className="gel-1/1 gel-1/3@l gs-u-mb+ gs-u-display-inline-block">
                      <span className="gel-pica-bold gs-u-display-block gs-u-mb">
                        Membership Level
                      </span>
                      <span className="gel-pica">
                        {getMembershipLevelLabel(userInfo.membershipLevel)}
                      </span>
                    </li>
                  </ul>
                </div>
              )}

              <h3 className="gel-great-primer-bold gel-1/1 gel-layout__item gs-u-mb++">
                Account credentials
              </h3>

              <fieldset className="gel-layout__item gel-6/12">
                <label htmlFor="username" className="gs-u-display-block">
                  Username *
                </label>
                <input
                  id="username"
                  type="text"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.username && touched.username
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.username && touched.username && (
                  <div className="input-error">{errors.username}</div>
                )}
              </fieldset>

              <fieldset className="gel-layout__item gel-6/12">
                <label htmlFor="password" className="gs-u-display-block">
                  Password *
                </label>
                <input
                  id="password"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.password && touched.password
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.password && touched.password && (
                  <div className="input-error">{errors.password}</div>
                )}
              </fieldset>

              <div className="gel-layout__item input-feedback gs-u-mt gs-u-mb++">
                <i>
                  These fields will allow you to log into our online services.
                </i>
              </div>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
                <button
                  className="button button--filled button--large"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Create account & link membership
                </button>
              </fieldset>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

RegisterMember.defaultProps = {

};

RegisterMember.propTypes = {

};

export default connect(mapStateToProps)(RegisterMember);
