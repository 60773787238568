const initialState = {
  activeBooking: null,
  list: []
};

export default function bookings(state = initialState, action) {
  switch (action.type) {
    case "BOOKINGS_SET_ACTIVE":
      return { ...state, activeBooking: action.activeBooking };
    default:
      return state;
  }
}
