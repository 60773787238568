import React, { Fragment } from "react";
import TextSection from "../../components/TextSection";
import EventModal from "../../containers/EventModal";
import BookingsCalendar from "../../containers/BookingsCalendar";

/**
 * Admin component
 *
 * @param {object} props
 */
const Admin = () => {
  return (
    <Fragment>
      <TextSection
        title="Bookings"
        textHtml={(
          <div className="gel-pica">
            <p className="gs-u-mb+">Here's a list of the upcoming bookings</p>
          </div>
        )}
      />
      <div className="gel-wrap o-slice-spacing-bottom">
        <ul className="gel-layout">
          <li className="gel-layout__item gs-u-mb++">
            <BookingsCalendar />
            <EventModal />
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default Admin;
