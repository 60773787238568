import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

/**
 * Section component
 *
 * @param {object} props
 */
const Section = ({ isReversed, classes, title, imageUrl, button, text }) => {
  const sectionClasses = classNames("section gel-layout", {
    [`${classes}`]: classes,
    "gel-layout--rev": isReversed
  });

  const sectionItemInnerClasses = classNames(
    "section__item_inner animate__animated"
  );

  return (
    <ul className={sectionClasses}>
      <li
        className="section__item section__item--image gs-u-ph++ gel-layout__item gel-1/2@l"
        style={{
          backgroundImage: `url(${imageUrl})`
        }}
      />
      <li className="section__item gs-u-ph++ gel-layout__item gel-1/2@l gs-u-align-center">
        <div className={sectionItemInnerClasses}>
          <h3 className="section__title gel-double-pica-bold gs-u-display-inline-block gs-u-mb++">
            {title}
          </h3>
          <div className="section__item_text gs-u-display-inline-block">
            {text}
            {button}
          </div>
        </div>
      </li>
    </ul>
  );
};

Section.defaultProps = {
  classes: null,
  isReversed: false,
  button: null
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  isReversed: PropTypes.bool,
  classes: PropTypes.string,
  button: PropTypes.node,
  text: PropTypes.node.isRequired
};

export default Section;