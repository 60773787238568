import React from "react";
import moment from "moment";
import get from "lodash/get";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import BookingItem from "../components/BookingItem";

const mapStateToProps = state => ({
  bookings: get(state, "bookings.list", [])
});

const mapDispatchToProps = dispatch => ({
});

const renderBookings = bookings => {
  return (
    <ul className="bookings-list">
      {bookings.map(booking => {
        if (booking) {
          return <BookingItem key={booking._id} booking={booking} />;
        }

        return null;
      })}
    </ul>
  );
};

/**
 * BookingsList component
 *
 * @param {object} props
 */
const BookingsList = ({ bookings, title, isViewAllEnabled }) => {
  if (!bookings.length) {
    return (
      <div className="bookings">
        <h2 className="text-section__title gel-great-primer-bold gs-u-display-inline-block gs-u-mb++">
          {title}
        </h2>
        <p>
          Looks like there's currently no scheduled bookings. Check back later
          to see if any come through.
        </p>
      </div>
    );
  }

  return (
    <div className="bookings">
      <div>
        {title && (
          <h2 className="text-section__title gel-great-primer-bold gs-u-display-inline-block gs-u-mb++">
            {title}
          </h2>
        )}
        {isViewAllEnabled && (
          <a className="button gs-u-ml+ sc-o-link" href="/admin/bookings">
            View all <i className="fa fa-next" />
          </a>
        )}
      </div>
      {renderBookings(bookings)}
    </div>
  );
};

BookingsList.defaultProps = {
  bookings: [],
  title: "Bookings",
  isViewAllEnabled: true
};

BookingsList.propTypes = {
  bookings: PropTypes.array,
  title: PropTypes.string,
  isViewAllEnabled: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingsList);
