import React from "react";

/**
 * OpeningHours component
 *
 * @param {object} props
 */
const OpeningHours = () => {
  return (
    <div id="opening-hours" className="opening-hours gs-u-mb+">
      <div className="opening-hours__inner gs-u-display-inline-block gs-u-p+">
        <p className="gel-pica-bold gs-u-mb">Open Daily</p>
        <p className="gel-pica-bold">24/7</p>
      </div>
    </div>
  );
};

export default OpeningHours;
