/* eslint-disable prettier/prettier */
import React, { Fragment } from "react";
import Banner from "../../components/Banner";
import TextSection from "../../components/TextSection";

/**
 * PaymentSuccess component
 *
 * @param {object} props
 */
const PaymentSuccess = () => {
  return (
    <Fragment>
      <Banner title="We've created your account!" imageUrl="/assets/img/gym-dark.jpg" />
      <TextSection
        title="What's next?"
        textHtml={(
          <React.Fragment>
            <p className="gs-u-mb++">
              You should receive a confirmation email from us with a link to verify your email address. Once your email has been verified, you'll be able to enjoy The Gym at Moffat on the go.
            </p>
            <a className="button gs-u-p+" href="/">Take me back to the home page.</a>
          </React.Fragment>
        )}
      />
    </Fragment>
  );
};

export default PaymentSuccess;
