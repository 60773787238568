import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { toggleSidebar } from '../store/actions/ui';

import Modal from './Modal';
import { renderMembershipError } from './BookingsModal';

// 06:00 - 12:00
// 15:30 - 21:00
const OPENING_TIMES_IN_MINS = [
  {
    start: 360,
    end: 1260,
  },
];

const mapStateToProps = state => ({
  activeNavItem: state.ui.activeNavItem,
  user: get(state, 'user'),
});

const mapDispatchToProps = dispatch => ({
  setSidebarState: isSidebarOpen => dispatch(toggleSidebar(isSidebarOpen)),
});

const renderInvalidMemberModal = (user, page) => {
  if (get(user, 'hasInvalidMembershipSubscription') && page === 'home') {
    return (
      <Modal user classes="modal--strict">
        <h2 className="gel-double-pica-bold gs-u-display-inline-block gs-u-mb++">
          M E M B E R S H I P
        </h2>
        {renderMembershipError({ user })}
      </Modal>
    );
  }
};

/**
 * Header component
 *
 * @param {object} props
 */
const Header = ({ setSidebarState, isSmall, page, user }) => {
  if (page === 'login') {
    return null;
  }

  const headerClasses = classNames('header gs-u-pv+ gs-u-pv+@l', {
    'header--small': isSmall,
    'header--large': !isSmall,
  });
  const headerLogoClasses = classNames(
    'header__logo gs-u-display-inline-block'
  );
  const headerNavClasses = classNames(
    'header__nav gs-u-display-inline-block gs-u-mt-',
    {
      'gs-u-mr+ gs-u-mt++@l gs-u-mr++@l': !isSmall,
      'gs-u-mr+': isSmall,
    }
  );
  return (
    <>
      {renderInvalidMemberModal(user, page)}
      <header className={headerClasses}>
        <a href="/" className={headerLogoClasses}>
          <img
            className="header__img gel-1/1"
            src="/assets/img/logo.svg"
            alt=""
          />
        </a>
        <ul className={headerNavClasses}>
          <li className="header__nav_item gs-u-display-none gs-u-display-inline-block@l gs-u-mr+">
            <a
              className="button button--dark"
              href="https://maps.app.goo.gl/8xX6zWQBRdZF7SVZ6"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-map-marker" /> View map
            </a>
          </li>
          {!user && (
            <li className="header__nav_item gs-u-display-none gs-u-display-inline-block@l">
              <a
                href="/login"
                className="button button--white button--large gs-u-mr"
              >
                <i className="fa fa-sign-in gs-u-mr@m" />
                <span>Sign in</span>
              </a>
            </li>
          )}
          <li className="header__nav_item gs-u-display-inline-block">
            <button
              type="button"
              className="button button--dark button--large"
              onClick={() => setSidebarState(true)}
            >
              <i className="fa fa-bars gs-u-mr@m" />
              <span>Menu</span>
            </button>
          </li>
        </ul>
      </header>
    </>
  );
};

Header.defaultProps = {
  navItems: null,
};

Header.propTypes = {
  navItems: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
