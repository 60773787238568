/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/prop-types */
/* eslint-disable no-alert */
import React from "react";
import axios from "axios";
import MedicalQuestionnaireFormFields from "../components/MedicalQuestionnaireFormFields";
import MemberLoginFormFields from "../components/MemberLoginFormFields";

axios.defaults.withCredentials = true;

export const NewMembershipForm = ({
  elementId,
  formikProps,
  inputPrefix,
  title = "Member Details",
}) => {
  const { values, touched, errors, handleChange, handleBlur } = formikProps;
  return (
    <div className="gs-u-mt+" id={elementId}>
      <div className="gel-layout">
        <h3 className="gel-great-primer-bold gel-1/1 gel-layout__item gs-u-mb++">
          {title}
        </h3>
        <fieldset className="gel-layout__item gs-u-mb++ gel-1/2 gel-2/12@l">
          <label htmlFor={`${inputPrefix}title`} className="gs-u-display-block">
            Title *
          </label>
          <input
            id={`${inputPrefix}title`}
            type="text"
            value={values[`${inputPrefix}title`]}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors[`${inputPrefix}title`] && touched[`${inputPrefix}title`]
                ? "text-input error"
                : "text-input"
            }
          />
          {errors[`${inputPrefix}title`] && touched[`${inputPrefix}title`] && (
            <div className="input-error">{errors[`${inputPrefix}title`]}</div>
          )}
        </fieldset>
        <fieldset className="gel-layout__item gs-u-mb++ gel-1/2 gel-5/12@l">
          <label
            htmlFor={`${inputPrefix}firstName`}
            className="gs-u-display-block"
          >
            First name *
          </label>
          <input
            id={`${inputPrefix}firstName`}
            type="text"
            value={values[`${inputPrefix}firstName`]}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors[`${inputPrefix}firstName`] &&
              touched[`${inputPrefix}firstName`]
                ? "text-input error"
                : "text-input"
            }
          />
          {errors[`${inputPrefix}firstName`] &&
            touched[`${inputPrefix}firstName`] && (
              <div className="input-error">
                {errors[`${inputPrefix}firstName`]}
              </div>
            )}
        </fieldset>
        <fieldset className="gel-layout__item gs-u-mb++ gel-1/2 gel-5/12@l">
          <label
            htmlFor={`${inputPrefix}surName`}
            className="gs-u-display-block"
          >
            Surname *
          </label>
          <input
            id={`${inputPrefix}surName`}
            type="text"
            value={values[`${inputPrefix}surName`]}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors[`${inputPrefix}surName`] &&
              touched[`${inputPrefix}surName`]
                ? "text-input error"
                : "text-input"
            }
          />
          {errors[`${inputPrefix}surName`] &&
            touched[`${inputPrefix}surName`] && (
              <div className="input-error">
                {errors[`${inputPrefix}surName`]}
              </div>
            )}
        </fieldset>
        <fieldset className="gel-layout__item gs-u-mb++ gel-6/12">
          <label
            htmlFor={`${inputPrefix}dateOfBirth`}
            className="gs-u-display-block"
          >
            Date of birth *
          </label>
          <input
            id={`${inputPrefix}dateOfBirth`}
            placeholder="DD/MM/YYYY"
            type="text"
            value={values[`${inputPrefix}dateOfBirth`]}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors[`${inputPrefix}dateOfBirth`] &&
              touched[`${inputPrefix}dateOfBirth`]
                ? "text-input error"
                : "text-input"
            }
          />
          {errors[`${inputPrefix}dateOfBirth`] &&
            touched[`${inputPrefix}dateOfBirth`] && (
              <div className="input-error">
                {errors[`${inputPrefix}dateOfBirth`]}
              </div>
            )}
        </fieldset>
        <fieldset className="gel-layout__item gs-u-mb++ gel-6/12">
          <label htmlFor={`${inputPrefix}phone`} className="gs-u-display-block">
            Phone number *
          </label>
          <input
            id={`${inputPrefix}phone`}
            type="tel"
            value={values[`${inputPrefix}phone`]}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors[`${inputPrefix}phone`] && touched[`${inputPrefix}phone`]
                ? "text-input error"
                : "text-input"
            }
          />
          {errors[`${inputPrefix}phone`] && touched[`${inputPrefix}phone`] && (
            <div className="input-error">{errors[`${inputPrefix}phone`]}</div>
          )}
        </fieldset>
        <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
          <label
            htmlFor={`${inputPrefix}address`}
            className="gs-u-display-block"
          >
            Address *
          </label>
          <input
            id={`${inputPrefix}address`}
            type="text"
            value={values[`${inputPrefix}address`]}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors[`${inputPrefix}address`] &&
              touched[`${inputPrefix}address`]
                ? "text-input error"
                : "text-input"
            }
          />
          {errors[`${inputPrefix}address`] &&
            touched[`${inputPrefix}address`] && (
              <div className="input-error">
                {errors[`${inputPrefix}address`]}
              </div>
            )}
        </fieldset>
        <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
          <label htmlFor={`${inputPrefix}email`} className="gs-u-display-block">
            Email *
          </label>
          <input
            id={`${inputPrefix}email`}
            placeholder="Enter your email"
            type="email"
            value={values[`${inputPrefix}email`]}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors[`${inputPrefix}email`] && touched[`${inputPrefix}email`]
                ? "text-input error"
                : "text-input"
            }
          />
          {errors[`${inputPrefix}email`] && touched[`${inputPrefix}email`] && (
            <div className="input-error">{errors[`${inputPrefix}email`]}</div>
          )}
          <div className="input-feedback gs-u-mt">
            <i>
              * Our preferred method of contact. An email address will allow you
              to use our online booking system.
            </i>
          </div>
        </fieldset>

        <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
          <p className="gs-u-mb">
            Data Protection: Information collected on this form will be treated
            as confidential and held securely by The Gym. We will use this
            information to administer and process your membership. Your details
            will not be passed on to a third party and will be destroyed when
            the membership is cancelled. We may also use this information to
            update you about our products and services.
          </p>
          <label htmlFor={`${inputPrefix}marketingOptOut`}>
            If you would like to opt out of receiving marketing/promotional
            messages, including The Gym at Moffat, please tick here
            <input
              className="gs-u-ml"
              type="checkbox"
              id={`${inputPrefix}marketingOptOut`}
              checked={values[`${inputPrefix}marketingOptOut`]}
              onChange={handleChange}
            />
          </label>
        </fieldset>

        <h3 className="gel-great-primer-bold gel-1/1 gel-layout__item gs-u-mb++">
          Membership Type
        </h3>

        <fieldset className="gel-layout__item gs-u-mb gel-1/1">
          <label className="gs-u-display-inline-block gs-u-mr+" htmlFor="adult">
            <input
              type="radio"
              className="gs-u-mr"
              id="adult"
              name={`${inputPrefix}membershipType`}
              value="adult"
              checked={values[`${inputPrefix}membershipType`] === "adult"}
              onChange={handleChange}
            />
            Adult
          </label>
        </fieldset>

        <h3 className="gel-great-primer-bold gel-1/1 gel-layout__item gs-u-mb++">
          Membership Level
        </h3>

        <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
          <label
            className="gs-u-display-inline-block gel-1/2@l"
            htmlFor="gym-only"
            style={{ verticalAlign: "top", paddingRight: "8px" }}
          >
            <input
              type="radio"
              className="gs-u-mr"
              id="gym-only"
              name={`${inputPrefix}membershipLevel`}
              value="gym-only"
              checked={values[`${inputPrefix}membershipLevel`] === "gym-only"}
              onChange={handleChange}
            />
            Gym Only
            <span className="label-info gs-u-ml">
              Adult: £135 p/6m or £270 p/a.
            </span>
          </label>
          <label
            className="gs-u-display-inline-block gel-1/2@l"
            htmlFor="gym-and-classes"
            style={{ verticalAlign: "top", paddingRight: "8px" }}
          >
            <input
              type="radio"
              className="gs-u-mr"
              id="gym-and-classes"
              name={`${inputPrefix}membershipLevel`}
              value="gym-and-classes"
              checked={
                values[`${inputPrefix}membershipLevel`] === "gym-and-classes"
              }
              onChange={handleChange}
            />
            Gym & Classes
            <span className="label-info gs-u-ml">
              Adult: £180 p/6m or £360 p/a.
            </span>
          </label>
        </fieldset>

        <h3 className="gel-great-primer-bold gel-1/1 gel-layout__item gs-u-mb++">
          Gym induction
        </h3>

        <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
          <label
            className="gs-u-display-inline-block gs-u-mr+ gs-u-mb++"
            htmlFor={`${inputPrefix}gym-induction-yes`}
          >
            <input
              type="radio"
              className="gs-u-mr"
              id={`${inputPrefix}gym-induction-yes`}
              name={`${inputPrefix}gymInduction`}
              value="gym-induction-yes"
              checked={
                values[`${inputPrefix}gymInduction`] === "gym-induction-yes"
              }
              onChange={handleChange}
            />
            I require a gym induction
            <span className="label-info gs-u-ml">
              You will be contacted by one of our team to organise this in due
              course.
            </span>
          </label>
          <label
            className="gs-u-display-inline-block"
            htmlFor={`${inputPrefix}gym-induction-no`}
          >
            <input
              type="radio"
              className="gs-u-mr"
              id={`${inputPrefix}gym-induction-no`}
              name={`${inputPrefix}gymInduction`}
              value="gym-induction-no"
              checked={
                values[`${inputPrefix}gymInduction`] === "gym-induction-no"
              }
              onChange={handleChange}
            />
            I do not require a gym induction
            <span className="label-info gs-u-ml">
              You must have previous gym experience and suffucient experience to
              use the equipment safely.
            </span>
          </label>
        </fieldset>

        <MedicalQuestionnaireFormFields
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
          inputPrefix={inputPrefix}
        />

        <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
          <label className="gs-u-mb+" htmlFor={`${inputPrefix}rulesCheck`}>
            I agree to abide by the rules of the gym and I undertake to read all
            instructions carefully before using the equipment. I understand that
            I use this facility at my own risk and that The Gym accepts no
            liability for injury or damage. *
            <input
              className="gs-u-ml"
              type="checkbox"
              id={`${inputPrefix}rulesCheck`}
              checked={values[`${inputPrefix}rulesCheck`]}
              onChange={handleChange}
            />
            {errors[`${inputPrefix}rulesCheck`] &&
              touched[`${inputPrefix}rulesCheck`] && (
                <span className="input-error">
                  {errors[`${inputPrefix}rulesCheck`]}
                </span>
              )}
          </label>

          <label
            className="gs-u-mb+"
            htmlFor={`${inputPrefix}socialMediaSharing`}
          >
            I am happy for my photograph to be shared on social media sites
            <input
              className="gs-u-ml"
              type="checkbox"
              id={`${inputPrefix}socialMediaSharing`}
              checked={values[`${inputPrefix}socialMediaSharing`]}
              onChange={handleChange}
            />
          </label>
        </fieldset>

        <h3 className="gel-great-primer-bold gel-1/1 gel-layout__item gs-u-mb++">
          Next of kin
        </h3>

        <fieldset className="gel-layout__item gs-u-mb++ gel-6/12">
          <label
            htmlFor={`${inputPrefix}nextOfKinName`}
            className="gs-u-display-block"
          >
            Name *
          </label>
          <input
            id={`${inputPrefix}nextOfKinName`}
            type="text"
            value={values[`${inputPrefix}nextOfKinName`]}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors[`${inputPrefix}nextOfKinName`] &&
              touched[`${inputPrefix}nextOfKinName`]
                ? "text-input error"
                : "text-input"
            }
          />
          {errors[`${inputPrefix}nextOfKinName`] &&
            touched[`${inputPrefix}nextOfKinName`] && (
              <div className="input-error">
                {errors[`${inputPrefix}nextOfKinName`]}
              </div>
            )}
        </fieldset>

        <fieldset className="gel-layout__item gs-u-mb++ gel-6/12">
          <label
            htmlFor={`${inputPrefix}nextOfKinPhone`}
            className="gs-u-display-block"
          >
            Phone number *
          </label>
          <input
            id={`${inputPrefix}nextOfKinPhone`}
            type="tel"
            value={values[`${inputPrefix}nextOfKinPhone`]}
            onChange={handleChange}
            onBlur={handleBlur}
            className={
              errors[`${inputPrefix}nextOfKinPhone`] &&
              touched[`${inputPrefix}nextOfKinPhone`]
                ? "text-input error"
                : "text-input"
            }
          />
          {errors[`${inputPrefix}nextOfKinPhone`] &&
            touched[`${inputPrefix}nextOfKinPhone`] && (
              <div className="input-error">
                {errors[`${inputPrefix}nextOfKinPhone`]}
              </div>
            )}
        </fieldset>

        <MemberLoginFormFields
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
          inputPrefix={inputPrefix}
        />
      </div>
    </div>
  );
};
