import React, { useState } from 'react';
import { Gallery as GridGallery } from 'react-grid-gallery';
import Lightbox from 'react-image-lightbox';

export const images = [
  {
    src: '/assets/img/gymnastics-gallery-1-thumb.jpeg',
    original: '/assets/img/gymnastics-gallery-1.jpeg',
  },
  {
    src: '/assets/img/gymnastics-gallery-2-thumb.jpeg',
    original: '/assets/img/gymnastics-gallery-2.jpeg',
  },
  {
    src: '/assets/img/gymnastics-gallery-3-thumb.jpeg',
    original: '/assets/img/gymnastics-gallery-3.jpeg',
  },
  {
    src: '/assets/img/gymnastics-gallery-4-thumb.jpeg',
    original: '/assets/img/gymnastics-gallery-4.jpeg',
  },
];

/**
 * Gallery component
 *
 * @param {object} props
 */
const Gallery = () => {
  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = ind => setIndex(ind);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  return (
    <div className="gallery">
      <GridGallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
        defaultContainerWidth={600}
      />
      {!!currentImage && (
        /* @ts-ignore */
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </div>
  );
};

Gallery.defaultProps = {};

Gallery.propTypes = {};

export default Gallery;
