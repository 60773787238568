import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

/**
 * Banner component
 *
 * @param {object} props
 */
const Banner = ({ classes, title, imageUrl }) => {
  const bannerClasses = classNames("banner gel-layout", {
    [`${classes}`]: classes
  });

  return (
    <div className={bannerClasses}>
      <div
        className="banner__inner gs-u-ph++ gel-layout__item gs-u-align-center"
        style={{
          backgroundImage: `url(${imageUrl})`
        }}
      >
        <h3 className="banner__title gel-double-pica-bold gs-u-display-inline-block gs-u-mb++">
          {title}
        </h3>
      </div>
    </div>
  );
};

Banner.defaultProps = {
  classes: null
};

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  classes: PropTypes.string
};

export default Banner;
