/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";

import MemberDetailsFormFields from "../components/MemberDetailsFormFields";
import MedicalQuestionnaireFormFields from "../components/MedicalQuestionnaireFormFields";

axios.defaults.withCredentials = true;

const mapStateToProps = (state) => ({});

/**
 * RegisterNonMember container
 *
 * @param {object} props
 */
const RegisterNonMember = () => {
  return (
    <div className="gel-wrap gel-wrap o-slice-spacing o-slice-spacing-bottom">
      <h2 className="gs-u-mb++">Fill out our Online Account form!</h2>

      <Formik
        initialValues={{
          title: "",
          firstName: "",
          surName: "",
          dateOfBirth: "",
          address: "",
          phone: "",
          email: "",
          marketingOptOut: false,
          medicalQuestionnaire1: "q1-no",
          medicalQuestionnaire1Explain: "",
          medicalQuestionnaire2: "q2-no",
          medicalQuestionnaire2Explain: "",
          medicalQuestionnaire3: "q3-no",
          medicalQuestionnaire3Explain: "",
          medicalQuestionnaire4: "q4-no",
          medicalQuestionnaire4Explain: "",
          medicalQuestionnaire5: "q5-no",
          medicalQuestionnaire5Explain: "",
          medicalQuestionnaire6: "q6-no",
          medicalQuestionnaire6Explain: "",
          medicalQuestionnaire7: "q7-no",
          medicalQuestionnaire7Explain: "",
          medicalQuestionnaire8: "q8-no",
          medicalQuestionnaire8Explain: "",
          medicalQuestionnaire9: "q9-no",
          medicalQuestionnaire9Explain: "",
          goodHealthCheck: false,
          rulesCheck: false,
          socialMediaSharing: false,
          username: "",
          password: "",
        }}
        onSubmit={async (values) => {
          const { data } = await axios
            .post("/api/users", values, { credentials: "include" })
            .catch((err) => {
              const { data: errData } = err.response;
              if (errData.error.indexOf("duplicate") > -1) {
                alert(
                  "Looks like that email address/username is already registered as a user. If you think that is incorrect, please let us know."
                );
              } else {
                alert(
                  "Something went wrong there. Please try again and if the problem persists, please let us know! Thanks for your patience."
                );
              }
            });

          if (data.user && data.user._id) {
            window.location.href = `/register/success`;
          } else {
            alert(
              "Something went wrong there. Please try again and if the problem persists, please let us know! Thanks for your patience."
            );
          }
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required("Title is required"),
          firstName: Yup.string().required("First name is required"),
          surName: Yup.string().required("Surname is required"),
          dateOfBirth: Yup.string().required("DOB is required"),
          address: Yup.string().required("Address is required"),
          phone: Yup.string().required("Phone number is required"),
          email: Yup.string()
            .email("Please enter a valid email")
            .required("Email is required"),
          goodHealthCheck: Yup.bool().oneOf([true], "Health check is required"),
          rulesCheck: Yup.bool().oneOf([true], "Rules check is required"),
          username: Yup.string().required("Username is required"),
          password: Yup.string().required("Password is required"),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <form onSubmit={handleSubmit} className="gel-layout">
              <MemberDetailsFormFields
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                isMembershipForm={false}
              />

              <MedicalQuestionnaireFormFields
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
                <i className="disclaimer gs-u-display-block gs-u-mb">
                  All gym users use the equipment at your own risk and it is
                  assumed you have the correct knowledge to use our equipment.
                  If you need help with the equipment, get in touch for an
                  induction.
                </i>
                <label className="gs-u-mb+" htmlFor="rulesCheck">
                  I agree to abide by the rules of the gym and I undertake to
                  read all instructions carefully before using the equipment. I
                  understand that I use this facility at my own risk and that
                  The Gym accepts no liability for injury or damage. *
                  <input
                    className="gs-u-ml"
                    type="checkbox"
                    id="rulesCheck"
                    checked={values.rulesCheck}
                    onChange={handleChange}
                  />
                  {errors.rulesCheck && touched.rulesCheck && (
                    <span className="input-error">{errors.rulesCheck}</span>
                  )}
                </label>

                <label className="gs-u-mb+" htmlFor="socialMediaSharing">
                  I am happy for my photograph to be shared on social media
                  sites
                  <input
                    className="gs-u-ml"
                    type="checkbox"
                    id="socialMediaSharing"
                    checked={values.socialMediaSharing}
                    onChange={handleChange}
                  />
                </label>
              </fieldset>

              <h3 className="gel-great-primer-bold gel-1/1 gel-layout__item gs-u-mb++">
                Account credentials
              </h3>

              <fieldset className="gel-layout__item gel-6/12">
                <label htmlFor="username" className="gs-u-display-block">
                  Username *
                </label>
                <input
                  id="username"
                  type="text"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.username && touched.username
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.username && touched.username && (
                  <div className="input-error">{errors.username}</div>
                )}
              </fieldset>

              <fieldset className="gel-layout__item gel-6/12">
                <label htmlFor="password" className="gs-u-display-block">
                  Password *
                </label>
                <input
                  id="password"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.password && touched.password
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.password && touched.password && (
                  <div className="input-error">{errors.password}</div>
                )}
              </fieldset>

              <div className="gel-layout__item input-feedback gs-u-mt gs-u-mb++">
                <i>
                  These fields will allow you to log into our online services.
                </i>
              </div>

              <fieldset className="gel-layout__item gs-u-mb++ gel-1/1">
                <button
                  className="button button--filled button--large"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Create account
                </button>
              </fieldset>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default connect(mapStateToProps)(RegisterNonMember);
