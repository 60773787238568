/* eslint-disable prettier/prettier */
import React from 'react';
import Banner from '../../components/Banner';
import CardGrid from '../../components/CardGrid';
import TextSection from '../../components/TextSection';
import Gallery from '../../components/Gallery';

/**
 * Gymnastics component
 *
 * @param {object} props
 */
const Gymnastics = () => {
  return (
    <React.Fragment>
      <Banner
        classes="gs-u-mb++"
        title="Gymnastics"
        imageUrl="/assets/img/gymnastics-equipment.jpeg"
      />

      <TextSection
        title="Welcome to Gymnastics at The Gym at Moffat"
        textHtml={
          <div className="gel-pica">
            <p className="gs-u-mb+">
              Mike Briggs and Sarah Leighfield have established a varied and
              popular gymnastics programme at the Gym at Moffat over the last 5
              years. We have some 50 children in 2 mixed preschool programmes
              from pre-toddler through to P1, and 3 girls' groups ranging from
              P1 to early Secondary school age and beyond. Our summer programme
              includes Gymkids, a keep-fit programme for younger children.
            </p>
            <p className="gs-u-mb+">
              We have strong links with the Moffat community through the gym,
              and with Moffat Academy through Sarah who helps in their schools
              gymnastics programme. We also have our young coach volunteers who
              help out at the sessions.
            </p>
          </div>
        }
      />
      <Gallery />
      <CardGrid
        title="Meet the team!"
        cardItems={[
          {
            textHtml: (
              <div className="gel-pica gs-u-pv++">
                <h3 className="gs-u-display-block gs-u-mb gel-pica-bold">
                  Mike Briggs
                </h3>
                <p className="gs-u-mb">
                  I'm Mike. I have coached gymnastics now for over 20 years. I
                  came to the Gym at Moffat in 2018 having coached at a
                  gymnastics club in Dumfries where my daughter trained. I'm
                  currently qualified as a Level 3 Women's coach, Level 1 Men's
                  coach, and pre-school children's coach.
                </p>
                <p className="gs-u-mb">
                  When not coaching, I'm keeping fit, running long distances and
                  playing my piano.
                </p>
                <p className="gs-u-align-middle gs-u-mb+">
                  <i className="fa fa-phone gs-u-mr" />
                  <a href="tel:07759907271" className="footer__phone gs-u-pv">
                    <b>07759907271</b>
                  </a>
                </p>
              </div>
            ),
            imageUrl: '/assets/img/mike.jpeg',
          },
          {
            textHtml: (
              <div className="gel-pica gs-u-pv++">
                <h3 className="gs-u-display-block gs-u-mb gel-pica-bold">
                  Sarah Leighfield
                </h3>
                <p className="gs-u-mb">
                  I'm Sarah and I was born, and grew up, in Moffat. I did
                  gymnastics in both primary and secondary school. I have 2
                  children, and my youngest is currently part of our gymnastics
                  programme in Moffat.
                </p>
                <p className="gs-u-mb">
                  At the Gym, I combine 2 of the things I love - working with
                  young people and gymnastics!
                </p>
              </div>
            ),
            imageUrl: '/assets/img/sarah.jpeg',
            isReversed: true,
          },
        ]}
      />
    </React.Fragment>
  );
};

export default Gymnastics;
