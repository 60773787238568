import get from "lodash/get";

const isGodOrAdmin = user => {
  const isGodUser = get(user, "userRights", "") === "god";
  const isAdminUser = get(user, "userRights", "") === "admin";
  return isGodUser || isAdminUser;
};

const isUserLoggedIn = user => {
  return get(user, "username", "").length > 0;
};

export { isGodOrAdmin, isUserLoggedIn };
